import React, { Component } from 'react'
import { connect } from 'react-redux';
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { filterTransactionHistory, getexportlink, getTransactionHistory } from '../reducers/TransactionHistory/TransactionHistory'

class Heading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startdate: `${new Date().getFullYear() - 1}-04-01`,
            enddate: `${new Date().getFullYear()}-04-01`,
            merchantid: ''
        };
    }

    componentDidMount = async () => {
        if (new Date().getMonth() + 1 >= 4) {
            await this.setState({ startdate: `${new Date().getFullYear()}-04-01` })
            await this.setState({ enddate: `${new Date().getFullYear() + 1}-04-01` })
        }
        this.props.getexportlink(this.state.startdate, this.state.enddate, null)
        this.props.filterTransactionHistory(this.state.startdate, this.state.enddate, null)

    }
    render() {
        const { title, subTitle, button, actionTitle, action, customCss, getTransactionHistory, themeColors, getexportlink, exportlink, merchantInfo, showSearch, filterTransactionHistory } = this.props;
        function filterlist(data) {
            if (data.state.merchantid == 'all') {
                getTransactionHistory()
                data.setState({ merchantid: '', startdate: '', enddate: '' });
            } else if (data.state.startdate !== '' && data.state.enddate !== '') {
                getexportlink(data.state.startdate, data.state.enddate, data.state.merchantid)
                filterTransactionHistory(data.state.startdate, data.state.enddate, data.state.merchantid)
            } else {
                getexportlink('', '', data.state.merchantid)
                filterTransactionHistory('', '', data.state.merchantid)
            }
        }

        // function filterdate(data) {
        //     if(data.startdate!=='' && data.enddate!==''){
        //     dateFilterTransactionHistory(data.startdate,data.enddate)
        //     }
        // }

        return (

            <div className={`${styles['fr-heading-div']}`}>
                <style>{customCss}</style>
                <div className={`heading-section `}>
                    <span className={`${styles['fr-heading-title']} heading-sub-title`} style={{ color: themeColors && themeColors.headingColor }}>{title}</span>
                    <span className={`${styles['fr-heading-sub-title']} heading-title`} style={{ color: themeColors && themeColors.headingColor }}>{subTitle}</span>
                </div>
                <div>
                    {showSearch ?
                        <div className={`${styles['filter_container']}`}>
                            <label style={{ fontSize: '15px' }}>Start Date :</label>
                            <input type='date'
                                style={{
                                    position: 'relative',
                                    height: '30px',
                                    width: '115px',
                                    paddingLeft: '10px',
                                    fontSize: '14px',
                                    margin: '0 5px',
                                }}
                                value={this.state.startdate}
                                max={this.state.enddate}
                                min="1950-01-01"
                                onChange={(e) => {
                                    this.setState({
                                        startdate: e.target.value
                                    });
                                }} />
                            <label style={{ fontSize: '15px' }}>End Date :</label>
                            <input type='date'
                                value={this.state.enddate}
                                style={{
                                    position: 'relative',
                                    height: '30px',
                                    width: '115px',
                                    paddingLeft: '10px',
                                    fontSize: '14px',
                                    margin: '0 5px',
                                }}
                                min={this.state.startdate}
                                onChange={(e) => {
                                    this.setState({
                                        enddate: e.target.value
                                    });
                                }} />
                            <button type='submit' style={{
                                padding: '5px 27px',
                                margin: '0 5px 0 0',
                                backgroundColor: '#000',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}
                                onMouseDown={() => {
                                    filterlist(this)
                                }}>Submit</button>
                            <select id="suggestions" style={{
                                backgroundColor: '#fff',
                                fontSize: '14px',
                                border: "1px solid #000",
                                height: '30px',
                                width: '140px',
                                paddingLeft: '10px',
                            }} onChange={async (e) => {
                                await this.setState({
                                    merchantid: e.target.value
                                }); filterlist(this)
                            }}>
                                <option value={'all'} selected>Show All</option>
                                {merchantInfo && merchantInfo.length > 0 ? merchantInfo.map((merchant) => {

                                    return <option value={merchant.id}>{merchant.name}</option>
                                }) : null}
                            </select>
                            <a href={`${exportlink}`} style={{
                                padding: '2px 27px',
                                marginLeft: '5px',
                                backgroundColor: '#000',
                                color: '#fff',
                                fontSize: '14px',
                                border: 'none',
                                textDecoration: 'none',
                                borderRadius: '5px',
                            }} download>Export</a>
                        </div> : null}
                </div>
                {button ?
                    <div style={{ display: 'flex', alignItems: 'center' }} className={this.props.className}>
                        <button className={`${styles['fr-heading-btn']} header-button`} onClick={() => action()}>{actionTitle}</button>
                        <FontAwesomeIcon style={{ color: '#A2A4AC' }} icon={faArrowCircleRight} />
                    </div>
                    : null}
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        themeColors: state.theme.themeColors,
        exportlink: state.TransactionHistory.exportlink,
        customCss: state.theme && state.theme.customCss,
    };
}

const mapDispatch = {
    filterTransactionHistory,
    getexportlink,
    getTransactionHistory,
}

export default connect(mapState, mapDispatch)(Heading);