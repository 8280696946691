
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { redeemFreebie, getLoyaltyConfig } from '../reducers/loyalty/loyalty';
import { pushPath } from '../reducers/navigation/navigation'
import LoyaltyProfilePage from '../components/LoyaltyProfilePage'


class LoyaltyProfile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isFreebieModal: false,
            selectedFreebie: null,
            type: null,
            authToken: this.props.authToken,
            merchantAccessToken: this.props.merchantAccessToken,
        }
    }

    componentDidMount() {
        this.props.getLoyaltyConfig();
        window.scrollTo(0, 0);
    }

    closeModal = () => {
        this.setState({ isFreebieModal: false, selectedFreebie: null })
    }

    openModal = (type, selectedFreebie) => {

        this.setState({ isFreebieModal: true, type: type, selectedFreebie: selectedFreebie });

    }

    render() {


        const { userDetails, userPoints, loading, loyaltyCardId, freebies, exclusiveFreebies, nextCardStatus, cards,
            pointsLabel, currency, displayConversionRateComponent, defaultCurrencyConversionRate,
            rewards, redeemed, conversionRate, aboutProgramComponentInfo,configuration } = this.props.loyalty;

        const currentCardDetails = cards.find((i) => {
            return i.id === loyaltyCardId;
        });

        const currentProgram = this.props.programList && this.props.programList.find((i) => {
            return i.cards.find((j) => j.id === loyaltyCardId);
        });

        const textColor = "#131313";

        const nextCardDetails = currentCardDetails && cards.find((i) => {
            return i.id == currentCardDetails.nextCardId;
        });

        return (
            <LoyaltyProfilePage
            configuration={configuration}
                horizontalStyle={this.props.horizontalStyle}
                userDetails={userDetails}
                textColor={textColor}
                nextCardDetails={nextCardDetails}
                currentCardDetails={currentCardDetails}
                currentProgram={currentProgram}
                userPoints={userPoints}
                rewards={rewards}
                redeemed={redeemed}
                conversionRate={conversionRate}
                nextCardStatus={nextCardStatus}
                freebies={freebies}
                openModal={(type, selectedFreebie) => this.openModal(type, selectedFreebie)}
                pushPath={this.props.pushPath}
                knowMoreUrl={this.props.knowMoreUrl}
                pointsLabel={pointsLabel}
                currency={currency}
                displayConversionRateComponent={displayConversionRateComponent}
                defaultCurrencyConversionRate={defaultCurrencyConversionRate}
                aboutProgramComponentInfo={aboutProgramComponentInfo}
                exclusiveFreebies={exclusiveFreebies}
                showManageProgram={this.props.programList && this.props.programList.length > 1 ? true : false}
            />
        );
    }
}


const mapState = (state, ownprops) => {
    return {
        loyalty: state.loyalty,
        knowMoreUrl: state.session.knowMoreUrl,
        programList: state.loyaltyprogram.programList,
        horizontalStyle: state.theme.horizontalStyle,
    };
}

const mapDispatch = {
    redeemFreebie,
    pushPath,
    getLoyaltyConfig,
}

export default connect(mapState, mapDispatch)(LoyaltyProfile);
