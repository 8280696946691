import apiClient from "../../api";

const initialState = {
    referrals: [],
};

const GET_ALL_REFERRALS = 'referral/GET_ALL_REFERRALS';

export const getReferrals = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    try {
        const response = await apiClient.post(apiClient.Urls.getReferrals, {
            merchantAccessToken, authToken
        });
        if (response.success) {
            dispatch({
                type: GET_ALL_REFERRALS,
                referrals: response.referralsList,
            });
        } else {
        }
    } catch (e) {
    }
}



const pointsHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_REFERRALS: {
            return {
                ...state,
                referrals: action.referrals
            }
        }
        default: return state;
    }
}


export default pointsHistoryReducer;
