
import React, { Component, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// import { pushPath } from '../reducers/navigation/navigation'
// import LoyaltyProfilePage from './LoyaltyProfilePage'
// import CurrentCard from '../containers/CurrentCard';
import getImage from "../../../images";
import styles from '../../../styles/gifting.css';
import { giftData, rawThemes, themes } from '../../../assets/rawData';
import ContinueButton from '../../common/Button.Continue';
import { pushPath } from '../../../reducers/navigation'
import Loader from '../../common/Loader';
import { setGiftDetails } from '../../../reducers/gifting';

export const GiftCards = (props) => {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)
  const [selectedCard, setselectedCard] = useState([])
  const { themesList, loading } = props || {}

  const handleContinue = (item, subItem) => {
    dispatch(setGiftDetails(
      {
        brandId: item.brandId,
        categoryId: item.categoryId,
        giftingCardMasterId: item.giftingCardMasterId,
        themeId: subItem.id,
        ...selectedCard
      }
    ))

    dispatch(pushPath('/gift-details', selectedCard))
    window.scrollTo({ top: 0, behavior: 'auto' });
  }


  return (
    <div className={`${styles['gifting-home-fr']}`}  >
      {
        loading === true
          ?
          <Loader />
          :
          (!!theme.themesList?.success === true
            ?
            themesList?.data?.map((item, i) =>
            (<div key={i} className={styles['rewards1']} style={{ width: "100%" }}>

              <div className={`${styles['fr-heading-sub-title']}`}>{item.categoryName}</div>
              <div
                className={`${styles['root']}`}
              >{
                  !!item.theme.length === true
                    ?
                    (item.theme.map((subItem, j) =>
                      !!subItem.theme ?
                        <div
                          key={j}
                          style={{ paddingRight: 15, position: 'relative' }}>
                          {
                            subItem.id === selectedCard?.id ?
                              <img alt="Img" style={
                                {
                                  position: 'absolute', right: 22, top: 9,
                                  height: 20, borderRadius: 10,
                                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)'
                                }
                              }
                                src={getImage('green_tick.svg')}
                              />
                              :
                              null
                          }

                          <img
                            onClick={() => setselectedCard({ ...subItem })}
                            src={subItem.theme}
                            className='card-shadow'
                            style={{
                              cursor: 'pointer',
                              width: 347, height: 186, borderRadius: 10,

                              boxShadow:
                                (subItem.id === selectedCard?.id)
                                  ?
                                  '0px 20px 30px -15px rgba(0,0,0,0.86)'
                                  :
                                  'none'
                            }}
                            alt="Img" />
                          {
                            (subItem.id === selectedCard?.id)
                            &&
                            <div style={{ position: 'absolute', bottom: 0 }}>
                              <ContinueButton
                                actionTitle={'Continue'}
                                action={() => handleContinue(item, subItem)} />
                            </div>
                          }
                        </div>
                        :
                        null
                    ))
                    :
                    <div style={{
                      paddingRight: 15, alignItems: 'center',
                      font: 'normal normal light 12px/14px Open Sans',
                      color: 'grey'

                    }}>
                      No themes found.
                    </div>
                }
              </div>
            </div>)
            )
            :
            !!theme.error && theme.loading === false ?
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <span className={styles['fr-modal-title']}>{theme.error?.message}</span>
              </div>
              :
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <span className={styles['fr-modal-title']}>{themesList?.message}</span>
              </div>
          )
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  themesList: state.theme.themesList,
  loading: state.loader
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards)
