import React, { useEffect, Component, useState, useRef } from 'react';
import Modal from 'react-modal';
import CustomDatePicker from '../common/DatePicker';
import getImage from "../images";
import styles from '../styles/loyalty.css';
import DateSelect from '../common/DateSelect'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -40%)',
        padding: '5px',
        height: '80vh',
        overflow: 'hidden'
    },
    overlay: {
        background: '#000000e0'
    }
};

export default class SignUpPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            profileImage: '',
            dob: null,
            passYear: null,
            termsCheck: false,
            signUpData: {

            }
        }
    }

    render() {



        const { isModalOpen, closeModal, openModal, pushPath, loading, attributes, LoyaltySignUp, status, termsUrl } = this.props;



        const onChange = (value, slug) => {
            const key = slug;
            this.setState({ signUpData: { ...this.state.signUpData, [key]: value } });
        }


        const formHead = attributes && attributes.filter((i) => { return i.type === "title" });
        const formTitle = formHead && formHead[0] ? formHead[0].title : "Additional Information Required";
        const formSubTitle = formHead && formHead[0] ? formHead[0].subTitle : "Fill this up to complete your signup!";

        return (
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div style={{ padding: 20, maxHeight: '90%', overflowY: 'scroll' }}>

                    {!loading ?
                        status.success ?
                            <div className={styles['fr-signup-head']} style={{ marginBottom: 15 }}>
                                <div>
                                    <span className={`${styles['fr-success-msg-1']} success-msg-1`}>Successfully Signed Up</span><br />
                                    <span className={`${styles['fr-success-msg-2']} success-msg-2`}>Thank you for registering with our loyalty program!</span>
                                </div>
                                {status.offers && status.offers.map((i, index) => {
                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className={`${styles['fr-modal-title']} offer-title`}>{i.name}</span>
                                            <span className={`${styles['fr-modal-sub-title']} offer-coupon`}>{i.couponCode}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div>
                                <span style={{ color: 'red', fontSize: 12, }}>{status && status.message || ''}</span>

                                <div className={`${styles['fr-signup-head']} form-head`} style={{ marginBottom: 15 }}>
                                    <span className={`${styles['fr-modal-title']} form-title`}>{formTitle}</span>
                                    <span className={`${styles['fr-modal-sub-title']} form-subtitle`}>{formSubTitle}</span>
                                </div>

                                {attributes && attributes.map((item, index) => {
                                    return <RenderField item={item} key={index}
                                        onChange={(val, key) => {
                                            onChange(val, key)
                                        }} />
                                })}

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <input type="checkbox" checked={this.state.termsCheck} onClick={() => {
                                        this.setState({ termsCheck: !this.state.termsCheck })
                                    }} className="terms-check-box" />
                                    <span style={{ fontSize: 14, marginLeft: 10 }}>
                                        By enrolling to the Program, I accept their<br />
                                        <a target='_blank' href={termsUrl} className="terms-link">Terms & Conditions</a>
                                    </span>
                                </div>

                            </div>

                        : <span>
                            Fields are loading...
                        </span>}
                </div>
                {status.success ?
                    <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                        <a onClick={() => {
                            pushPath('/loyalty', null)
                        }}
                            style={{ width: '100%', fontSize: 16, textAlign: 'center', cursor: 'pointer' }}>Go to Profile<img src={getImage('arrow.svg')} alt="Img" /></a>
                    </div>
                    :
                    <div style={{ marginTop: 10 }}>
                        <button onClick={() => {
                            LoyaltySignUp(this.state.signUpData)
                        }}
                            className={`${styles['fr-redeem-btn']} fr-btn-cutom`} style={{ width: '100%', height: 40, fontSize: 16 }} disabled={!this.state.termsCheck}>Submit<img src={getImage('arrow.svg')} alt="Img" /></button>
                    </div>}
            </Modal>
        )
    }
}



const TextField = ({ item, type, onChange, value, readOnly }) => {

    const icons = {
        "name": getImage('profile_icon.svg'),
        "email": getImage('profile_icon.svg'),
        "phone": getImage('phone.svg'),
    }

    return (
        <div className={styles["fr-custom-field"]} style={{ backgroundColor: readOnly ? '#e8ecf1' : 'auto', cursor: readOnly ? 'not-allowed' : 'auto' }}>
            <div className={styles["fr-custome-field-inner"]}>
                <span className={styles["fr-custom-field-label"]}>
                    {item.label} {item.required ? <span style={{ color: 'red' }}>*</span> : null}
                </span>
                <input style={{ backgroundColor: readOnly ? '#e8ecf1' : 'auto', cursor: readOnly ? 'not-allowed' : 'auto' }} placeholder={item.placeholder || `Please enter ${item.label}`} disabled={readOnly} onChange={onChange} value={value} />
            </div>
            <img alt="Img" src={icons[type]} />
        </div>
    )
}



const UploadFile = ({ item, onChange }) => {
    const [uploadFileName, setUploadFileName] = useState("")
    const inputref = useRef()
    return (
        <div>
            <div className={styles['fr-upload-id']} onClick={() => inputref.current.click()}>
                <img src={getImage('upload_icon.svg')} alt="Img" />
                <span id="file_name">{uploadFileName == "" ? item.label : uploadFileName}</span>{item.required ? <span style={{ color: 'red' }}>*</span> : null}
            </div>
            <input ref={inputref} style={{ display: "none" }} type="file" id="upload_doc_btn"
                onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                        setUploadFileName(e.target.files[0].name)
                        const reader = new FileReader();

                        return new Promise(resolve => {
                            reader.onload = ev => {
                                resolve(ev.target.result);
                                onChange(ev.target.result);
                            }
                            reader.readAsDataURL(e.target.files[0])
                        })
                    }
                }}
            />
        </div>
    )
}

const SelectField = ({ item, onChange }) => {
    return (
        <div className={styles["fr-custom-field"]}>
            <div className={styles["fr-custome-field-inner"]}>
                <span className={styles["fr-custom-field-label"]}>
                    {item.label}{item.required ? <span style={{ color: 'red' }}>*</span> : null}
                </span>
                <select className={styles["fr-custom-select"]} onChange={onChange} id="select">
                    <option value=""> {item.placeholder || `Please select ${item.label}`}</option>
                    {item.options.map((i, index) => {
                        return <option value={i.slug} >{i.label}</option>
                    })}
                </select>
            </div>
            <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
        </div>
    )
}

class DateField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date: null
        }
    }
    render() {
        const { item, onChange } = this.props;
        return (
            <div className={styles["fr-custom-field"]}>
                <div className={styles["fr-custome-field-inner"]}>
                    <span className={styles["fr-custom-field-label"]}>
                        {item.label}{item.required ? <span style={{ color: 'red' }}>*</span> : null}
                    </span>
                    <input type="date" className={styles['fr-custom-date-field']} onChange={e => {
                        onChange(e.target.value);
                        this.setState({ date: e.target.value })
                    }} value={this.state.date} />

                </div>
            </div>
        )
    }

}



class SectionBreak extends Component {
    render() {
        const { item } = this.props;
        return (
            <div className={styles["fr-section-break"]}>
                {/* SectionBreak */}
                <span style={{ fontSize: 15, fontWeight: 'bold' }}>
                    <div dangerouslySetInnerHTML={{ __html: item.title || "" }} />
                </span>
                <span style={{ fontSize: 12, color: '#4D4D4D' }}>
                    <div dangerouslySetInnerHTML={{ __html: item.subTitle || "" }} />
                </span>
            </div>
        )
    }
}

class SubSectionBreak extends Component {
    render() {
        const { item } = this.props;
        return (
            <div style={{ marginBottom: 5 }}>
                {/*Sub SectionBreak */}
                <span style={{ fontSize: 13, textTransform: 'uppercase' }}>
                    <div dangerouslySetInnerHTML={{ __html: item.title || "" }} />
                </span>
            </div>
        )
    }
}

export class RenderField extends Component {
    render() {

        const { item, onChange, value, readOnly } = this.props

        const fields = {
            "text": <TextField readOnly={readOnly} item={item} type="name" value={value} onChange={(e) => onChange(e.target.value, item.slug)} />,
            "email": <TextField readOnly={readOnly} item={item} type="email" value={value} onChange={(e) => onChange(e.target.value, item.slug)} />,
            "phone": <TextField readOnly={readOnly} item={item} type="phone" value={value} onChange={(e) => onChange(e.target.value, item.slug)} />,
            "date-select": <DateSelect readOnly={readOnly} item={item} value={value} onChange={(date) => onChange(date, item.slug)} />,
            "date": <CustomDatePicker item={item} value={value} onChange={(date) => onChange(date, item.slug)} />,
            "select": <SelectField item={item} value={value} onChange={(e) => onChange(e.target.value, item.slug)} />,
            "file": <UploadFile item={item} onChange={(result) => onChange(result, item.slug)} />,
            "sectionBreak": <SectionBreak item={item} />,
            "subSectionBreak": <SubSectionBreak item={item} />
        };

        return (
            fields[item.type] || null
        )
    }
}




