import React, { Component } from 'react';
import BackButton from '../common/BackButton';
import Heading from '../common/Heading';
import getImage from "../images";
import styles from '../styles/loyalty.css';

export default class ProgramListPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedIndex: 0,
            selectedItem: this.props.programs && this.props.programs[0],
            isEnrolled: this.props.enrolledPrograms && this.props.enrolledPrograms.includes(this.props.programs && this.props.programs[0]),
        }
    }

    componentDidMount() {
        this.setState({ isEnrolled: this.props.enrolledPrograms && this.props.enrolledPrograms.includes(this.state.selectedItem.id) })
    }

    render() {

        const { programs, pushPath, openSignUpModal, getEnrollmentCriteria, enrolledPrograms } = this.props;

        const selectCard = (item, index) => {

            if (enrolledPrograms && enrolledPrograms.includes(item.id)) {
                this.setState({ isEnrolled: true, selectedItem: item, selectedIndex: index })
            }
            else {
                this.setState({ isEnrolled: false, selectedItem: item, selectedIndex: index })
            }


        }



        return (
            <div style={{ minHeight: '100vh' }}>
                <div>
                    <BackButton onclick={() => pushPath('/loyalty', null)} />
                    <hr />
                </div>
                <div>
                    <Heading title="Choose" subTitle="Loyalty Program" />
                    <div className={styles['fr-programs-list-div']}>
                        {programs && programs.map((item, index) => {
                            return <ProgramItem selectCard={() => selectCard(item, index)} item={item} key={index} isSelected={index === this.state.selectedIndex ? true : false} />
                        })}
                    </div>
                </div>

                <div>
                    <Heading title="Benefits Available" subTitle="With This Program" />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {this.state.selectedItem.benefits && this.state.selectedItem.benefits.map((item, index) => {
                            return <BenefitItem item={item} />
                        })}
                    </div>
                </div>

                <div>
                    <Heading title="About" subTitle={this.state.selectedItem.name + " Program"} />
                    <div>
                        <p>{this.state.selectedItem.about}</p>
                    </div>
                </div>
                <div>
                    {this.state.isEnrolled ?
                        <button className={`${styles['fr-redeem-btn']} fr-btn-cutom`} style={{ height: 50, fontSize: 16 }}
                            onClick={() => {
                                pushPath('/loyalty', null)
                            }}
                        >View Details<img src={getImage('arrow.svg')} alt="Img" /></button>
                        :
                        <button className={`${styles['fr-redeem-btn']} fr-btn-cutom`} style={{ height: 50, fontSize: 16 }}
                            onClick={() => {
                                openSignUpModal();
                                getEnrollmentCriteria(this.state.selectedItem.id);
                            }}
                        >Enroll Now<img src={getImage('arrow.svg')} alt="Img" /></button>
                    }
                </div>

            </div>
        )
    }
}

class ProgramItem extends Component {
    render() {
        const { item, isSelected, selectCard } = this.props;
        return (
            <div onClick={() => selectCard()} style={{ marginBottom: 15, backgroundColor: `${item.backgroundColor}`, backgroundImage: `url(${item.backgroundImage})`, boxShadow: `0 38px 7px -32px ${item.backgroundColor}` }} className={styles['fr-program-card']}>
                <img src={isSelected ? getImage('green_tick.svg') : getImage('grey_tick.svg')} alt="Img" className={styles['fr-tick-mark-img']} />
                <span className={styles['fr-program-card-title']} style={{ color: item.textColor }}>{item.name}</span>
                <span className={styles['fr-program-card-sub-title']} style={{ color: item.textColor }}>Program</span>
            </div>
        )
    }
}

class BenefitItem extends Component {
    render() {
        const { item } = this.props;
        return (
            <div className={styles['fr-benefit-small-card']} style={{ background: `${item.background}` }}>
                <img src={item.itemImage} alt="benefit" className={styles['fr-benefit-small-img']} />
                <span className={styles['fr-offer-card-title']}>{item.offerName}</span>
                <span style={{ color: "#FFF", textAlign: 'center', fontSize: 12 }}>{item.itemName}</span>
                <button className={styles['fr-link-white-btn']}>
                    <span>Details</span><img src={getImage('white_arrow.svg')} alt="Img" />
                </button>
            </div>
        )
    }
}
