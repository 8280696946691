import React, { Component } from 'react';
import styles from '../styles/loyalty.css';
import { RenderField } from './SignUpPage';
import OtpForm from './OtpForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import getImage from '../images';

export default class RegFormPage extends Component {

    // componentDidMount()
    // {
    //     const { getSignUpTemplate } = this.props;
    //     getSignUpTemplate();
    // }

    render() {
        const { themeColors, programList, source } = this.props;
        return (
            <div className={`${styles['fr-signup-root-form']} signup-root-form`}>
                {this.props.verifyMobile ?
                    <OtpForm themeColors={themeColors}
                        preFillData={this.props.preFillData}
                        status={this.props.status} defaultCountry={this.props.defaultCountry} isMultiCountryEnable={this.props.isMultiCountryEnable} getLoginOtp={(val) => this.props.getLoginOtp(val)} isOtpSent={this.props.isOtpSent} verifyOtp={this.props.verifyOtp} />
                    :
                    programList && programList.length > 0 ?
                        <SignUpForm
                            signUpConfiguration={this.props.signUpConfiguration}
                            offerText={this.props.offerText}
                            preFillData={this.props.preFillData}
                            readOnly={this.props.readOnly}
                            LoyaltySignUp={this.props.LoyaltySignUp}
                            getEnrollmentCriteria={this.props.getEnrollmentCriteria}
                            getPrograms={this.props.getPrograms}
                            programList={this.props.programList}
                            attributes={this.props.attributes}
                            loading={this.props.loading}
                            status={this.props.status}
                            termsUrl={this.props.termsUrl}
                            themeColors={themeColors}
                            pushPath={this.props.pushPath}
                            source={source}
                        /> : null
                }
            </div>
        )
    }
}


class SignUpForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            termsCheck: false,
            signUpData: {
                source: props.source
            }
        }
    }

    componentDidMount() {

        this.props.programList && this.props.programList.length > 0 && this.props.getEnrollmentCriteria(this.props.programList[0].id);

        this.props.preFillData && this.props.preFillData.forEach(i => {
            this.setState({ signUpData: { ...this.state.signUpData, [i.slug]: i.value } });
        });
    }

    render() {
        const { loading, attributes, LoyaltySignUp, status, offerText, preFillData, readOnly, termsUrl, themeColors, pushPath } = this.props;


        const onChange = (value, slug) => {
            const key = slug;
            this.setState({ signUpData: { ...this.state.signUpData, [key]: value } });
        }

        const formHead = attributes && attributes.filter((i) => { return i.type === "title" });
        const formTitle = formHead && formHead[0] ? formHead[0].title : "Additional Information Required";
        const formSubTitle = formHead && formHead[0] ? formHead[0].subTitle : "Fill this up to complete your signup!";

        return (
            this.props.programList && this.props.programList.length > 0 ?
                <div style={{ marginTop: 10 }}>
                    {!loading ?
                        status.success ?
                            <div className={`${styles['fr-signup-head']} form-head`} style={{ marginBottom: 15, textAlign: 'center' }}>
                                <div>
                                    <span className={`${styles['fr-success-msg-1']} success-msg-1`}>Successfully Signed Up</span><br />
                                    <span className={`${styles['fr-success-msg-1']} success-msg-1`}>Thank you for registering with our loyalty program!</span>
                                </div>

                                {status.offers && status.offers.map((i, index) => {
                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                                            <span className={`${styles['fr-modal-title']} offer-title`}>{i.name}</span>
                                            <span className={`${styles['fr-coupon-signup']} offer-coupon`}>{i.couponCode}</span>
                                        </div>
                                    )
                                })}

                                <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                                    <a onClick={() => {
                                        pushPath('/loyalty', null)
                                    }}
                                        style={{ width: '100%', fontSize: 16, display: 'flex', alignItems: 'center', textAlign: 'center', cursor: 'pointer' }}>Loyalty Program<img src={getImage('arrow.svg')} alt="Img" /></a>
                                </div>

                            </div>
                            :
                            <div style={{ textAlign: 'center' }}>
                                <div className={`${styles['fr-signup-head']} form-head`} style={{ marginBottom: 15 }}>
                                    <span className={`${styles['fr-modal-title']} form-title`}>{offerText || formTitle}</span>
                                    <span className={`${styles['fr-modal-sub-title']} form-subtitle`}>{formSubTitle}</span>
                                </div>

                                {attributes && attributes.map((item, index) => {
                                    let current = preFillData && preFillData.find((i) => {
                                        return item.slug == i.slug;
                                    });

                                    let onlyRead = current && current.value && readOnly && readOnly[current && current.slug] ? true : false;

                                    return <RenderField item={item} value={this.state.signUpData[current && current.slug]} key={index} readOnly={onlyRead}
                                        onChange={(val, key) => {
                                            onChange(val, key)
                                        }} />
                                })}

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 15 }}>
                                    <input type="checkbox" checked={this.state.termsCheck} onClick={() => {
                                        this.setState({ termsCheck: !this.state.termsCheck })
                                    }} className="terms-check-box" />
                                    <span style={{ fontSize: 14, marginLeft: 7 }}>
                                        By enrolling to the Program, I accept their <br />
                                        <a href={termsUrl} target='_blank'>Terms & Conditions</a>
                                    </span>
                                </div>

                                <span style={{ color: 'red', fontSize: 12, textAlign: 'center' }}>{status && status.message || ''}</span>

                                <button onClick={() => {
                                    if (this.state.termsCheck) {
                                        LoyaltySignUp(this.state.signUpData)
                                    }
                                    else {
                                        alert("please accept the terms & conditions");
                                    }
                                }}
                                    className={`${styles['fr-redeem-btn']} fr-btn-cutom`}
                                    // disabled={!this.state.termsCheck}
                                    style={{ backgroundColor: themeColors && themeColors.buttonBgColor, color: themeColors && themeColors.buttonTextColor, width: '100%', height: 40, fontSize: 16 }}>
                                    Submit<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowCircleRight} />
                                </button>
                            </div>

                        : <span>
                            Fields are loading...
                        </span>}

                </div>
                :
                <div className={styles['fr-signup-head']} style={{ marginBottom: 15 }}>
                    <span className={styles['fr-modal-sub-title']}>{"Form is loading"}</span>
                </div>
        )

    }
}


