import React, { Component } from 'react'
import styles from '../styles/loyalty.css';

export default class Loader extends Component {
    render() {
        return (
            <div style={{ display: 'flex', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <div className={styles['fr-signup-head']}>
                    <span className={styles['fr-modal-sub-title']} style={{ fontSize: 20 }}>Your Rewards are</span>
                    <span className={styles['fr-modal-title']}>Loading!</span>
                </div>
            </div>
        )
    }
}
