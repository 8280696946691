import React, { Component } from 'react'
import Header from '../common/Header';
import getImage from "../images";
import styles from '../styles/referral.css';

export default class PointHistoryPage extends Component {
    render() {
        const { transactions, basicConfig,customeCss } = this.props;
        const {customCss} = customeCss;
        const { showEmail, showPhone } = basicConfig || {};

        return (
            <div>
              <style>{customCss}</style>
                <div>
                    <Header title="Your" subTitle="Referral History" />
                    <div className={styles['fr-history-list']}>
                        <table>
                            <tr style={{textAlign:'center'}}>
                                <th>Referree</th>
                                {showPhone == "1" && <th>Phone Number</th>}
                                {showEmail == "1" && <th>Email</th>}
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                            {
                                transactions && transactions.map((item, index) => {
                                    var classPoints = item.status == "Pending" ? 'fr-yellow-text' : "fr-green-text";
                                    var statusLabel = item.status == "Pending" ? 'Awaiting' : "Credited";

                                    var date = new Date(item.date);
                                    var formatted = date.toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' });
                                    return (
                                        <tr key={index} style={{textAlign:'center'}}>
                                            <td className={styles['fr-text']}>{item.name || "-"}</td>
                                            {showPhone == "1" && <td className={styles['fr-text']}>{item.phoneNumber || "-"}</td>}
                                            {showEmail == "1" && <td className={styles['fr-text']}>{item.email || "-"}</td>}
                                            <td className={styles['fr-text']}>{formatted || "-"}</td>
                                            <td className={styles[classPoints]}>{statusLabel || "-"}</td>
                                        </tr>
                                    )

                                })
                            }
                            {
                                transactions && transactions.length === 0 && (
                                    <tr>
                                        <td colSpan="5">
                                            Start your referral journey now!
                                        </td>
                                    </tr>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
