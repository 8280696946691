import { pushPath } from "./navigation";
import { setTheme } from "./theme";
import apiClient from '../api';
import { setLoading } from "./loader";

const initialState = {
  merchantAccessToken: null,
  authToken: null,
  loyaltyId: null,
  showIntroduction: null,
  isEnrolled: null,
  knowMoreUrl: '',
};

const SET_TOKENS = 'session/SET_TOKENS';
const DENOMINATION__CONFIG = 'session/DENOMINATION__CONFIG';
const SESSION_ERROR = 'session/SESSION_ERROR';

const SET_INTRO_INFO = "session/SET_INTRO_INFO";
const CLOSE_INTRO_MODAL = "session/CLOSE_INTRO_MODAL";
const SET_AUTH_TOKEN = "session/SET_AUTH_TOKEN";
const USER_LOCATION = "session/USER_LOCATION";
const TERMS_AND_POLICIES = "session/TERMS_AND_POLICIES";


const displayError = (title, message, loading = 'loading') => ({
  type: SESSION_ERROR,
  title,
  message,
  loading,
});

export const closeIntroModal = () => async (dispatch, getState) => {
  await dispatch({
    type: CLOSE_INTRO_MODAL,
  });
}

export const initialize = (params) => async (dispatch, getState) => {
  await dispatch({
    type: SET_TOKENS,
    payload: params
  });

  // dispatch(getUserDetails(routeName));
  // dispatch(setTheme(horizontalStyle));
}


export const getUserLocationAction = () => async (dispatch, getState) => {
  const state = getState();
  const { merchantAccessToken, baseUrl } = state.session;
  try {
    const response = await apiClient.get(apiClient.Urls.userLocation(baseUrl), {
      merchantAccessToken,
    });
    if (!!response) {
      dispatch({
        type: USER_LOCATION,
        payload: response
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};


export const getDenominationConfig = () => async (dispatch, getState) => {
  const state = getState();
  const { authToken, merchantAccessToken, baseUrl } = state.session;
  try {
    const response = await apiClient.get(apiClient.Urls.denominationConfig(baseUrl), {
      // authToken,
      merchantAccessToken,
    });
    if (!!response) {
      dispatch({
        type: DENOMINATION__CONFIG,
        payload: response
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};

export const getConfigurationAction = () => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const state = getState();
  const { authToken, merchantAccessToken, baseUrl } = state.session;
  try {
    const response = await apiClient.get(apiClient.Urls.content(baseUrl), {
      // authToken,
      model: 'termsAndPolicies',
      merchantAccessToken,
    });
    console.log({ response })
    if (!!response) {
      dispatch(setLoading(false))
      dispatch({
        type: TERMS_AND_POLICIES,
        payload: response,
      });
    } else {
      dispatch(setLoading(false))
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(displayError('EXCEPTION', e.message));
  }
};


export const setupIntroInfo = (showIntroduction, isEnrolled, routeName) => async (dispatch, getState) => {
  await dispatch({
    type: SET_INTRO_INFO,
    showIntroduction,
    isEnrolled
  });
  routeName ?
    dispatch(pushPath(routeName, null)) :
    dispatch(pushPath(isEnrolled ? '/loyalty' : '/programs', null))
}

export const setAuthToken = (authToken) => async (dispatch, getState) => {
  await dispatch({
    type: SET_AUTH_TOKEN,
    authToken
  });

}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_ERROR: {
      return {
        ...state,
        [action.loading]: false,
        error: {
          title: action.title,
          message: action.message,
        }
      }
    }

    case SET_TOKENS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case DENOMINATION__CONFIG: {
      return {
        ...state,
        denominationConfig: action.payload
      }
    }

    case USER_LOCATION: {
      return {
        ...state,
        location: action.payload
      }
    }

    case TERMS_AND_POLICIES: {
      return {
        ...state,
        termsConditions: action.payload
      }
    }

    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.authToken,
      }
    }
    case SET_INTRO_INFO: {
      return {
        ...state,
        showIntroduction: action.showIntroduction,
        isEnrolled: action.isEnrolled,
      }
    }
    case CLOSE_INTRO_MODAL: {
      return {
        ...state,
        showIntroduction: false,
      }
    }

    default: return state;
  }

}


export default sessionReducer;