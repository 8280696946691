import React, { Component } from 'react';
import { connect } from 'react-redux';
import TransactionHistoryPage from '../components/TransactionHistoryPage';
import { pushPath } from '../reducers/navigation/navigation';
import { filterTransactionHistory, getTransactionHistory, getMerchantinfo } from '../reducers/TransactionHistory/TransactionHistory';
import { initializeHistory } from "../reducers/session/session";
import RegForm from './RegForm';

class TransactionHistory extends Component {

    constructor(props) {
        super(props)
    }

    async componentDidMount() {

        window.scrollTo(0, 0);
        if (this.props.isMainComponent) {
            this.props.getMerchantinfo()
            await this.props.initializeHistory(this.props.merchantAccessToken, this.props.authToken, this.props.loyaltyId);
        } else {
            if (this.props.authToken) {
                this.props.filterTransactionHistory(`${new Date().getFullYear() - 1}-04-01`, `${new Date().getFullYear()}-04-01`, null)

                // this.props.getTransactionHistory()
            };
        }
    }

    render() {
        const { customCss, userDetails, getTransactionHistory, merchantinfo } = this.props;

        if (userDetails && userDetails.isEnrolled) {
            return (
                <TransactionHistoryComponent filterTransactionHistory={this.props.filterTransactionHistory} merchantInfo={merchantinfo} customCss={customCss} getTransactionHistory={getTransactionHistory} themeColors={this.props.themeColors} isMainComponent={this.props.isMainComponent || false} pushPath={this.props.pushPath} pointsInfo={this.props.pointsInfo} transactions={this.props.transactions} />
            );
        }
        else {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <style>{customCss}</style>
                    <RegForm
                        authToken={this.props.authToken}
                        merchantAccessToken={this.props.merchantAccessToken}
                        loyaltyId={this.props.loyaltyId}
                        isMultiCountryEnable={this.props.isMultiCountryEnable}
                        defaultCountry={this.props.defaultCountry}
                        preFillData={this.props.preFillData}
                        {...this.props}
                    />
                </div>
            )
        }
    }
}


class TransactionHistoryComponent extends Component {
    render() {
        const { customCss } = this.props;
        return (
            <div>
                <style>{customCss}</style>
                <TransactionHistoryPage filterTransactionHistory={this.props.filterTransactionHistory} merchantInfo={this.props.merchantInfo} themeColors={this.props.themeColors} TransactionHistory={this.props.getTransactionHistory} isMainComponent={this.props.isMainComponent || false} pushPath={this.props.pushPath} pointsInfo={this.props.pointsInfo} transactions={this.props.transactions} />
            </div>
        )
    }
}



const mapState = (state, ownprops) => {
    return {
        transactions: state.TransactionHistory.transactions,
        themeColors: state.theme.themeColors,
        customCss: state.theme && state.theme.customCss,
        userDetails: state.loyalty.userDetails,
        programs: state.loyaltyprogram,
        merchantinfo: state.TransactionHistory.merchantinfo,

    };
}

const mapDispatch = {
    pushPath,
    getTransactionHistory,
    initializeHistory,
    filterTransactionHistory,
    getMerchantinfo
}

export default connect(mapState, mapDispatch)(TransactionHistory);
