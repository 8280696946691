import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getProgram,getReferralProgram } from '../reducers/referral/referral';
import HowItWorksPage2 from '../components/HowItWorksPage2';

class HowItWorks2 extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getProgram();
        this.props.getReferralProgram();
    }

    render() {
        const { program,referralProgram} = this.props;
        return (
            <HowItWorksPage2 program={program} referralProgram={referralProgram}/>
        )
    }
}

const mapState = (state, ownprops) => {

    return {
        program: state.referral.program,
        referralProgram:state.referral.referralProgram
    };
}

const mapDispatch = {
    getProgram,
    getReferralProgram
}

export default connect(mapState, mapDispatch)(HowItWorks2);
