import React, { Component } from 'react'
import { connect } from 'react-redux';
import getImage from '../images';
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon, faCircleChevronRight } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faStar } from '@fortawesome/free-solid-svg-icons'
class BenefitItem2 extends Component {
    render() {
        const { item, onclick, benefitStyles, type, icons, userPoints, themeColors } = this.props;
        const { listItemBackground, listItemTextColor, exclusiveItemBackground, exclusiveItemTextColor } = benefitStyles || {};
        const bgcolor = type == 'exclusive' ? exclusiveItemBackground || '#ddd' : listItemBackground || '#ddd';
        const color = type == 'exclusive' ? exclusiveItemTextColor || '#fff' : listItemTextColor || '#FFF';
        const { pointsIcon } = icons || {};
        const value = parseFloat(item.worth) - parseFloat(userPoints)

        return (
            <div style={{ flex: 1 }}>
                <div style={{
                    marginTop: 10,
                    marginBottom: 15,
                    backgroundColor: themeColors?.freebieBgColor || "#dfe1f6",
                    display: "flex", borderRadius: 6, flexDirection: "column"
                }}>
                    <div style={{ display: "flex" }}>
                        <img src={item.image} alt="Img" style={{ width: "100%", height: 100, objectFit: "cover", borderRadius: 6, margin: 5, alignSelf: "center" }} />
                    </div>
                    <img src={item.image} alt="Img" style={{ width: 80, height: 80, objectFit: "cover", borderRadius: 80, position: "absolute", marginTop: 48, marginLeft: 12 }} />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <div style={{
                                display: "flex", justifyContent: "flex-end", alignItems: "center", width: "97%"
                            }}>
                                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", padding: 4, borderRadius: 6, }}>
                                    <FontAwesomeIcon icon={faStar} color='#fff' />
                                    <span style={{ fontSize: 15, color: '#fff' }}>{parseFloat(item.worth)} Points</span>
                                </div>
                            </div>
                        </div>
                        <span style={{ color: themeColors?.themeColor, marginLeft: 15, marginTop: 10, fontSize: 15, width: "97%" }}>{item.name.length > 30 ? `${item.name.slice(0, 30)}...` : item.name}</span>
                        <span style={{ color: '#000', marginLeft: 15, fontSize: 15, marginTop: 4, width: "97%" }}>{item.description || ''}</span>
                    </div>
                    <div className={`${styles['btn-div']}`} style={{ marginBottom: 20 }}>
                        <button style={{ color: color || '#000', backgroundColor: themeColors?.buttonBgColor, borderRadius: 5, height: 40 }} className={`${styles['fr-link-black-btn2']} `} onClick={() => onclick()}>
                            {parseInt(userPoints) >= parseInt(item.worth) ?
                                <span className={`${styles['link-btn-label']}`} style={{ fontWeight: "bold" }}>Buy Now for {parseFloat(item.worth)} Points</span>
                                : <span className={`${styles['link-btn-label']}`} style={{ fontWeight: "bold" }}>Earn {value} more points to redeem</span>}
                            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                        </button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    const { benefitStyles, icons, themeColors } = state.theme;

    return {
        benefitStyles,
        icons,
        themeColors
    };
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(BenefitItem2);

