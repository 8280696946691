import React, { Component } from 'react'
import getImage from '../images';
import BackButton from './BackButton';

export default class Success extends Component {
    render() {
        const { transactionData, pushPath, clearTransactionDetails, isSignUp } = this.props;
        return (
            <div>

                <div >
                    <BackButton onclick={() => pushPath('/loyalty', null)} />
                    <hr />
                </div>

                <div style={{ display: "flex", minHeight: "90vh", padding: 20, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <span style={{ marginTop: '1rem', marginBottom: '1rem', color: "#48c774", fontSize: "2rem", fontWeight: "bold" }}>Thank You!</span>
                    <span style={{ color: "#7a7a7a", fonSize: "1.5rem", fontWeight: "600", marginBottom: '1rem', }}>Your payment is Successful!</span>
                    <span style={{ color: " #7a7a7a", textAlign: 'center', fontSize: "1rem", marginBottom: '1rem', }}>Here is your transaction reference ID:
                        <span style={{ fontWeight: 'bold' }}>{transactionData && transactionData.transactionId}</span></span>
                    <span style={{ color: " #7a7a7a", fontSize: "1rem", textAlign: 'center' }}>The program upgradation is in process. Once it is completed, you'll receive an update via SMS &amp; Email.</span>
                    <div style={{ marginTop: 10 }}>
                        <a onClick={() => {
                            pushPath('/loyalty', null)
                        }}
                            style={{ width: '100%', fontSize: 16, display: 'flex', alignItems: 'center', textAlign: 'center', cursor: 'pointer' }}>Loyalty Program<img src={getImage('arrow.svg')} alt="Img" /></a>
                    </div>
                </div>
            </div>
        )
    }
}
