import React, { Component } from 'react';
import { initializeRegistration } from "../reducers/session/session";
import { getPrograms } from '../reducers/loyaltyprogram/loyaltyprogram'
import { connect } from "react-redux";
import RegFormPage from '../components/RegFormPage'
import RegFormPageTemp1 from '../components/RegFormPageTemp1'
import styles from '../styles/loyalty.css';
import { LoyaltySignUp, getEnrollmentCriteria, getLoginOtp, verifyOtp } from '../reducers/signup/signup'
import { getSignUpTemplate } from '../reducers/theme/theme'
import getImage from '../images';
import { pushPath } from '../reducers/navigation/navigation'
import RegFormPageTemp2 from '../components/RegFormPageTemp2';

class RegForm extends Component {

    constructor(props) {
        super(props)
        const { merchantAccessToken, authToken, shopifyCustomerId, postLoginCallback, } = this.props;
        this.props.initializeRegistration({ merchantAccessToken, authToken, shopifyCustomerId, postLoginCallback });
    }

    render() {
        const { auth, programs, getLoginOtp, verifyOtp, isOtpSent, preFillData, readOnly, offerText, userDetails, isMultiCountryEnable, defaultCountry, termsUrl, loyaltyUrl, themeColors, customCss, successLabels, signUpConfiguration, configuration } = this.props;

        const { programList } = programs;

        if (userDetails && userDetails.isEnrolled) {
            return (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <style>{customCss}</style>
                    <span className={`${styles['fr-modal-title']} form-title`}>{successLabels && successLabels.successMessage || `You're already part of our Rewards Program.`}</span>
                    <a href={loyaltyUrl}
                        className={`${styles['fr-redeem-btn']} fr-btn-cutom`} style={{ width: '100%', height: 40, marginTop: 50, fontSize: 16 }}>{successLabels && successLabels.buttonLabel || `View your benefits here`} <img src={getImage('arrow.svg')} alt="Img" />
                    </a>
                </div>
            )
        }

        return (
            <div>
                <style>{customCss}</style>
                {this.props.signUpConfiguration && this.props.signUpConfiguration.template == 'template1' ?
                    <RegFormPageTemp1
                        signUpConfiguration={this.props.signUpConfiguration}
                        getEnrollmentCriteria={this.props.getEnrollmentCriteria}
                        programList={programList || []}
                        attributes={this.props.attributes}
                        readOnly={readOnly}
                        LoyaltySignUp={this.props.LoyaltySignUp}
                        loyaltyKnowMore={this.props.loyaltyKnowMore}
                        themeColors={this.props.themeColors}
                        verifyMobile={auth ? false : true}
                        getPrograms={this.props.getPrograms}
                        loading={this.props.loading}
                        status={this.props.status}
                        termsUrl={termsUrl}
                        getLoginOtp={(val) => getLoginOtp(val)}
                        verifyOtp={verifyOtp}
                        isOtpSent={isOtpSent}
                        preFillData={preFillData}
                        offerText={offerText}
                        isMultiCountryEnable={isMultiCountryEnable}
                        defaultCountry={defaultCountry}
                        pushPath={this.props.pushPath}
                    />
                    :
                    this.props.signUpConfiguration && this.props.signUpConfiguration.template == 'template2' ?
                        <RegFormPageTemp2
                            signUpConfiguration={this.props.signUpConfiguration}
                            getEnrollmentCriteria={this.props.getEnrollmentCriteria}
                            programList={programList || []}
                            attributes={this.props.attributes}
                            readOnly={readOnly}
                            LoyaltySignUp={this.props.LoyaltySignUp}
                            themeColors={this.props.themeColors}
                            verifyMobile={auth ? false : true}
                            getPrograms={this.props.getPrograms}
                            loading={this.props.loading}
                            status={this.props.status}
                            termsUrl={termsUrl}
                            getLoginOtp={(val) => getLoginOtp(val)}
                            verifyOtp={verifyOtp}
                            isOtpSent={isOtpSent}
                            preFillData={preFillData}
                            offerText={offerText}
                            isMultiCountryEnable={isMultiCountryEnable}
                            defaultCountry={defaultCountry}
                            pushPath={this.props.pushPath}
                        />
                        :
                        <RegFormPage
                            signUpConfiguration={this.props.signUpConfiguration}
                            LoyaltySignUp={this.props.LoyaltySignUp}
                            getEnrollmentCriteria={this.props.getEnrollmentCriteria}
                            getPrograms={this.props.getPrograms}
                            programList={programList || []}
                            attributes={this.props.attributes}
                            loading={this.props.loading}
                            status={this.props.status}
                            termsUrl={termsUrl}
                            verifyMobile={auth ? false : true}
                            getLoginOtp={(val) => getLoginOtp(val)}
                            verifyOtp={verifyOtp}
                            isOtpSent={isOtpSent}
                            preFillData={preFillData}
                            readOnly={readOnly}
                            offerText={offerText}
                            isMultiCountryEnable={isMultiCountryEnable}
                            defaultCountry={defaultCountry}
                            themeColors={themeColors}
                            pushPath={this.props.pushPath}
                        />

                }
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    const { themeColors, customCss } = state.theme;
    return {
        navigation: state.navigation,
        attributes: state.signup.signUpAttributes,
        offerText: state.signup.offerText,
        loading: state.signup.loading,
        status: state.signup.status,
        programs: state.loyaltyprogram,
        isOtpSent: state.signup.isOtpSent,
        auth: state.session.authToken,
        userDetails: state.loyalty.userDetails,
        termsUrl: state.theme.redirectUrls && state.theme.redirectUrls.termsUrls,
        loyaltyUrl: state.theme.redirectUrls && state.theme.redirectUrls.myLoyaltyPageUrl,
        successLabels: state.theme.successLabels,
        signUpConfiguration: state.theme.signUpConfiguration,
        loyaltyKnowMore: state.theme.loyaltyKnowMore,

        themeColors,
        customCss,
    };
}

const mapDispatch = {
    initializeRegistration,
    LoyaltySignUp,
    getEnrollmentCriteria,
    getLoginOtp,
    verifyOtp,
    getPrograms,
    pushPath,
    getSignUpTemplate,

}

export default connect(mapState, mapDispatch)(RegForm);
