import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../common/Loader';
import styles from '../../../styles/gifting.css';

function TermsConditions(props) {
  const denominationConfig = useSelector(state => state.session.denominationConfig)
  const navigation = useSelector(state => state.navigation)
  const gifting = useSelector(state => state.gifting)
  const session = useSelector(state => state.session)
  const loader = useSelector(state => state.loader)

  const { termsConditions } = session || {}
  const { loading } = loader || {}
  const { terms, instructions } = denominationConfig.data || {}
  return (
    <div style={{
      display: 'flex', flexWrap: 'wrap', height: 300, paddingTop: 20
    }}>

      {
        loading === true
          ?
          <Loader />
          :
          <div>

            {
              (!!terms || !!instructions) ?
                <div className={`${styles['fr-heading-sub-title']}`}>Terms and Conditions</div>
                :
                null
            }

            {!!terms === true
              ?
              <div className="content" dangerouslySetInnerHTML={{ __html: terms }}></div>
              : null
            }

            {!!instructions === true
              ?
              <div className="content" dangerouslySetInnerHTML={{ __html: instructions }}></div>
              : null
            }

          </div>
      }
    </div>
  )
}

export default TermsConditions