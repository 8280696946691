import apiClient from '../../api';

const initialState = {
    programList: [],
    selectedCardBenefits: [],
    exclusiveBenefits: []
};

const GET_PROGRAMS = 'loyaltyprogram/GET_PROGRAMS';
const GET_CARD_FREEBIES = 'loyaltyprogram/GET_CARD_FREEBIES';
const GET_EXCLUSIVE_CARD_FREEBIES = "GET_EXCLUSIVE_CARD_FREEBIES";

export const getPrograms = () => async (dispatch, getState) => {

    const state = getState();
    const { authToken, merchantAccessToken, loyaltyId } = state.session;

    try {

        const response = await apiClient.get(apiClient.Urls.getPrograms(loyaltyId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_PROGRAMS,
                programs: response.programs,
            });
        } else {

        }
    } catch (e) {

    }
};


export const getBenfits = (cardId) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.getCardFreebies(loyaltyId, cardId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {

            dispatch({
                type: GET_CARD_FREEBIES,
                benefits: response.freebies,
            });
        } else {

        }
    } catch (e) {

    }
};

export const getExclusiveBenfits = (cardId) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.getExclusiveFreebies(loyaltyId, cardId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {

            dispatch({
                type: GET_EXCLUSIVE_CARD_FREEBIES,
                exclusiveBenefits: response.freebies,
            });
        } else {
        }
    } catch (e) {
    }
};


const programReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROGRAMS: {
            return {
                ...state,
                programList: action.programs
            }
        }
        case GET_CARD_FREEBIES: {
            return {
                ...state,
                selectedCardBenefits: action.benefits
            }
        }
        case GET_EXCLUSIVE_CARD_FREEBIES: {
            return {
                ...state,
                exclusiveBenefits: action.exclusiveBenefits
            }
        }

        default: return state;
    }

}


export default programReducer;