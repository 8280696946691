import React, { Component, useState, useEffect } from 'react';
import getImage from "../../../images";
import styles from '../../../styles/gifting.css';
import { connect, useDispatch, useSelector } from 'react-redux'
import { pushPath } from '../../../reducers/navigation';
import Offers_Terms from './Offers_Terms';
import PaymentButton from '../../common/PaymentButton';
import Step0Form from './Step0.Form';
import Step1Form from './Step1.Form';
import BackButton from '../../common/Button.Back';
import ProceedButton from '../../common/Button.Proceed';

import { validateEmail } from '../../../utils/emailValidate';
import { placeOrderAction } from '../../../reducers/placeOrder';
import { getConfigurationAction } from '../../../reducers/session';

function SelectedGiftPage(props) {
  const dispatch = useDispatch()
  const navigation = useSelector(state => state.navigation)
  const gifting = useSelector(state => state.gifting)
  const denominationConfig = useSelector(state => state.session.denominationConfig)

  const [selectedForm, setselectedForm] = useState(0)
  const [errors, seterrors] = useState({})

  const { config, currencyInfo, offerList } = denominationConfig?.data || {}

  useEffect(() => {
    dispatch(getConfigurationAction())
  }, [])

  const validationFormOne = () => {
    const { giftDetails } = gifting || {}
    let errors = {}
    let formIsValid = true;

    if (!!giftDetails.message === false && giftDetails.type === 'gift') {
      formIsValid = false;
      errors['message'] = "Please add Message."
    }

    if (!!giftDetails.message === true && giftDetails.message.length < 5 && giftDetails.type === 'gift') {
      formIsValid = false;
      errors['message'] = "Message should be at least 5 characters."
    }

    if (!!giftDetails.amount === false) {
      formIsValid = false;
      errors['amount'] = "Please add gift amount."
    }

    if (!!giftDetails.amount === true && parseFloat(giftDetails.amount) < parseFloat(config.min_val)) {
      formIsValid = false;
      errors['amount'] = `Gift amount should be at least ${config.min_val}.`
    }

    if (!!giftDetails.amount === true && parseFloat(giftDetails.amount) > parseFloat(config.max_val)) {
      formIsValid = false;
      errors['amount'] = `Gift amount should be less than ${config.max_val}.`
    }

    // if (!!giftDetails.amount === true && session.denominationConfig) {
    //   formIsValid = false;
    //   errors['amount'] = "Please add gift amount."
    // }

    seterrors(errors)
    return formIsValid;
  }

  const validationFormTwo = () => {
    const { giftDetails } = gifting || {}
    let errors = {}
    let formIsValid = true;

    if (!!giftDetails.username === false) {
      formIsValid = false;
      errors['username'] = "Please add your name."
    }

    if (!!giftDetails.username === true && giftDetails.username.length < 3) {
      formIsValid = false;
      errors['username'] = "Name should be at least 3 characters."
    }

    if (!!giftDetails.email === false) {
      formIsValid = false;
      errors['email'] = "Please enter your email."
    }

    if (!!giftDetails.email === true && !validateEmail(giftDetails.email)) {
      formIsValid = false;
      errors['email'] = "Please enter valid email."
    }

    if (!!giftDetails.customerPhone === false) {
      formIsValid = false;
      errors['customerPhone'] = "Please enter your phone number."
    }

    if (!!giftDetails.customerPhone === true && giftDetails.customerPhone.length < 10) {
      formIsValid = false;
      errors['customerPhone'] = "Please enter valid phone number."
    }

    if (giftDetails.type === 'gift') {
      if (!!giftDetails.receiverName === false) {
        formIsValid = false;
        errors['receiverName'] = "Please add recepient's name."
      }

      if (!!giftDetails.receiverName === true && giftDetails.receiverName.length < 3) {
        formIsValid = false;
        errors['receiverName'] = "Name should be at least 3 characters."
      }

      if (!!giftDetails.receiverEmail === false) {
        formIsValid = false;
        errors['receiverEmail'] = "Please enter recepient's email."
      }

      if (!!giftDetails.receiverEmail === true && !validateEmail(giftDetails.receiverEmail)) {
        formIsValid = false;
        errors['receiverEmail'] = "Please enter valid email."
      }

      if (!!giftDetails.receiverPhoneNumber === false) {
        formIsValid = false;
        errors['receiverPhoneNumber'] = "Please enter recepient's phone number."
      }

      if (!!giftDetails.receiverPhoneNumber === true && giftDetails.receiverPhoneNumber.length < 10) {
        formIsValid = false;
        errors['receiverPhoneNumber'] = "Please enter valid phone number."
      }
    }
    seterrors(errors)
    return formIsValid;
  }

  const handleForm = (param) => {
    setselectedForm(param)
  }

  const handleseterrors = (param) => {
    seterrors(param)
  }

  const handleBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    handleForm(0)
    if (selectedForm === 0) {
      dispatch(pushPath('/gifting', navigation.params))
    }
  }

  const handleProceed = () => {
    if (gifting.giftDetails.type === 'gift') {
      window.scrollTo({ top: 0, behavior: 'auto' });
      handleForm(1)
      if (selectedForm === 1) {
        dispatch(pushPath('/gift-summary', navigation.params))
      }
    }
    else {
      dispatch(placeOrderAction())
      // alert('call place order')
    }
  }


  return (
    <div style={{ padding: 15, position: 'relative' }}>
      <BackButton
        onclick={handleBack}
        buttonText={selectedForm === 0 ? 'Back to Templates' : 'Back to Card Details'}
      />

      <hr style={{ borderColor: '#E8E8E8', opacity: 0.4 }} />

      {
        selectedForm === 0
          ?
          <Step0Form
            errors={errors}
            handleseterrors={handleseterrors}
            validationFormOne={validationFormOne}
            selectedForm={selectedForm}
            handleForm={handleForm}
          />
          :
          <Step1Form
            errors={errors}
            validationFormTwo={validationFormTwo}
            selectedForm={selectedForm}
            handleForm={handleForm}
          />
      }

      <Offers_Terms {...props} />

      {/* <ProceedButton
        onclick={handleProceed}
      /> */}

    </div>
  )
}

export default SelectedGiftPage