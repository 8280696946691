import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getOrderHistoryAction } from '../../../reducers/gifting';
import { pushPath } from '../../../reducers/navigation';
import styles from '../../../styles/gifting.css';
import BackButton from '../../common/Button.Back';

function OrderHistory() {
  const dispatch = useDispatch()
  const orderHistory = useSelector(state => state.gifting.orderHistory)

  useEffect(() => {
    dispatch(getOrderHistoryAction())
  }, [])

  const handleBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(pushPath('/gifting', null))

  }

  const parseDate = (s) => {
    let dateObj = new Date(s);
    let myDate = (dateObj.getUTCFullYear()) + "/" +
      (dateObj.getMonth() + 1) + "/" +
      (dateObj.getUTCDate());
    if (!!myDate) {
      return (myDate)
    }
    else {
      return (s)
    }
  }

  return (
    <div style={{ padding: 15, position: 'relative' }}>
      <BackButton
        onclick={handleBack}
        buttonText={'Back to Templates'}
      />
      <hr style={{ borderColor: '#E8E8E8', opacity: 0.4 }} />

      <div className={`${styles['history-box']}`}>
        {orderHistory?.success === true ?
          <table className={`${styles['history-table']}`}>
            <tr className={`${styles['history-table-head']}`}>
              <th className={`${styles['history-table-head-element']}`}>Date</th>
              <th>Amount</th>
              <th>Transaction Id</th>
              <th>Type</th>
              <th>Theme</th>
              <th>Receiver Name</th>
            </tr>

            {orderHistory?.data?.length > 0
              ?
              orderHistory?.data?.map((item, i) => {
                let amt = !!item.amount ? (parseFloat(item?.amount)).toFixed(2) : '-';
                let parsedDate = parseDate(item.createdAt)
                return (
                  <tr key={i}>
                    <td className={`${styles['history-table-head-element']}`}>{item.date}</td>
                    <td style={{ alignItems: 'end' }}>{item.currencySymbol}{item.amount}</td>
                    <td>{item.transactionId}</td>
                    <td>{item.type}</td>
                    <td>
                      <img src={item.orderItems[0]?.themeImageUrl} loading='lazy'
                        style={{ height: 70, borderRadius: 6 }} />
                    </td>
                    <td>{item.orderItems[0]?.receiverName}</td>
                    <td>{item.orderStatus}</td>
                  </tr>
                )
              })
              :
              <tr>
                <td colSpan={6}>
                  No data found.
                </td>
              </tr>
            }
          </table>
          :
          <div>{orderHistory?.message}</div>
        }
      </div>
    </div>
  )
}

export default OrderHistory