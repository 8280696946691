import React, { Component } from 'react'
import styles from '../styles/loyalty.css';

export default class CustomDatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date: null
        }
    }


    render() {
        const { item, value, onChange } = this.props;
        return (
            <div className={styles["fr-custom-field"]}>
                <div className={styles["fr-custome-field-inner"]}>
                    <span className={styles["fr-custom-field-label"]}>
                        {item.label}{item.required ? <span style={{ color: 'red' }}> *</span> : null}
                    </span>
                    <input disabled={value ? true : false} onChange={(e) => {
                        onChange(e.target.value);
                        this.setState({ date: e.target.value });
                    }} type={'date'} id="date-picker" className={styles['fr-custom-date-field']}
                        value={this.state.date || value || `Please select your ${item && item.label}`} />
                </div>
            </div>
        )
    }

}