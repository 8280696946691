import React, { Component } from 'react'
import getImage from "../images";
import styles from '../styles/loyalty.css';


export default class CurrentCardPage2 extends Component {
    render() {

        const { currentCardDetails, userPoints, isFrom, pointsLabel, showLogoOnCard, logo, currency, icons, configuration, loyaltyCardLabels } = this.props;
        const { spending, status, visits, rewards, redeemed, } = this.props.userDetails ? this.props.userDetails : {};
        const textColor = currentCardDetails && currentCardDetails.textColor || '#FFF';

        const cardIcon = icons && icons.loyaltyCardIcon;

        return (
            <div className={`${styles['goldTierCard']} current-card`} style={{
                width: '100%',
                backgroundColor: currentCardDetails && currentCardDetails.color || '#000', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: `url(${currentCardDetails && currentCardDetails.backgroundImage})`
            }}>
                {showLogoOnCard ? <img src={logo} alt="logo" className={styles['fr-card-logo']} /> : <img src={getImage('card_dots.svg')} alt="logo" className={`${styles['fr-card-dots']} current-card-dots`} />}
                <div className={`${styles['goldCardHead']}`} style={{ color: textColor }}>{currentCardDetails && currentCardDetails.name} CARD</div>
                <div className={`${styles['rewardPoints2']}`}>
                    <div className={`${styles['fav-rewardPoints']}`} style={{ display: "flex", flexDirection: "column" }} >
                        <span className={`${styles['fr-open-sans-semibold']} fr-points-label`} style={{ color: textColor, alignSelf: "center", fontSize: 33 }}>{userPoints} </span>
                        <span style={{ color: textColor, alignSelf: "center" }}>{pointsLabel || 'Points'}</span>
                    </div>
                </div>
                {loyaltyCardLabels && loyaltyCardLabels.length > 0 ?
                    <div className={`${styles['goldCardFooter2']} current-card-footer`}>
                        {loyaltyCardLabels && loyaltyCardLabels.map((data) => {

                            if (data.slug == "visits") {
                                return <div className={styles['fr-card-status-div']}>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-score']} fr-card-status-head`}>{visits}</span>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-text']} fr-card-status-info`}>{data.title}</span>
                                </div>
                            }
                            else if (data.slug == "spending") {
                                return <div className={styles['fr-card-status-div']}>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-score']} fr-card-status-head`}><sup>{currency}</sup>{spending}</span>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-text']} fr-card-status-info`}>{data.title}</span>
                                </div>
                            }
                        })
                        }
                    </div>
                    :
                    <div className={`${styles['goldCardFooter2']} current-card-footer`}>

                        <div className={styles['fr-card-status-div']}>
                            <span style={{ color: textColor }} className={`${styles['fr-card-status-score']} fr-card-status-head`}>{visits}</span>
                            <span style={{ color: textColor }} className={`${styles['fr-card-status-text']} fr-card-status-info`}>Visits</span>
                        </div>
                        <div className={styles['fr-card-div']} > </div>
                        <div className={styles['fr-card-status-div']}>
                            <span style={{ color: textColor }} className={`${styles['fr-card-status-score']} fr-card-status-head`}><sup>{currency}</sup>{spending}</span>
                            <span style={{ color: textColor }} className={`${styles['fr-card-status-text']} fr-card-status-info`}>SPENT</span>
                        </div>
                        {rewards ?
                            <div style={{ display: "contents" }}>
                                <div className={styles['fr-card-div']} > </div>
                                <div className={styles['fr-card-status-div']}>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-score']} fr-card-status-head`}>{rewards}</span>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-text']} fr-card-status-info`}>Rewards</span>
                                </div>
                            </div>
                            : null
                        }
                        {redeemed ?
                            <div style={{ display: "contents" }}>
                                <div className={styles['fr-card-div']} > </div>
                                <div className={styles['fr-card-status-div']}>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-score']} fr-card-status-head`}>{redeemed}</span>
                                    <span style={{ color: textColor }} className={`${styles['fr-card-status-text']} fr-card-status-info`}>Redeemed</span>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                }
            </div>

        )
    }
}
