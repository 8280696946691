import React, { useState } from 'react'
import Modal from 'react-modal';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from '../../styles/gifting.css';
import getImage from '../../images'
import { pushPath } from '../../reducers/navigation';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '5px',
    // height: '90vh',
    alignItems: 'center',
    overflow: 'hidden'
  },
  overlay: {
    background: '#000000e0'
  }
};
function HistoryButton(props) {
  const dispatch = useDispatch()
  const navigation = useSelector(state => state.navigation)
  const session = useSelector(state => state.session)

  const [isModalOpen, setisModalOpen] = useState(false)

  const { authToken, loginUrl } = session

  const handleClick = () => {
    if (!!authToken) {
      dispatch(pushPath('/order-history', navigation.params))
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
    else {
      setisModalOpen(true)
    }
  }

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setisModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <img src={getImage('close.svg')} alt="Img" className={styles['fr-close-btn']} onClick={() => setisModalOpen(false)} />
        </div>
        <div style={{ padding: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
            <span className={styles['fr-modal-title']}>You are not logged in. </span>
            <span className={styles['fr-modal-sub-title']}>Please login to check order history.</span>
          </div>
          {
            !!loginUrl &&
            <div style={{
              maxWidth: 60,
              backgroundColor: '#F4F4F4', padding: 10, borderRadius: 6,
              font: 'normal normal 600 12px/17px Open Sans',
              letterSpacing: 1.8,

              opacity: 1, cursor: 'pointer'
            }} >
              <a style={{ texTransform: 'uppercase', textDecoration: 'none', color: '#131313', }} href={!!loginUrl && loginUrl}>SIGN IN</a>
            </div>
          }
        </div>



      </Modal>
      <div
        onClick={() => handleClick()}
        style={{
          display: 'flex', alignItems: 'center',
          maxWidth: 95,
          backgroundColor: '#F4F4F4', padding: 10, borderRadius: 6,
          margin: '5px 40px 5px 40px',
          font: 'normal normal 600 12px/17px Open Sans',
          letterSpacing: 1.8,
          color: '#131313',
          texTransform: 'uppercase',
          opacity: 1, cursor: 'pointer'
        }}>
        <img src={getImage('history_icon.svg')} alt="Img" />&nbsp;HISTORY
      </div>
    </div>
  )
}

export default HistoryButton

