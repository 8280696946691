import React from "react";
import { connect } from "react-redux";
import styles from '../styles/loyalty.css';


const OverAllProgressCard = (props) => {

    const { tierProgress, loyalty, programList } = props;
    const { userDetails, loyaltyCardId } = loyalty;
    const currentProgram = programList && programList.find((i) => {
        return i.cards.find((j) => j.id === loyaltyCardId);
    });
    const cards = currentProgram && currentProgram.cards || [];
    const bgColor = tierProgress && tierProgress.backgroundColor;
    const color = tierProgress && tierProgress.textColor;
    const progressBarColor = tierProgress && tierProgress.progressBarColor;
    const enable = tierProgress && tierProgress.enable;
    const persentSpent = userDetails && ((userDetails.currentYearSpending) / (cards && Math.round(cards[cards.length - 1].upgradeConditions[0]?.spend))) * 100
    let nextCard = undefined
    let difference = ''

    const upgradeCondition = []
    cards && cards.map((item) => {
        if (item.upgradeConditions && item.upgradeConditions.length > 0) {
            upgradeCondition.push({ Name: item.name, amount: item.upgradeConditions[0].spend })
        }
        else {
            upgradeCondition.push({ Name: item.name, amount: 0 })
        }
    })
    upgradeCondition.map((item) => {
        if (parseInt(userDetails.currentYearSpending) < parseInt(item.amount)) {
            if (difference == '') {
                difference = item.amount - userDetails.currentYearSpending
                nextCard = item
            }
        }
    })

    if (enable != '1') {
        return (
            <div className={`${styles['tierProgress']} overallTierProgress`} style={{ background: bgColor || '#131313' }}>
                <p className={styles['mb-0']} style={{ color: color, fontSize: '12px' }}>Overall tier progress current financial year</p>
                <div className={`${styles['progress']} tier-progress-bar`} style={{ backgroundColor: progressBarColor }}>
                    {cards && cards.map((card) => {
                        if (card.upgradeConditions && card.upgradeConditions.length == 0) {
                            return <div> <div className={`${styles['progressDots']} start-dot`} style={{ left: 0, background: `${card.color}` }}></div>
                                <span style={{ left: 0 }} className={`${styles['hover_element']}`}>₹0</span></div>
                        } else {
                            const position = ((card.upgradeConditions && card.upgradeConditions[0].spend) / (cards && Math.round(card.upgradeConditions && cards[cards.length - 1].upgradeConditions[0].spend))) * 100

                            if (position == 100) {
                                return <div><div className={`${styles['progressDots']} end-dot`} style={{ right: 0, background: `${card.color}` }}></div>
                                    <span style={{ right: 0 }} className={`${styles['hover_element']}`}>₹{Math.floor(card.upgradeConditions && card.upgradeConditions[0].spend).toLocaleString("en-IN")}</span></div>
                            } else {
                                return <div><div className={`${styles['progressDots']} `} style={{ left: `${position}%`, background: `${card.color}` }}></div>
                                    <span style={{ left: `${position - 4}%`, }} className={`${styles['hover_element']}`}>₹{Math.floor(card.upgradeConditions && card.upgradeConditions[0].spend).toLocaleString("en-IN")}</span>
                                </div>
                            }

                        }
                    })}
                    <div>
                        <div className={`${styles['progressDots']} middle-dot`} style={{ left: (persentSpent > 96 ? 96 + '%' : persentSpent + '%'), top: '-23px', width: '0', height: '0', borderLeft: '11px solid transparent', borderRight: '11px solid transparent', borderTop: '16px solid #c70000' }}>
                        </div>
                        <span style={{ left: (persentSpent > 96 ? 102 + '%' : persentSpent + 4 + '%'), }} className={`${styles['hover_element']}`}>₹{Math.floor(userDetails.currentYearSpending).toLocaleString("en-IN")}</span>
                    </div>
                </div>
                <div className={styles['progressSection']} style={{ paddingBottom: '20px', position: 'relative' }}>
                    {cards && cards.map((card) => {
                        if (card.upgradeConditions && card.upgradeConditions.length == 0) {
                            return <div className={styles['proCategory']}>
                                <span style={{ color: color }}>{card.name}</span>
                            </div>
                        } else {
                            const position = ((card.upgradeConditions && card.upgradeConditions[0].spend) / (cards && Math.round(cards[cards.length - 1].upgradeConditions[0] && cards[cards.length - 1].upgradeConditions[0].spend))) * 100
                            if (position == 100) {
                                return <div className={styles['proCategory']} >
                                    <span style={{ color: color }}>{card.name}</span>
                                </div>
                            } else {
                                return <div className={styles['proCategory']} style={{ position: 'absolute', left: `${position - 2}%` }}>
                                    <span style={{ color: color }}>{card.name}</span>
                                </div>
                            }
                        }
                    })}
                </div>
                <div style={{ paddingTop: '5px', paddingBottom: '10px' }}>
                    {difference == '' ?
                        <small style={{ fontSize: 12 }}>You will be upgraded to Diamond</small>
                        : <small style={{ fontSize: 12 }}>Purchase ₹{Intl.NumberFormat('en-In').format(difference)} more to achieve {nextCard.Name} </small>
                    }
                </div>
            </div>
        )
    }
    else {
        return null
    }

}


const mapState = (state, ownprops) => {
    return {
        loyalty: state.loyalty,
        tierProgress: state.theme && state.theme.overallTierProgress,
        programList: state.loyaltyprogram.programList,
    };
}

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(OverAllProgressCard);