import React from 'react'
import styles from './commonStyles.css';

const ProceedButton = props => {
  return (
    <div>
      <button
        onClick={props.onclick}
        className={styles['gft-proceed-btn']}>
        Proceed to Payment
      </button>
    </div>
  )
}

ProceedButton.propTypes = {

}

export default ProceedButton
