import React, { useEffect, Component, useState } from 'react';
import CustomDatePickerTemp2 from '../common/DatePickerTemp2';
import styles from '../styles/loyalty.css';

export default class SignUpPageTemp2 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            profileImage: '',
            dob: null,
            passYear: null,
            termsCheck: false,
            signUpData: {

            }
        }
    }

    render() {

    }
}


const TextFieldTemp2 = ({ item, type, onChange, value, readOnly }) => {


    return (
        <div className={styles["temp1-custom-field"]}>
            <div className={styles["temp1-custome-field-inner"]}>
                <span className={styles["temp1-temp1-custom-field-label"]}>
                    {item.label}{item.required ? <span style={{ color: 'red' }}>*</span> : null}
                </span>
                <input className={styles["temp2-custom-field-input"]} placeholder={item.placeholder || `Please enter ${item.label}`} disabled={readOnly} onChange={onChange} value={value} />
            </div>
        </div>
    )
}



const TextField2Temp2 = ({ item, type, onChange, value, readOnly }) => {

    return (
        <div className={styles["temp1-custom-field"]}>
            <div className={styles["temp1-custome-field-inner"]}>
                <span className={styles["temp1-temp1-custom-field-label"]}>
                    {item.label} {item.required ? <span style={{ color: 'red' }}>*</span> : null}
                </span>
                <input className={styles["temp2-custom-field-input"]} placeholder={item.placeholder || `Please enter ${item.label}`} disabled={readOnly} onChange={onChange} value={value} />
            </div>
        </div>
    )
}
const SelectFieldTemp2 = ({ item, onChange }) => {
    return (
        <div className={styles["temp1-custom-field"]}>
            <div className={styles["temp1-custome-field-inner"]}>
                <span className={styles["temp1-temp1-custom-field-label"]}>
                    {item.label} {item.required ? <span style={{ color: 'red' }}>*</span> : null}
                </span>
                <select className={styles["temp2-custom-field-input"]} onChange={onChange} id="select">
                    <option value="" > {item.placeholder || `Please select ${item.label}`}</option>
                    {item.options.map((i, index) => {
                        return <option value={i.slug} >{i.label}</option>
                    })}
                </select>
            </div>
        </div>
    )
}



export class Template2RenderField extends Component {
    render() {
        const { item, onChange, value, readOnly } = this.props
        const fields = {
            "text": <TextFieldTemp2 item={item} type="name" value={value} onChange={(e) => onChange(e.target.value, item.slug)} />,
            "email": <TextField2Temp2 item={item} type="email" value={value} onChange={(e) => onChange(e.target.value, item.slug)} />,
            "date": <CustomDatePickerTemp2 item={item} value={value} onChange={(date) => onChange(date, item.slug)} />,
            "select": <SelectFieldTemp2 item={item} value={value} onChange={(e) => onChange(e.target.value, item.slug)} />,

        };

        return (
            fields[item.type] || null
        )
    }
}


