import React, { Component } from 'react';
import { connect } from 'react-redux';
import SignUpPage from '../components/SignUpPage';
import { pushPath } from '../reducers/navigation/navigation';
import { openSignUpModal, closeSignUpModal, LoyaltySignUp } from '../reducers/signup/signup'

class SignUp extends Component {

    render() {
        const { termsUrl } = this.props.redirectUrls || {};
        return (
            <SignUpPage
                isModalOpen={this.props.modalOpen}
                closeModal={this.props.closeSignUpModal}
                openModal={this.props.openSignUpModal}
                pushPath={this.props.pushPath}
                LoyaltySignUp={this.props.LoyaltySignUp}
                attributes={this.props.attributes}
                loading={this.props.loading}
                status={this.props.status}
                termsUrl={termsUrl}
            />
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        modalOpen: state.signup.isSignUpModalOpen,
        attributes: state.signup.signUpAttributes,
        loading: state.signup.loading,
        status: state.signup.status,
        redirectUrls: state.theme.redirectUrls || {},
    };
}

const mapDispatch = {
    pushPath,
    openSignUpModal,
    closeSignUpModal,
    LoyaltySignUp,
}

export default connect(mapState, mapDispatch)(SignUp);