import React from "react";
import { connect } from "react-redux";
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { pushPath } from '../reducers/navigation/navigation'

const TierProgressCard = (props) => {

    const { pushPath, tierProgress, loyalty, programList } = props;

    const { loyaltyCardId, nextCardStatus, cards } = loyalty || {};

    const currentCardDetails = cards.find((i) => {
        return i.id === loyaltyCardId;
    });

    const currentProgram = programList && programList.find((i) => {
        return i.cards.find((j) => j.id === loyaltyCardId);
    });

    const nextCardDetails = currentCardDetails && cards.find((i) => {
        return i.id == currentCardDetails.nextCardId;
    });

    const bgColor = tierProgress && tierProgress.backgroundColor;
    const color = tierProgress && tierProgress.textColor;
    const progressBarColor = tierProgress && tierProgress.progressBarColor;
    const progressDotColor = tierProgress && tierProgress.progressDotColor;

    return (
        tierProgress?.disable == '0' || tierProgress?.disable == false ?
            <div className={`${styles['tierProgress']} tier-progress`} style={{ background: bgColor || '#131313' }} onClick={() =>
                pushPath('/upgrade-card', { currentProgramId: currentProgram.id, currentCardId: currentCardDetails.id })
            }>
                <p className={styles['mb-0']} style={{ color: color }}>Tier progress</p>

                <div className={`${styles['progress']} tier-progress-bar`} style={{ backgroundColor: progressBarColor }}>
                    <div className={`${styles['progressDots']} start-dot`} style={{ left: 0, background: progressBarColor }}>
                    </div>
                    <div className={`${styles['progressDots']} middle-dot`} style={{ left: nextCardStatus && (nextCardStatus.percent + '%'), transform: 'scale(1.5)', background: progressDotColor }}>

                    </div>
                    <div className={`${styles['progressDots']} end-dot`} style={{ right: 0, background: progressBarColor }}>
                    </div>
                </div>
                <div className={styles['progressSection']}>
                    <div className={styles['proCategory']}>
                        <span style={{ color: color }}>{currentCardDetails && currentCardDetails.name}</span>
                    </div>
                    <div className={styles['proCategory']}>
                        <span style={{ color: color }}>{nextCardDetails && nextCardDetails.name || ""}</span>
                    </div>
                </div>
                <small style={{ fontSize: 12 }}>{nextCardStatus && nextCardStatus.text}</small>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className={styles['fr-link-black-btn']}
                        style={{ padding: '10px', color: color }}>
                        View Details<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowCircleRight} /></span>
                </div>
            </div>
            : null
    )
}

const mapState = (state, ownprops) => {
    return {
        tierProgress: state.theme && state.theme.tierProgress,
        loyalty: state.theme && state.loyalty,
    };
}

const mapDispatch = {
    pushPath
}

export default connect(mapState, mapDispatch)(TierProgressCard);