import React, { Component } from 'react'
import { connect } from 'react-redux';
import ReferralOverviewPage from '../components/ReferralOverviewPage'

class ReferralOverview extends Component {
    render() {
        const { referral } = this.props;
        const { userInfo, program, shareCodeContent, steps, basicConfig } = referral;

        return (
            <ReferralOverviewPage steps={steps} basicConfig={basicConfig} referralBenefits={this.props.referralBenefits} shareType={this.props.shareType} shareCodeContent={shareCodeContent} userInfo={userInfo} program={program} shareUrl={this.props.shareUrl} />
        )
    }
}

const mapState = (state, ownprops) => {
    const shareInfo = state.session.shareReferralInfo;

    return {
        referral: state.referral,
        shareType: shareInfo && shareInfo.type,
        shareUrl: shareInfo && shareInfo.baseUrl,
        referralBenefits: state.session.referralBenefits,
    };
}

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(ReferralOverview);