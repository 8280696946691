import React, { Component } from 'react'
import styles from '../styles/loyalty.css';

export default class GiftCardHistoryDesktopHeader extends Component {


    render() {

        return (

            <div style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div style={{ width: "100%", display: "flex", backgroundColor: "#000", justifyContent: "space-between", alignItems: "center", padding: 20, borderRadius: 5 }}>
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start" }}>
                        <span className={`${styles['head-brand-text']}`} >Brand</span>
                    </div>
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start", backgroundColor: "pick" }}>
                        <span className={`${styles['head-text']}`}>Date</span>
                    </div>
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start" }}>
                        <span className={`${styles['head-text']}`}>Expiry Date</span>
                    </div>
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start" }}>
                        <span className={`${styles['head-text']}`}>Points</span>
                    </div >
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start" }}>
                        <span className={`${styles['head-text']}`}>Claim Voucher</span>
                    </div >
                </div>
            </div>

        )
    }
}
