import React from 'react';

const ColumnLayout = (props) => {
    const { sectionList, components, componentsMap } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
            {sectionList?.map((i, index) => {
                return (
                    <RenderSection key={index} componentsMap={componentsMap} components={components.filter(item => item.section == i)} />
                )
            })}
        </div>
    )
}

const RenderSection = ({ components, componentsMap }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            {components?.map((item, index) => {
                return (
                    <div key={index} style={{ width: '100%', height: 'auto' }}>
                        {componentsMap(item)}
                    </div>
                )
            })}
        </div>
    )
}

export default ColumnLayout;