import apiClient from '../api';
import { setLoading } from './loader';
import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input';

const initialState = {
  horizontalStyle: null,
  pgResponse: null,
};
const THEME_ERROR = 'giftingprogram/THEME_ERROR';
const SET_THEME = 'giftingprogram/SET_THEME';
const CATEGORY_THEMES_LIST = 'giftingprogram/CATEGORY_THEMES_LIST';
const PLACE_ORDER = 'giftingprogram/PLACE_ORDER';
const CARD_BUY_TRANSACTION_DETAILS = 'giftingprogram/CARD_BUY_TRANSACTION_DETAILS';

const formatPhone = (param) => {
  const countryCode = param && formatPhoneNumberIntl(param).split(' ')[0];
  const formattedPhone = param && formatPhoneNumber(param).slice(1).split(' ').join('');
  const args = {
    mobileNumber: formattedPhone,
    countryCode,
  };
  return args;
}

export const placeOrderAction = (callback) => async (dispatch, getState) => {
  const state = getState();
  const { authToken, merchantAccessToken, baseUrl } = state.session;
  const {
    brandId, categoryId, themeId, amount, type, message,
    username, email, customerPhone,
    receiverName, receiverPhoneNumber, receiverEmail
  } = state.gifting.giftDetails;

  let senderPhoneCode = formatPhone(customerPhone)
  let receiverPhoneCode = formatPhone(receiverPhoneNumber)

  let args = {
    cards: [
      {
        themeId: themeId,
        cardValue: amount,
        quantity: 1
      }
    ],

    customerName: username,
    customerEmail: email,
    customerPhoneCountryCode: senderPhoneCode.countryCode,
    customerPhone: senderPhoneCode.mobileNumber,

    receiverName: receiverName,
    receiverEmail: receiverEmail,
    receiverPhoneCountryCode: receiverPhoneCode.countryCode,
    receiverPhone: receiverPhoneCode.mobileNumber,

    authToken: authToken,
    message: message,
    merchantAccessToken: merchantAccessToken,
    orderType: type,
    channel: "web",
    source: window.location.origin,
    returnUrl: window.location.href,
    // returnUrl: window.location.origin + '/',

  }

  try {
    dispatch(setLoading(true))
    const response = await apiClient.post(apiClient.Urls.placeOrder(baseUrl), args);
    // const response = await apiClient.post(apiClient.Urls.placeOrder('https://beta.froogal.in/dedicated-api/'), args);
    console.log({ response })
    if (response.success) {
      dispatch({
        type: PLACE_ORDER,
        payload: response
      });
      dispatch(setLoading(false))
    } else {
      callback(response)
      dispatch(setLoading(false))
    }

  } catch (e) {
    dispatch(setLoading(false))
  }
};


export const transactionStatusAction = (param) => async (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: CARD_BUY_TRANSACTION_DETAILS,
    payload: param
  });
}


const placeOrderReducer = (state = initialState, action) => {
  switch (action.type) {


    case CATEGORY_THEMES_LIST: {
      return {
        ...state,
        themesList: action.payload
      }
    }
    case PLACE_ORDER: {
      return {
        ...state,
        pgResponse: {
          url: action.payload.url || action.payload.redirectUrl,
          method: action.payload.method || 'GET',
          payload: action.payload.payload,
        }
      }
    }
    case CARD_BUY_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionStatus: action.payload
      }
    }

    case SET_THEME: {
      return {
        ...state,
        horizontalStyle: action.horizontalStyle
      }
    }
    default: return state;
  }
}
export default placeOrderReducer;