import apiClient from '../../api';
import { setAuthToken } from '../session/session';
import { getUserDetails } from '../loyalty/loyalty';

const initialState = {
    isSignUpModalOpen: false,
    signUpProgramId: null,
    signUpAttributes: null,
    loading: false,
    selectedCard: null,
    status: "",
    isOtpSent: false,
};

const OPEN_SIGN_UP_MODAL = "signup/OPEN_SIGN_UP_MODAL";
const CLOSE_SIGN_UP_MODAL = "signup/CLOSE_SIGN_UP_MODAL";
const GET_SIGNUP_ATTRIBUTES_START = 'loyaltyprogram/GET_SIGNUP_ATTRIBUTES_START';
const GET_SIGNUP_ATTRIBUTES_SUCCESS = 'loyaltyprogram/GET_SIGNUP_ATTRIBUTES_SUCCESS';
const GET_SIGNUP_ATTRIBUTES_FAILED = 'loyaltyprogram/GET_SIGNUP_ATTRIBUTES_FAILED';
const GET_SIGNUP_SUCCESS = 'loyaltyprogram/GET_SIGNUP_SUCCESS';
const GET_SIGNUP_FAILED = 'loyaltyprogram/GET_SIGNUP_FAILED';
const GET_LOGIN_OTP = 'loyaltyprogram/GET_LOGIN_OTP';


export const openSignUpModal = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: OPEN_SIGN_UP_MODAL,
        });
    }
    catch (e) {

    }
}

export const closeSignUpModal = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: CLOSE_SIGN_UP_MODAL,
        });
    }
    catch (e) {

    }
}

export const getEnrollmentCriteria = (loyaltyCardId) => async (dispatch, getState) => {
    const state = getState();
    const { merchantAccessToken, authToken, loyaltyId } = state.session;

    dispatch({
        type: GET_SIGNUP_ATTRIBUTES_START,
    });
    try {

        const response = await apiClient.get(apiClient.Urls.enrollmentCriteria(loyaltyId, loyaltyCardId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_SIGNUP_ATTRIBUTES_SUCCESS,
                attributes: response.attributes,
                selectedCard: response.cardId,
                offerText: response.offerText,
            });
        } else {
            dispatch({
                type: GET_SIGNUP_ATTRIBUTES_FAILED,
            });
        }
    } catch (e) {
        dispatch({
            type: GET_SIGNUP_ATTRIBUTES_FAILED,
        });
    }

}




export const getLoginOtp = (mobileNumber) => async (dispatch, getState) => {

    const state = getState();
    const { merchantAccessToken, isMultiCountryEnable } = state.session;

    try {
        var response = null;
        if (isMultiCountryEnable) {
            response = await apiClient.get(apiClient.Urls.requestOtp, {
                loginAsGuest: true,
                merchantAccessToken,
                mobileNumber: mobileNumber,
                countryCode: '91'
            });
        } else {
            response = await apiClient.get(apiClient.Urls.requestOtp, {
                loginAsGuest: true,
                merchantAccessToken,
                mobileNumber,
            });

        }

        if (response.success) {

            dispatch({
                type: GET_LOGIN_OTP,
                isOtpSent: true
            });
        } else {

        }
    } catch (e) {

    }

}




export const verifyOtp = (mobileNumber, otp) => async (dispatch, getState) => {
    const state = getState();
    const { merchantAccessToken, postLoginCallback, multiCountryInfo } = state.session;

    try {
        const response = await apiClient.get(apiClient.Urls.validateOtp, {
            merchantAccessToken,
            mobileNumber,
            otp,
        });

        if (response.success) {
            dispatch(setAuthToken(response.authToken))
            if (typeof postLoginCallback === 'function') {
                postLoginCallback({
                    authToken: response.authToken,
                    phoneNumber: mobileNumber,
                });
            }
        } else {
            dispatch({
                type: GET_SIGNUP_FAILED,
                message: response.message
            });
        }
    } catch (e) {

    }

}

export const shopifySignUp = () => async (dispatch, getState) => {
    const state = getState();
    const { merchantAccessToken, authToken, shopifyCustomerId } = state.session;

    try {
        if (shopifyCustomerId) {
            const response = await apiClient.get(apiClient.Urls.shopifySignUp, {
                merchantAccessToken,
                authToken,
                shopifyCustomerId
            });

            if (response.success) {
                console.log("shopify registration is done.");
            }
            else {
                console.log("shopify registration failed", response);
            }
        }

    } catch (e) {

    }

}

export const LoyaltySignUp = (formData) => async (dispatch, getState) => {

    const state = getState();
    const { selectedCard } = state.signup;

    const { merchantAccessToken, authToken } = state.session;

    const { loyaltyId } = state.loyalty;

    const loyaltyCardId = selectedCard;

    try {
        const response = await apiClient.post(apiClient.Urls.loyaltySignUp(loyaltyId, loyaltyCardId), {
            authToken,
            merchantAccessToken,
            data: formData,
            cardId: loyaltyCardId,
        });

        if (response.success) {

            dispatch({
                type: GET_SIGNUP_SUCCESS,
                status: response
            });
        } else {
            dispatch({
                type: GET_SIGNUP_FAILED,
                message: response.message
            });
        }
    } catch (e) {
        dispatch({
            type: GET_SIGNUP_FAILED,
            message: e.message
        });
    }
}

const signUpReducer = (state = initialState, action) => {
    switch (action.type) {

        case OPEN_SIGN_UP_MODAL: {
            return {
                ...state,
                isSignUpModalOpen: true,
            }
        }
        case GET_LOGIN_OTP: {
            return {
                ...state,
                isOtpSent: action.isOtpSent,
            }
        }
        case CLOSE_SIGN_UP_MODAL: {
            return {
                ...state,
                isSignUpModalOpen: false,
            }
        }
        case GET_SIGNUP_ATTRIBUTES_SUCCESS: {
            return {
                ...state,
                loading: false,
                signUpAttributes: action.attributes,
                selectedCard: action.selectedCard,
                offerText: action.offerText,
            }
        }
        case GET_SIGNUP_SUCCESS: {
            return {
                ...state,
                status: action.status
            }
        }
        case GET_SIGNUP_FAILED: {
            return {
                ...state,
                status: { success: false, message: action.message || "Please check the data that you have entered." }
            }
        }
        case GET_SIGNUP_ATTRIBUTES_START: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_SIGNUP_ATTRIBUTES_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }

        case GET_SIGNUP_ATTRIBUTES_SUCCESS: {
            return {
                ...state,
                isSignUpModalOpen: false,
            }
        }


        default: return state;
    }

}


export default signUpReducer;
