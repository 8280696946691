import React, { Component } from 'react';
import ProgramList from "./containers/ProgramList";
import UpgradeCard from "./containers/UpgradeCard";
import LoyaltyMain from "./containers/LoyaltyMain";
import Benefit from "./containers/Benefit";
import PointsHistory from "./containers/PointsHistory";
import { initialize } from "./reducers/session/session";
import { connect } from "react-redux";
import Loader from './common/Loader';
import LoyaltyIntro from './common/LoyaltyIntro';
import styles from '../src/styles/loyalty.css';
import AllBenefits from './containers/AllBenefits';
import VoucherCart from './containers/VoucherCart';

class Loyalty extends Component {


  constructor(props) {
    super(props)
    this.props.initialize(this.props.merchantAccessToken, this.props.authToken ? this.props.authToken : this.props.sessionAuthToken, this.props.loyaltyId, this.props.shopifyCustomerId, { knowMoreUrl: this.props.knowMoreUrl, horizontalStyle: this.props.horizontalStyle, termsUrl: this.props.termsUrl });
  }

  render() {
    const { navigation, authToken, merchantAccessToken, sessionAuthToken } = this.props;
    const { path } = navigation;

    const routes = {
      "/": Loader,
      "/programs": ProgramList,
      "/upgrade-card": UpgradeCard,
      "/loyalty": LoyaltyMain,
      "/freebie-details": Benefit,
      "/points-history": PointsHistory,
      "/all-benefits": AllBenefits,
      "/voucher-cart": VoucherCart,
    };

    const ComponentToRender = routes[path] || null;

    return (
      ComponentToRender
        ?
        <div className={styles['fr-main-loyalty-div']}>
          <ComponentToRender authToken={authToken || sessionAuthToken} merchantAccessToken={merchantAccessToken} termsUrl={this.props.termsUrl} tabStyle={this.props.tabStyle} />
          <LoyaltyIntro />
        </div>

        : null
    );
  }
}

const mapState = (state, ownprops) => {
  return {
    navigation: state.navigation,
    sessionAuthToken: state.session.authToken,
  };
}

const mapDispatch = {
  initialize,
}

export default connect(mapState, mapDispatch)(Loyalty);
