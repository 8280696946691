const initialState = {
    path: '/',
    params: null,
};

const SET_PATH = "navigation/SET_PATH";

export const pushPath = (path, params) => async (dispatch, getState) => {
    dispatch({
        type: SET_PATH,
        path,
        params
    })
};

const navigationReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PATH: {
            return {
                ...state,
                path: action.path,
                params: action.params,
            }
        }
        default: return state;
    }
}

export default navigationReducer;