import React from "react";
import { connect } from "react-redux";
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

const KnowMoreCard = (props) => {

    const { loyaltyKnowMore } = props;


    const image = loyaltyKnowMore && loyaltyKnowMore.icon;
    const title = loyaltyKnowMore && loyaltyKnowMore.heading;
    const description = loyaltyKnowMore && loyaltyKnowMore.description;
    const link = loyaltyKnowMore && loyaltyKnowMore.redirectUrl;
    const textColor = loyaltyKnowMore && loyaltyKnowMore.textColor;
    const backgroundColor = loyaltyKnowMore && loyaltyKnowMore.backgroundColor;
    const disable = loyaltyKnowMore && loyaltyKnowMore.disable;

    if ( disable == '1' ) {
        return null
    }

    return (
        <div className={styles['fr-know-more-loyalty-div']} style={{ background: backgroundColor }}>
            <img style={{ height: 90, width: 'auto' }} src={image} alt="Img" />
            <div className={styles['fr-know-more-sub-div']} style={{ paddingLeft: '10px' }}>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                    <span className={styles['know-more-head']} style={{ color: textColor, marginBottom: 5 }}>{title || ''}</span>
                    <span className={styles['know-more-sub']} style={{ color: textColor }}>{description || ''}</span>
                </div>
                <a href={link} className={styles['fr-link-black-btn']} style={{ color: textColor }}>
                    Know More <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowCircleRight} />
                </a>
            </div>
        </div>
    )
}


const mapState = (state, ownprops) => {
    return {
        loyaltyKnowMore: state.theme && state.theme.loyaltyKnowMore,
    };
}

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(KnowMoreCard);