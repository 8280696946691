import React from 'react'
import PropTypes from 'prop-types'
import styles from './commonStyles.css'
import getImage from '../../images'

const BackButton = (props) => {
  return (
    <button
      className={`${styles['gft-nagivation-back-btn']}`}
      onClick={() => props.onclick()}
      style={{ display: 'flex', alignItems: 'center', padding: 10 }}
    >
      <img src={getImage('back_arrow.svg')} />
      <text>{props.buttonText}</text>
    </button>
  )
}
BackButton.propTypes = {
  buttonText: PropTypes.string,
  onclick: PropTypes.func
}
export default BackButton
