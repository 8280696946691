import React, { Component } from 'react';
import BenefitsList from './BenefitsList';
import { initializeBenefits } from "../reducers/session/session";
import { connect } from "react-redux";
import Benefit from './Benefit';
import styles from '../styles/loyalty.css';
import RegForm from './RegForm';

class MyBenefits extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentTab: 'active'
        }
        this.props.initializeBenefits(this.props.merchantAccessToken, this.props.authToken, this.props.loyaltyId);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const onTabClick = (value) => {
            this.setState({ currentTab: value })
        }

        const { currentTab } = this.state;

        const { navigation, customCss, userDetails } = this.props;
        const { path } = navigation;

        if (userDetails && userDetails.isEnrolled) {
            return (
                <div>
                    <style>{customCss}</style>
                    {path == "/freebie-details" ?
                        <Benefit /> :
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ marginBottom: 30 }}>

                                    <span className={currentTab === "active" ? `${styles['fr-loyalty-tab']} ${styles['fr-loyalty-tab-active']}` : styles['fr-loyalty-tab']} onClick={() => onTabClick('active')}>
                                        Benefits
                                    </span>
                                    <span
                                        className={currentTab === "expired" ? `${styles['fr-loyalty-tab']} ${styles['fr-loyalty-tab-active']}` : styles['fr-loyalty-tab']} onClick={() => onTabClick('expired')}>
                                        History
                                    </span>
                                </div>

                            </div>
                            {currentTab === "active" ? <BenefitsList type="active" /> : null}
                            {currentTab === "expired" ? <BenefitsList type="history / expired" /> : null}
                        </div>
                    }
                </div>
            )
        }
        else {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <style>{customCss}</style>
                    <RegForm
                        authToken={this.props.authToken}
                        merchantAccessToken={this.props.merchantAccessToken}
                        loyaltyId={this.props.loyaltyId}
                        isMultiCountryEnable={this.props.isMultiCountryEnable}
                        defaultCountry={this.props.defaultCountry}
                        preFillData={this.props.preFillData}
                        {...this.props}
                    />
                </div>
            )
        }
    }
}

const mapState = (state, ownprops) => {
    return {
        navigation: state.navigation,
        customCss: state.theme && state.theme.customCss,
        userDetails: state.loyalty.userDetails,
    };
}

export default connect(mapState, { initializeBenefits })(MyBenefits);