import React, { Component } from 'react'
import { connect } from 'react-redux';
import CurrentCardPage from '../components/CurrentCardPage';
import CurrentCardPage2 from '../components/CurrentCardPage2';
import { getloyaltyCardLabels } from '../reducers/theme/theme';

class CurrentCard extends Component {

    componentDidMount() {
        this.props.getloyaltyCardLabels();
    }

    render() {
        const { userDetails, userPoints, loading, loyaltyCardId, cards, pointsLabel, showLogoOnCard, logo, currency, configuration } = this.props.loyalty;
        const currentCardDetails = cards.find((i) => {
            return i.id === loyaltyCardId;
        });
        return (
            <div style={{ width: "100%", display: "flex" }}>
                {configuration?.loyaltyCardTemplate == "template1" ?
                    < CurrentCardPage currentCardDetails={currentCardDetails} loyaltyCardLabels={this.props.loyaltyCardLabels} spointsLabel={pointsLabel} currency={currency} icons={this.props.icons}
                        userPoints={userPoints} configuration={configuration} textColor={'#fff'} userDetails={userDetails} isFrom={this.props.from} showLogoOnCard={showLogoOnCard} logo={logo} />
                    : configuration?.loyaltyCardTemplate == "template2" ?
                        < CurrentCardPage2 currentCardDetails={currentCardDetails} loyaltyCardLabels={this.props.loyaltyCardLabels} spointsLabel={pointsLabel} currency={currency} icons={this.props.icons}
                            userPoints={userPoints} configuration={configuration} textColor={'#fff'} userDetails={userDetails} isFrom={this.props.from} showLogoOnCard={showLogoOnCard} logo={logo} />
                        : < CurrentCardPage currentCardDetails={currentCardDetails} loyaltyCardLabels={this.props.loyaltyCardLabels} spointsLabel={pointsLabel} currency={currency} icons={this.props.icons}
                            userPoints={userPoints} configuration={configuration} textColor={'#fff'} userDetails={userDetails} isFrom={this.props.from} showLogoOnCard={showLogoOnCard} logo={logo} />
                }
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    const { icons, loyaltyCardLabels } = state.theme;
    return {
        loyalty: state.loyalty,
        icons: icons,
        loyaltyCardLabels,
    };
}

const mapDispatch = {
    getloyaltyCardLabels,
}

export default connect(mapState, mapDispatch)(CurrentCard);
