import React, { Component } from 'react'
import Referral from './containers/Referral'
import ReactDom from 'react-dom';
import { Provider } from "react-redux";
import createStore from './store';
import { setBaseUrl as setImageBaseUrl } from "./images";
import packageJson from '../package.json';

import apiClient from './api';
import PointsHistory from './containers/PointsHistory';

const version = packageJson.version;


export class ReferralRoot extends Component {

  store = null;

  constructor(props) {
    super(props);

    this.store = createStore();

    if (props.imageBaseUrl) {
      setImageBaseUrl(props.imageBaseUrl);
    }

    if (props.apiBaseUrl) {
      apiClient.setBaseUrl(props.apiBaseUrl);
    }
  }


  render() {

    if (!this.props.authToken) {
      return <ErrorMessage errorCode="ERROR 1" />
    }
    if (!this.props.merchantAccessToken) {
      return <ErrorMessage errorCode="ERROR 2" />
    }

    return (
      <Provider store={this.store}>
        <Referral
          merchantAccessToken={this.props.merchantAccessToken}
          authToken={this.props.authToken}
          {...this.props}
        />
      </Provider>
    );
  }
}


export class ReferralHistory extends Component {
  store = null;
  constructor(props) {
    super(props);
    this.store = createStore();
    if (props.imageBaseUrl) {
      setImageBaseUrl(props.imageBaseUrl);
    }
    if (props.apiBaseUrl) {
      apiClient.setBaseUrl(props.apiBaseUrl);
    }
  }

  render() {

    if (!this.props.authToken) {
      return <ErrorMessage errorCode="ERROR 1" />
    }
    if (!this.props.merchantAccessToken) {
      return <ErrorMessage errorCode="ERROR 2" />
    }

    return (
      <Provider store={this.store}>
        <PointsHistory
          isRoot={true}
          merchantAccessToken={this.props.merchantAccessToken}
          authToken={this.props.authToken}
          {...this.props}
        />
      </Provider>
    );
  }
}

class ErrorMessage extends Component {
  render() {
    return (
      <div style={{ display: 'flex', height: '100vh', padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <h1>Oops!</h1>
        <h4>Something went wrong, Please contact the administrator.</h4>
        <h5>{this.props.errorCode}</h5>
      </div>
    )
  }
}


export function initialize(element, props) {

  console.log('Referral SDK Version: -' + version + ' initialize');

  if (props.imageBaseUrl) {
    setImageBaseUrl(props.imageBaseUrl);
  }

  ReactDom.render(<ReferralRoot {...props} />, element)
}


