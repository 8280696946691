import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getLoyaltyConfig } from '../reducers/loyalty/loyalty';
import Heading from '../common/Heading';
import BenefitItem from './BenefitItem';
import { pushPath } from '../reducers/navigation/navigation'

class ExclusiveBenefitList extends Component {

    componentDidMount() {
        this.props.getLoyaltyConfig();
    }

    render() {
        const { pushPath, loyalty, isHorizontalList } = this.props;
        const { freebies, exclusiveFreebies, loyaltyCardId, cards } = loyalty;

        const currentCardDetails = cards.find((i) => {
            return i.id === loyaltyCardId;
        });

        return (
            <div>
                <Heading title={currentCardDetails && currentCardDetails.name} subTitle="Exclusive Benefits" button={freebies.length > 3 ? true : false} actionTitle={'View All'} action={() => { pushPath('/all-benefits', null) }} />
                <div style={{
                    display: isHorizontalList == true ? 'flex' : null,
                    overflowX: isHorizontalList == true ? 'auto' : null

                }}>
                    {
                        exclusiveFreebies.slice(0, 4).map((item, index) => {
                            return <BenefitItem item={item} key={index} isHorizontalList={isHorizontalList} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} type={'exclusive'} />
                        })
                    }
                </div>
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        loyalty: state.loyalty,
    };
}

const mapDispatch = {
    getLoyaltyConfig,
    pushPath
}

export default connect(mapState, mapDispatch)(ExclusiveBenefitList);
