import React, { Component } from 'react';
import BenefitsList from './BenefitsList';
import { initialize } from "../reducers/session/session";
import { connect } from "react-redux";
import Benefit from './Benefit';

class ExpiredBenefits extends Component {

    constructor(props) {
        super(props)
        this.props.initialize(this.props.merchantAccessToken, this.props.authToken, this.props.loyaltyId, this.props.knowMoreUrl, "benefits");
    }

    render() {
        const { navigation, customCss } = this.props;
        const { path } = navigation;
        return (
            <div>
                <style>{customCss}</style>
                {
                    path == "/freebie-details" ?
                        <Benefit /> :
                        <BenefitsList type="redeemed / expired" />
                }
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        navigation: state.navigation,
        customCss: state.theme && state.theme.customCss,
    };
}

export default connect(mapState, { initialize })(ExpiredBenefits);