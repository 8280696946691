import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setGiftDetails } from '../../reducers/gifting';
import styles from './commonStyles.css';
function SendOption(props) {
  const dispatch = useDispatch()
  const gifting = useSelector(state => state.gifting)

  const handleSelection = (param) => {
    dispatch(setGiftDetails({ type: param }))
  }

  return (
    <div className={styles['gft-tabs-wrap']} >
      <div className={styles['gft-tabs']} >
        <div style={{ position: 'relative', width: 160 }}>
          <text
            className={gifting.giftDetails.type === 'gift' ? `${styles['gft-loyalty-tab-active']}` : styles['gft-loyalty-tab']} onClick={() => handleSelection('gift')}>
            send gift card
          </text>
          {/* {gifting.giftDetails.type === 'gift'
            &&
            <img alt="Img" style={{ position: 'absolute', right: 0, height: 20 }} src={green_tick} />} */}
        </div>


        <div style={{ width: 2, backgroundColor: '#D9D9D9' }} />

        <div style={{ position: 'relative', width: 160 }}>
          <text className={gifting.giftDetails.type === 'self' ? `${styles['gft-loyalty-tab-active']}` : styles['gft-loyalty-tab']} onClick={() => handleSelection('self')}>
            buy for self
          </text>
          {/* {gifting.giftDetails.type === 'self'
            &&
            <img alt="Img" style={{ position: 'absolute', right: 0, height: 20 }} src={green_tick} />} */}

        </div>


      </div>
    </div>
  )
}

SendOption.propTypes = {

}

export default SendOption
