import React, { Component } from 'react'
import { connect } from 'react-redux';
import styles from '../styles/loyalty.css';

class EarningRateCard extends Component {
    render() {
        const { earningRateBox, cards, loyaltyCardId } = this.props;

        const currentCardDetails = cards.find((i) => {
            return i.id === loyaltyCardId;
        });

        const conversionRate = currentCardDetails && currentCardDetails.pointsRate || null;

        const bgColor = earningRateBox && earningRateBox.backgroundColor;
        const color = earningRateBox && earningRateBox.textColor;

        return (
            <div className={`${styles['earnRateCard']} fr-earn-rate-card`} style={{ background: bgColor || '#131313' }}>
                <p className={`${styles['mb-0']} ${styles['currentp']}`} style={{ color: color || '#FFF' }}>Earn</p>
                <div>
                    <span style={{ fontSize: 24 }}>{conversionRate}%</span>
                </div>
                <p style={{ color: color || '#FFF' }}> Points on every transaction</p>
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        earningRateBox: state.theme && state.theme.earningRateBox,
        cards: state.loyalty.cards,
        loyaltyCardId: state.loyalty.loyaltyCardId,
    };
}

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(EarningRateCard);
