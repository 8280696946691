import React, { Component } from 'react'
import Heading from '../common/Heading';
import styles from '../styles/loyalty.css';
import BackButton from '../common/BackButton';

export default class PointHistoryPage extends Component {
    render() {
        const { pushPath, pointsInfo, transactions, isMainComponent, themeColors } = this.props;

        return (
            <div>
                {!isMainComponent ?
                    <BackButton onclick={() => pushPath('/loyalty', null)} />

                    : null}
                <div>
                    <Heading title="Your" subTitle="Overview" />
                    <div className={styles['fr-history-div']} style={{ background: themeColors && themeColors.themeColor }}>
                        <div className={styles['fr-history-sub-div']}>
                            <span style={{ fontSize: 36 }}>{pointsInfo && pointsInfo.transactionsCount}</span>
                            <div className={styles['fr-history-inner-div']}>
                                <span>Total</span>
                                <span>transactions</span>
                            </div>
                        </div>
                        <div className={styles['fr-vr-pipe']}>

                        </div>
                        <div className={styles['fr-history-sub-div']}>
                            <span style={{ fontSize: 36 }}>{pointsInfo && pointsInfo.pointEarned}</span>
                            <div className={styles['fr-history-inner-div']}>
                                <span>Total</span>
                                <span>points earned</span>
                            </div>
                        </div>
                        <div className={styles['fr-vr-pipe']}>

                        </div>
                        <div className={styles['fr-history-sub-div']}>
                            <span style={{ fontSize: 36 }}>{pointsInfo && pointsInfo.pointBurned}</span>
                            <div className={styles['fr-history-inner-div']}>
                                <span>Total</span>
                                <span>points burned</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Heading title="All" subTitle="Transactions" />
                    <div className={styles['fr-history-list']}>
                        <table>
                            <tr>
                                <th>Type</th>
                                <th>Date</th>

                                <th>Points</th>
                                <th>Status</th>
                            </tr>
                            {
                                transactions.map((item, index) => {
                                    var type = item.type === "B" ? 'Benefit' : item.type === "R" ? "Referral" : "Loyalty";
                                    var classType = item.type === "B" ? 'fr-type-benefit' : item.type === "R" ? "fr-type-ref" : "fr-type-loyalty";
                                    var classPoints = item.type === "B" ? 'fr-red-text' : "fr-green-text";
                                    var symbol = item.type === "B" ? '-' : "+";
                                    return (
                                        <tr key={index}>
                                            <td><span className={styles[classType]}>{type}</span></td>
                                            <td className={`${styles['fr-text']} points-date`}>{item.date}</td>
                                            <td className={`${styles[classPoints]} points-text`}>{symbol}{item.points}</td>
                                            <td className={`${styles[classPoints]} points-status`}>{item.status}</td>
                                        </tr>
                                    )

                                })
                            }
                            {
                                transactions.length === 0 && (
                                    <tr>
                                        <td colSpan="5">
                                            No Data Found
                                        </td>
                                    </tr>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
