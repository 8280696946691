
import apiClient from '../../api';
import { getBaseConfig, getMilestones, getProfile, getProgram, getRedirectUrls, getSteps, getThemeColors, setShareContent,getCustomeCss } from '../referral/referral'

const initialState = {
    merchantAccessToken: null,
    authToken: null,
    userId: null,
    programId: null,
    shareUrl: null,
    shareReferralInfo: null,
    loyaltyId: null,
    referralBenefits: null,
    termsUrl: null,
};

const SET_TOKENS = 'referral/SET_TOKENS';
const SET_USER_ID = "referral/SET_USER_ID";
const GET_LOYALTY_ID = "referral/GET_LOYALTY_ID";



export const intialize = (merchantAccessToken, authToken) => async (dispatch, getState) => {
    await dispatch({
        type: SET_TOKENS,
        merchantAccessToken,
        authToken,
    });

    // await dispatch(getLoyaltyId());
    dispatch(getProfile());
    dispatch(getProgram());
    dispatch(getBaseConfig())
    dispatch(getRedirectUrls());
    dispatch(getThemeColors());
    dispatch(getSteps());
    dispatch(setShareContent());
    dispatch(getMilestones());
    dispatch(getCustomeCss());

}

export const getLoyaltyId = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.config, {
            merchantAccessToken,
        });

        if (response.success) {

            const loyalty = response.config && response.config.loyalty;
            const loyaltyId = loyalty && loyalty.defaultLoyaltyId;
            const referral = response.config && response.config.referral || {};
            const programId = referral && referral.defaultProgramId;

            await dispatch({
                type: GET_LOYALTY_ID,
                loyaltyId: loyaltyId,
                programId: programId,
            });
            // dispatch(getUserId(loyaltyId))

        } else {
        }
    } catch (error) {
    }
};


export const getUserId = (loyaltyId) => async (dispatch, getState) => {

    const state = getState();
    const { merchantAccessToken, authToken } = state.session;


    try {
        const response = await apiClient.post(apiClient.Urls.getUser(loyaltyId), {
            merchantAccessToken, authToken
        });


        if (response.success) {
            await dispatch({
                type: SET_USER_ID,
                userId: response.userId,
            });
        } else {
            // dispatch(displayError('', response.message));
        }
    } catch (e) {
        // dispatch(displayError('EXCEPTION', e.message));
    }

    dispatch(getProfile())

}

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKENS: {
            return {
                ...state,
                merchantAccessToken: action.merchantAccessToken,
                authToken: action.authToken,
            }
        }
        case SET_USER_ID: {
            return {
                ...state,
                userId: action.userId
            }
        }
        case GET_LOYALTY_ID: {
            return {
                ...state,
                loyaltyId: action.loyaltyId,
                programId: action.programId
            }
        }

        default: return state;
    }

}


export default sessionReducer;
