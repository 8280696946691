import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getLoyaltyConfig } from '../reducers/loyalty/loyalty';
import Heading from '../common/Heading';
import BenefitItem from './BenefitItem';
import { pushPath } from '../reducers/navigation/navigation'
import styles from '../styles/loyalty.css';
import BenefitItem2 from './BenefitItem2';
import BenefitItem3 from './BenefitItem3';

class BenefitList extends Component {

    componentDidMount() {
        this.props.getLoyaltyConfig();
    }

    render() {
        const { pushPath, loyalty, isHorizontalList, basefreebiestemplate } = this.props;
        const { freebies, loyaltyCardId, cards, userPoints } = loyalty;
        const currentCardDetails = cards.find((i) => {
            return i.id === loyaltyCardId;
        });
        const basetemplate = basefreebiestemplate?.basefreebiestemplate?.template

        return (
            <div>
                <Heading title={currentCardDetails && currentCardDetails.name} subTitle="Benefits" button={freebies.length > 3 ? true : false} actionTitle={'View All'} action={() => { pushPath('/all-benefits', null) }} />

                {basetemplate == 'template2' ?
                    <div
                        className={`${styles['freebies-container']}`}
                    >
                        {
                            freebies.slice(0, 6).map((item, index) => {
                                return <BenefitItem2 item={item} key={index} isHorizontalList={isHorizontalList} userPoints={userPoints} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} />
                            })
                        }
                    </div>
                    :
                    basetemplate == 'template3' ?
                        <div
                            className={`${styles['freebies-container']}`}
                        >
                            {
                                freebies.slice(0, 6).map((item, index) => {
                                    return <BenefitItem3 item={item} key={index} isHorizontalList={isHorizontalList} userPoints={userPoints} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} />
                                })
                            }
                        </div>
                        :
                        <div
                            style={{
                                display: isHorizontalList == true ? 'flex' : null,
                                overflowX: isHorizontalList == true ? 'auto' : null,
                            }}
                        >
                            {
                                freebies.slice(0, 4).map((item, index) => {
                                    return <BenefitItem item={item} key={index} isHorizontalList={isHorizontalList} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} />
                                })
                            }
                        </div>}
            </div>
        )
    }
}

const mapState = (state, ownprops) => {

    return {
        loyalty: state.loyalty,
        basefreebiestemplate: state.theme,
    };
}

const mapDispatch = {
    getLoyaltyConfig,
    pushPath

}

export default connect(mapState, mapDispatch)(BenefitList);
