import React, { Component } from 'react'
import { connect } from 'react-redux';
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

class ExclusiveBenefitItem extends Component {
    render() {
        const { item, onclick, benefitStyles } = this.props;
        const { exclusiveItemBackground, exclusiveItemTextColor } = benefitStyles || {};
        return (
            <div className={styles['fr-benefit-small-card']} style={{ background: exclusiveItemBackground || '#fff' }} onClick={() => onclick()}>
                <img src={item.image} alt="benefit" className={styles['fr-benefit-small-img']} />
                <span className={styles['fr-offer-card-title']} style={{ color: exclusiveItemTextColor || '#000' }}>{item.type}</span>
                <span style={{ color: exclusiveItemTextColor || '#000', textAlign: 'center', fontSize: 12 }} >{item.name}</span>
                <button className={`${styles['fr-link-white-btn']} reedem-button-small`} style={{ color: exclusiveItemTextColor || '#000' }}>
                    <span>Details</span><FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                </button>

            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    const { benefitStyles } = state.theme;
    return {
        benefitStyles,
    };
}

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(ExclusiveBenefitItem);

