import React from "react";
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

export default function ({ onclick }) {
    return (
        <div>
            <button onClick={() => onclick()} className={`${styles['fr-nagivation-back-btn']} header-button`} style={{ color: '#000' }}>
                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleLeft} />
                Back
            </button>
        </div>
    )
}
