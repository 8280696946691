
import React, { Component } from 'react';
import LoyaltyProfile from './LoyaltyProfile';
import styles from '../styles/loyalty.css';
import BenefitsList from './BenefitsList';
import { connect } from 'react-redux';
import DashboardDynamicLayout from './DashboardDynamicLayout';
import {
    getloyaltyPageLayout, getLoyaltyPageConfig
} from '../reducers/theme/theme';

class LoyaltyMain extends Component {


    constructor(props) {
        super(props)
        this.state = {
            currentTab: 'my_card',
            isLoading: true,
        }
    }

    componentDidMount() {
        this.props.getloyaltyPageLayout();
        this.props.getLoyaltyPageConfig();
        window.scrollTo(0, 0);
    }

    render() {

        const onTabClick = (value) => {
            this.setState({ currentTab: value })
        }

        const { currentTab } = this.state;
        const { termsUrl, themeColors, loyaltyLayoutContent } = this.props;
        const length = loyaltyLayoutContent && loyaltyLayoutContent.length || 0;
        const { themeColor, buttonTextColor, buttonBgColor } = themeColors || {};

        if (!loyaltyLayoutContent) {

            return (
                <div>
                    Loading...
                </div>
            );
        }

        if (!this.props.tabStyle) {
            return (
                <div className="loyalty-profile-main">

                    {length > 0 ? null :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ marginBottom: 30 }}>
                                <span
                                    className={`${styles['fr-loyalty-tab']} ${styles['fr-loyalty-tab-active']}`} style={{ borderBottomColor: themeColor || '#ddd' }}>
                                    My Card
                                </span>
                            </div>
                            <div>
                                <a href={termsUrl || ''} target="_blank" className={styles['fr-terms-btn']} style={{ background: buttonBgColor || '#000', color: buttonTextColor || '#fff' }}>
                                    View T&Cs
                                </a>
                            </div>
                        </div>}
                    {length > 0 ?
                        <DashboardDynamicLayout />
                        : <LoyaltyProfile />}
                </div>
            )

        }
        else {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: 30 }}>
                            <span style={{ borderBottomColor: themeColor || '#ddd' }}
                                className={currentTab === "my_card" ? `${styles['fr-loyalty-tab']} ${styles['fr-loyalty-tab-active']}` : styles['fr-loyalty-tab']} onClick={() => onTabClick('my_card')}>
                                My Card
                            </span>
                            <span style={{ borderBottomColor: themeColor || '#ddd' }} className={currentTab === "active" ? `${styles['fr-loyalty-tab']} ${styles['fr-loyalty-tab-active']}` : styles['fr-loyalty-tab']} onClick={() => onTabClick('active')}>
                                Benefits
                            </span>
                            <span style={{ borderBottomColor: themeColor || '#ddd' }}
                                className={currentTab === "expired" ? `${styles['fr-loyalty-tab']} ${styles['fr-loyalty-tab-active']}` : styles['fr-loyalty-tab']} onClick={() => onTabClick('expired')}>
                                History
                            </span>
                        </div>
                        <div>
                            <a href={termsUrl || ''} target="_blank" className={styles['fr-terms-btn']} style={{ background: buttonBgColor || '#000', color: buttonTextColor || '#fff' }}>
                                VIEW T&C
                            </a>
                        </div>
                    </div>

                    <div>
                        {currentTab === "my_card" ?
                            <LoyaltyProfile />
                            : currentTab === "active" ?
                                <BenefitsList type="active" /> :
                                <BenefitsList type="redeemed / expired" />}
                    </div>
                </div>
            );
        }
    }
}


const mapState = (state, ownprops) => {
    const { themeColors, redirectUrls, loyaltyLayoutContent } = state.theme;
    return {
        loyaltyLayoutContent,
        themeColors,
        termsUrl: redirectUrls && redirectUrls.termsUrls || {}
    };
}

const mapDispatch = {
    getloyaltyPageLayout,
    getLoyaltyPageConfig
}

export default connect(mapState, mapDispatch)(LoyaltyMain);