import apiClient from "../../api";


const initialState = {
    userInfo: null,
    program: [],
    milestones: [],
    shareCodeContent: null,
    steps: null,
    redirectUrls: null,
    themeColors: null,
    basicConfig: null,
    layoutConfig: null,
    layoutContent: null,
    loyaltyInfo: null,
    referralProgram:[],
    customeCss:{},
};

const GET_REFERRAL_PROFILE = 'referral/GET_REFERRAL_PROFILE';
const GET_REFERRAL_PROGRAM = 'referral/GET_REFERRAL_PROGRAM';
const GET_REFERRAL_STEPS = 'referral/GET_REFERRAL_STEPS';
const GET_REFERRAL_MILESTONES = 'referral/GET_REFERRAL_MILESTONES';
const SET_SHARE_CONTENT = 'referral/SET_SHARE_CONTENT'
const REDIRECT_URLS = 'referral/REDIRECT_URLS'
const THEME_COLORS = 'referral/THEME_COLORS'
const GET_BASE_CONFIG = 'referral/GET_BASE_CONFIG'
const GET_LAYOUT_CONFIG = 'referral/GET_LAYOUT_CONFIG'
const GET_LAYOUT_CONTENT = 'referral/GET_LAYOUT_CONTENT'
const GET_USER_LOYALTY_INFO = 'referral/GET_USER_LOYALTY_INFO'
const GET_REFERRAL_PROGRAM_2 = 'referral/GET_REFERRAL_PROGRAM_2';
const CUSTOM_CSS = 'referral/CUSTOM_CSS';

export const getProfile = () => async (dispatch, getState) => {


    const state = getState();
    const { merchantAccessToken, authToken } = state.session;


    try {
        const response = await apiClient.post(apiClient.Urls.getProfile, {
            authToken, merchantAccessToken
        });

        if (response.success) {
            dispatch({
                type: GET_REFERRAL_PROFILE,
                userInfo: response.userInfo,
            });

        } else {
            // dispatch(displayError('', response.message));
        }
    } catch (e) {
        // dispatch(displayError('EXCEPTION', e.message));
    }

}

export const getThemeColors = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'themeColors',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: THEME_COLORS,
                themeColors: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getCustomeCss = () => async (dispatch, getState) => {

  try {
      const state = getState();
      const { merchantAccessToken } = state.session;
      const response = await apiClient.get(apiClient.Urls.themeConfig, {
          model: 'customCss',
          template: 'referral-sdk',
          merchantAccessToken,
      });

      if (response.success) {
          dispatch({
              type: CUSTOM_CSS,
              customeCss: response.data,
          });
      } else {
      }
  } catch (error) {
  }
};

export const getUserLoyaltyInfo = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken, authToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.getUser, {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_USER_LOYALTY_INFO,
                loyaltyInfo: response,
            });
        } else {
        }
    } catch (error) {
    }
};

// https://www.froogal.in/dedicated-api/loyalty/v2/default/user/details?authToken=SKKOqiZdTtE0JokWFHvknmOjCNzjsHgYewaXMRJxnzbpLte5GIUsd5HU4XFl&merchantAccessToken=Kovpao95LurSJKEIgGSFzJeOpwW2MEdB

export const getBaseConfig = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'basicConfig',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_BASE_CONFIG,
                basicConfig: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getLayoutConfig = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'pageConfig',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_LAYOUT_CONFIG,
                layoutConfig: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getLayoutContent = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'pageLayout',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_LAYOUT_CONTENT,
                layoutContent: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getRedirectUrls = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'redirectUrls',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: REDIRECT_URLS,
                redirectUrls: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getSteps = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'getStarted',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_REFERRAL_STEPS,
                steps: response.data,
            })
        } else {
        }
    } catch (error) {
    }
}

export const getProgram = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'howItWorks',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_REFERRAL_PROGRAM,
                program: response.data,
            })
        } else {
        }
    } catch (error) {
    }
}
export const getReferralProgram = () => async (dispatch, getState) => {

  try {
      const state = getState();
      const { merchantAccessToken } = state.session;
      const response = await apiClient.get(apiClient.Urls.themeConfig, {
          model: 'howItWorksContent',
          template: 'referral-sdk',
          merchantAccessToken,
      });

      if (response.success) {
          dispatch({
              type: GET_REFERRAL_PROGRAM_2,
              referralProgram: response.data,
          })
      } else {
      }
  } catch (error) {
  }
}

export const setShareContent = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'shareCodeContent',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: SET_SHARE_CONTENT,
                content: response.data,
            })
        } else {
        }
    } catch (error) {
    }

}


export const getCouponCode = (id) => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken, authToken } = state.session;

        const freebie = await apiClient.get(apiClient.Urls.getFreebie(id), {
            authToken,
            merchantAccessToken,
        });

        if (freebie.success) {
            return freebie?.freebie?.freebieItem?.couponCode;
        }
        else {
            return null;
        }

    }
    catch (error) {
        return error;
    }
}

export const getMilestones = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken, authToken } = state.session;
        const response = await apiClient.post(apiClient.Urls.getMilestones, {
            authToken,
            merchantAccessToken,
        });

        const cmsresponse = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'milestones',
            template: 'referral-sdk',
            merchantAccessToken,
        });

        const mergedResponse = response?.data?.map((i, index) => {
            const cmsObj = cmsresponse?.data.find((item, ind) => ind === index ? item : null)

            const backgroundColor = cmsObj?.backgroundColor || '#131313';
            const description = cmsObj?.description || '';
            const offerText = cmsObj?.offerText || "";
            const title = cmsObj?.title || "";



            return {
                ...i,
                backgroundColor, description, offerText, title
            }
        });


        if (response.success) {
            dispatch({
                type: GET_REFERRAL_MILESTONES,
                milestones: mergedResponse,
            })
        } else {
        }
    } catch (error) {
    }
}


const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REFERRAL_PROFILE: {
            return {
                ...state,
                userInfo: action.userInfo,
            }
        }
        case GET_BASE_CONFIG: {
            return {
                ...state,
                basicConfig: action.basicConfig,
            }
        }
        case GET_LAYOUT_CONFIG: {
            return {
                ...state,
                layoutConfig: action.layoutConfig,
            }
        }
        case GET_LAYOUT_CONTENT: {
            return {
                ...state,
                layoutContent: action.layoutContent,
            }
        }

        case GET_REFERRAL_PROGRAM: {
            return {
                ...state,
                program: action.program,
            }
        }
        case GET_REFERRAL_PROGRAM_2: {
          return {
              ...state,
              referralProgram: action.referralProgram,
          }
      }
        case GET_REFERRAL_STEPS: {
            return {
                ...state,
                steps: action.steps,
            }
        }
        case GET_REFERRAL_MILESTONES: {
            return {
                ...state,
                milestones: action.milestones,
            }
        }
        case SET_SHARE_CONTENT: {
            return {
                ...state,
                shareCodeContent: action.content,
            }
        }
        case REDIRECT_URLS: {
            return {
                ...state,
                redirectUrls: action.redirectUrls
            }
        }
        case THEME_COLORS: {
            return {
                ...state,
                themeColors: action.themeColors
            }
        }
        case CUSTOM_CSS: {
          return {
              ...state,
              customeCss: action.customeCss
          }
      }
        case GET_USER_LOYALTY_INFO: {
            return {
                ...state,
                loyaltyInfo: action.loyaltyInfo,
            }
        }

        default: return state;
    }

}


export default sessionReducer;
