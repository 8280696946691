
import React, { Component } from 'react';
import Heading from '../common/Heading';
import getImage from '../images';
import styles from '../styles/loyalty.css';
import BenefitItem from './BenefitItem';

class BenefitsListPage extends Component {

    render() {
        const { type, freebies, pushPath } = this.props;

        return (
            <div>
                <div>
                    <Heading title="Showing" subTitle={type + " Benefits"} />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {freebies.length > 0 ?
                            freebies.map((item, index) => {
                                return (
                                    <div className={`${styles['fr-freebie-width']} fr-benefit-parent-div`} key={index}>
                                        <BenefitItem item={item} onclick={() => pushPath('/freebie-details', { freebie: item, type: type })} />
                                    </div>
                                )
                            })
                            :
                            <div className={styles['orderBox']}>
                                <h6>{type === "active" ? "No Benefits available now." : "No benefits redeemed / expired till now"}</h6>
                            </div>
                        }
                    </div>
                </div>

            </div>
        );
    }
}


export default BenefitsListPage;
