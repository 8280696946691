import React, { Component } from 'react';
import Dashboard from "./components/pages/dashboard";
import Loader from './components/common/LoaderInitial';
import styles from '../src/styles/gifting.css';
import { connect } from 'react-redux';
import { getDenominationConfig, getUserLocationAction, initialize } from "./reducers/session";
import SelectedGiftPage from './components/pages/giftDetails';
import { getThemesList, } from './reducers/theme';
import GiftingSummary from './components/pages/summary';
import { getUserInfoAction } from './reducers/user';
import OrderSuccess from './components/pages/orderSuccess';
import HTMLComment from 'react-html-comment';
import Package from '../package.json'
import { pushPath } from './reducers/navigation';
import { setGiftDetails } from './reducers/gifting';
import { transactionStatusAction } from './reducers/placeOrder';
import OrderHistory from './components/pages/orderHistory';

class Gifting extends Component {
  constructor(props) {
    super(props)
  }
  async componentDidMount() {

    let abc = {
      merchantAccessToken: this.props.merchantAccessToken,
      authToken: this.props.authToken,
      loyaltyId: this.props.loyaltyId,
      baseUrl: this.props.baseUrl,
      loginUrl: this.props.loginUrl,
      knowMoreUrl: this.props.knowMoreUrl,
      horizontalStyle: this.props.horizontalStyle
    }
    await this.props.initialize(abc);
    await this.props.getThemesList();
    await this.props.getDenominationConfig();
    await this.props.getUserInfoAction()
    await this.props.getUserLocationAction()

    const params = await new URLSearchParams(window.location.search);
    const urlParamameters = {
      orderDetails: JSON.parse(params.get('orderDetails')),
      paymentReturn: params.has('paymentReturn'),
      paymentStatus: params.get('paymentStatus'),
      transactionReference: params.get('transactionReference'),
      orderId: params.get('orderId'),
    }
    const {
      channel, customerEmail, customerName, customerPhone, customerPhoneCountryCode,
      message, orderType, receiverEmail, receiverName, receiverPhone,
      receiverPhoneCountryCode, source,
    } = urlParamameters.orderDetails || {}

    const {
      orderDetails, orderId, paymentReturn,
      paymentStatus, transactionReference } = urlParamameters || {}

    if (urlParamameters.paymentStatus === 'success') {
      await this.props.transactionStatusAction({
        orderDetails, orderId, paymentReturn,
        paymentStatus, transactionReference,
      })
      await this.props.pushPath('/order-success', this.props.navigation.params)

    }
    else if (urlParamameters.paymentStatus === 'failure') {

      const { data } = this.props.themesList
      let allList = []
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].theme.length; j++) {
          allList.push(data[i].theme[j])
        }
      }

      let selectedTheme = allList.find(a => a.id === orderDetails.cards[0].themeId)

      console.log({ urlParamameters })
      await this.props.setGiftDetails({
        themeId: orderDetails.cards[0].themeId,
        theme: selectedTheme.theme,
        amount: orderDetails.cards[0].cardValue,
        channel: channel,
        email: customerEmail,
        username: customerName,
        customerPhone: `+${customerPhoneCountryCode}${customerPhone}`,
        customerPhoneCountryCode: customerPhoneCountryCode,
        message: message,
        type: orderType,
        receiverEmail: receiverEmail,
        receiverName: receiverName,
        receiverPhoneNumber: `+${receiverPhoneCountryCode}${receiverPhone}`,
        receiverPhoneCountryCode: receiverPhoneCountryCode,
        source: source,

      })

      await this.props.transactionStatusAction({
        orderDetails, orderId, paymentReturn,
        paymentStatus, transactionReference,
      })
      await this.props.pushPath('/gift-summary', this.props.navigation.params)
    }
    else {
      await this.props.pushPath('/gifting', this.props.navigation.params) //prod
      // await this.props.pushPath('/order-history', this.props.navigation.params)

    }
  }

  render() {
    const { navigation, authToken, merchantAccessToken } = this.props;
    const { path } = navigation;

    const routes = {
      "/": Loader,
      // "/programs": ProgramList,
      // "/upgrade-card": UpgradeCard,
      "/gifting": Dashboard,
      "/gift-details": SelectedGiftPage,
      "/gift-summary": GiftingSummary,
      "/order-success": OrderSuccess,
      "/order-history": OrderHistory,
      // "/all-benefits": AllBenefits,
    };

    const ComponentToRender = routes[path] || null;

    return (
      ComponentToRender
        ?
        <div className={styles['fr-main-loyalty-div']}>
          <HTMLComment text={`Version ${Package.version}`} />
          <ComponentToRender
            authToken={authToken}
            merchantAccessToken={merchantAccessToken}
            termsUrl={this.props.termsUrl}
            tabStyle={this.props.tabStyle}
            baseUrl={this.props.baseUrl}
          />
        </div>
        : null
    );
  }
}

const mapState = (state, ownprops) => {
  return {
    navigation: state.navigation,
    themesList: state.theme.themesList
  };
}

const mapDispatch = {
  initialize,
  getThemesList,
  getDenominationConfig,
  getUserInfoAction,
  getUserLocationAction,
  pushPath,
  setGiftDetails,
  transactionStatusAction
}

export default connect(mapState, mapDispatch)(Gifting);