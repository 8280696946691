import React, { Component } from 'react';
import { initializeRegistration } from "../reducers/session/session";
import { getPrograms } from '../reducers/loyaltyprogram/loyaltyprogram'
import { pushPath } from '../reducers/navigation/navigation'
import { clearTransactionDetails } from '../reducers/loyalty/loyalty';
import { connect } from "react-redux";
import RegFormPage from '../components/RegFormPage'
import { LoyaltySignUp, getEnrollmentCriteria, getLoginOtp, verifyOtp } from '../reducers/signup/signup'
import Loyalty from '../Loyalty';
import Success from '../common/Success';
import Failure from '../common/Failure';
import UpgradeCard from './UpgradeCard';

class LoyaltyRootSignUp extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isPaymentScreen: false,
            paymentStatus: null,
            transactionData: null,
            orderDetails: null,
        }
        const { merchantAccessToken, authToken, shopifyCustomerId, postLoginCallback, loyaltyId } = this.props;
        this.props.initializeRegistration({ merchantAccessToken, authToken, loyaltyId, shopifyCustomerId, postLoginCallback });
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        window.scrollTo(0, 0);

        if (params.has('paymentReturn') && params.get('paymentType') == "loyaltyCard") {// TODO: move to costructor
            this.setState({ isPaymentScreen: true, paymentStatus: params.get('paymentStatus') });
            if (params.get('paymentStatus') == "success") {
                this.setState({ transactionData: { transactionId: params.get('transactionReference'), orderId: params.get('orderId') } });
            }
            else if (params.get('paymentStatus') == "failure") {
                this.setState({ orderDetails: JSON.parse(params.get('orderDetails')) });
            }
        }

    }


    render() {
        const { auth, programs, getLoginOtp, verifyOtp, isOtpSent, clearTransactionDetails, themeColors, pushPath, preFillData, readOnly, offerText, userDetails, isMultiCountryEnable, defaultCountry, termsUrl, customCss, getSignUptemplate } = this.props;
        const { programList } = programs;
        const { isPaymentScreen, paymentStatus, transactionData, orderDetails } = this.state;

        const baseProgram = programList && programList[0];

        const freeCard = baseProgram && baseProgram?.cards?.find(card => card.worth == "0");

        if (isPaymentScreen) {
            return (
                <div>
                    <style>{customCss}</style>
                    {paymentStatus == "success" ?
                        <Success pushPath={() => this.setState({ isPaymentScreen: false })} clearTransactionDetails={clearTransactionDetails} transactionData={transactionData} />
                        :
                        paymentStatus == "failure" ?
                            <Failure pushPath={() => {
                                this.setState({ isPaymentScreen: false })
                            }} orderDetails={orderDetails} />
                            : null}
                </div>
            )
        }

        if (userDetails && userDetails.isEnrolled) {
            return (
                <div>
                    <style>{customCss}</style>
                    <Loyalty
                        merchantAccessToken={this.props.merchantAccessToken}
                        loyaltyId={this.props.loyaltyId}
                        knowMoreUrl={this.props.knowMoreUrl}
                        tabStyle={this.props.tabStyle}
                        termsUrl={termsUrl}
                        {...this.props}
                    />

                </div>
            )
        }
        if (userDetails && userDetails.isEnrolled === false) {
            return (

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <style>{customCss}</style>
                    <RegFormPage
                        LoyaltySignUp={this.props.LoyaltySignUp}
                        getEnrollmentCriteria={this.props.getEnrollmentCriteria}
                        getPrograms={this.props.getPrograms}
                        programList={programList}
                        attributes={this.props.attributes}
                        loading={this.props.loading}
                        status={this.props.status}
                        termsUrl={termsUrl}
                        verifyMobile={auth ? false : true}
                        getLoginOtp={(val) => getLoginOtp(val)}
                        verifyOtp={verifyOtp}
                        isOtpSent={isOtpSent}
                        preFillData={preFillData}
                        offerText={offerText}
                        readOnly={readOnly}
                        themeColors={themeColors}
                        defaultCountry={defaultCountry}
                        pushPath={pushPath}
                    // getSignUptemplate={this.props.getSignUptemplate}
                    />
                </div>
            )
        }
        return (
            <div>
                Loading...
            </div>
        )

    }
}

const mapState = (state, ownprops) => {
    return {
        navigation: state.navigation,
        attributes: state.signup.signUpAttributes,
        offerText: state.signup.offerText,
        loading: state.signup.loading,
        status: state.signup.status,
        programs: state.loyaltyprogram,
        isOtpSent: state.signup.isOtpSent,
        auth: state.session.authToken,
        userDetails: state.loyalty.userDetails,
        termsUrl: state.theme.redirectUrls && state.theme.redirectUrls.termsUrls,
        themeColors: state.theme && state.theme.themeColors,
        customCss: state.theme && state.theme.customCss,
    };
}

const mapDispatch = {
    initializeRegistration,
    LoyaltySignUp,
    getEnrollmentCriteria,
    getLoginOtp,
    verifyOtp,
    getPrograms,
    pushPath,
    clearTransactionDetails,
}

export default connect(mapState, mapDispatch)(LoyaltyRootSignUp);
