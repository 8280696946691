import React, { Component } from 'react';
import styles from '../styles/loyalty.css';
import { Template1RenderField } from './SignUpPageTemp1.jsx';
import OtpForm from './OtpForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import OtpForm1 from './OtpForm1';


export default class RegFormPageTemp1 extends Component {


    render() {
        const { themeColors, programList, source } = this.props;



        return (
            <div className={`${styles['fr-signup-root-form']} signup-root-form`}>
                {this.props.verifyMobile ?
                    <OtpForm1 themeColors={themeColors}
                        preFillData={this.props.preFillData}
                        status={this.props.status} defaultCountry={this.props.defaultCountry} isMultiCountryEnable={this.props.isMultiCountryEnable} getLoginOtp={(val) => this.props.getLoginOtp(val)} isOtpSent={this.props.isOtpSent} verifyOtp={this.props.verifyOtp} />
                    :
                    programList && programList.length &&
                    <SignUpForm
                        signUpConfiguration={this.props.signUpConfiguration}
                        readOnly={this.props.readOnly}
                        LoyaltySignUp={this.props.LoyaltySignUp}
                        getEnrollmentCriteria={this.props.getEnrollmentCriteria}
                        programList={this.props.programList}
                        attributes={this.props.attributes}
                        loading={this.props.loading}
                        loyaltyKnowMore={this.props.loyaltyKnowMore}
                        configuration={this.props.configuration}
                        themeColors={this.props.themeColors}
                        offerText={this.props.offerText}
                        preFillData={this.props.preFillData}
                        getPrograms={this.props.getPrograms}
                        status={this.props.status}
                        termsUrl={this.props.termsUrl}
                        pushPath={this.props.pushPath}
                        source={source}


                    />
                }
            </div>
        )
    }
}


class SignUpForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            termsCheck: false,
            signUpData: {
                source: props.source
            }
        }
    }

    componentDidMount() {

        this.props.programList && this.props.programList.length > 0 && this.props.getEnrollmentCriteria(this.props.programList[0].id);

        this.props.preFillData && this.props.preFillData.forEach(i => {
            this.setState({ signUpData: { ...this.state.signUpData, [i.slug]: i.value } });
        });
    }

    render() {
        const { attributes, LoyaltySignUp, preFillData, readOnly, termsUrl, themeColors } = this.props;

        const onChange = (value, slug) => {
            const key = slug;
            this.setState({ signUpData: { ...this.state.signUpData, [key]: value } });
        }

        const config = this.props.signUpConfiguration
        const themeColor = this.props.themeColors

        return (

            <div style={{ textAlign: 'center', backgroundColor: "#F7F8FA" }}>
                <div className={`${styles['fr-signup-head']} form-head`} style={{ marginBottom: 15, paddingTop: 10 }}>
                    <img src={config?.image} alt="Img" style={{ width: 50, height: 80, objectFit: 'contain', borderRadius: 6, marginBottom: 10 }} />
                    <span className={`${styles['fr-modal-title']} form-title`} dangerouslySetInnerHTML={{ __html: config && config.title }}></span>
                    <span className={`${styles['fr-modal-sub-title']} form-subtitle`} dangerouslySetInnerHTML={{ __html: config && config.subTitle }}></span>
                </div>
                {attributes && attributes.map((item, index) => {
                    let current = preFillData && preFillData.find((i) => {
                        return item.slug == i.slug;
                    });

                    let onlyRead = current && current.value && readOnly && readOnly[current && current.slug] ? true : false;
                    return <Template1RenderField item={item} value={this.state.signUpData[current && current.slug]} key={index} readOnly={onlyRead}
                        onChange={(val, key) => {
                            onChange(val, key)
                        }} />
                })}

                <div className={styles["temp1-custom-field1"]}>
                    <input type="checkbox" checked={this.state.termsCheck} onClick={() => {
                        this.setState({ termsCheck: !this.state.termsCheck })
                    }} className="terms-check-box" style={{ width: "auto" }} />
                    <div style={{ display: "flex", width: "85%", marginTop: 3 }}>
                        <span style={{ fontSize: 12, marginLeft: 7, flexDirection: "row", marginRight: 4, color: "#788494" }}>
                            By agree with our <br />
                        </span>
                        <a href={termsUrl} style={{ color: themeColor?.themeColor, fontSize: 12, fontWeight: "bold" }} target='_blank'>Terms & Conditions</a>
                    </div>
                </div>


                <div
                    className={styles["temp1-custom-btn"]}
                >
                    <button onClick={() => {
                        if (this.state.termsCheck) {
                            LoyaltySignUp(this.state.signUpData)
                        }
                        else {
                            alert("please accept the terms & conditions");
                        }
                    }}
                        className={styles["temp1-custom-btn-field1"]}
                        style={{ backgroundColor: themeColors && themeColors.buttonBgColor, color: themeColors && themeColors.buttonTextColor }}
                    >
                        Sign Up<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowCircleRight} />
                    </button>
                </div>
            </div>

        )
    }
}


