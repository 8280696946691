import React, { Component } from 'react';
import styles from './../styles/loyalty.css';
import BackButton from './BackButton';

export default class Failure extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { pushPath, orderDetails } = this.props;

        return (
            <div>
                <div>
                    <BackButton onclick={() => { pushPath(); }} />
                    <hr />
                </div>

                <div style={{ display: "flex", minHeight: "90vh", padding: 20, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <span style={{ marginTop: '1rem', marginBottom: '1rem', color: "#d84832", fontSize: "2rem", fontWeight: "bold" }}>Payment Failed!</span>

                    <span style={{ color: " #7a7a7a", fontSize: "1rem", textAlign: 'center' }}>
                        Your payment has failed. If the amount is deducted, it will be refunded to your account in 7-10 working days.
                    </span>
                    <div style={{ marginTop: 10 }}>
                        <button onClick={() => {
                            pushPath();
                        }}
                            className={styles['fr-failure-modal-btn']} style={{ width: '100%', height: 40 }}>Try Again</button>
                    </div>
                </div>
            </div>

        )
    }
}
