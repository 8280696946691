import React, { Component } from 'react';
import GiftCardHistoryDesktopItem from './GiftCardHistoryDesktopItem';
import GiftCardHistoryDesktopHeader from './GiftCardHistoryDesktopHeader';
import GiftCardHistoryMobileItem from './GiftCardHistoryMobileItem';

class GiftCardHistoryComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            copiedUrlIndex: null,
            copiedPinIndex: null,

        };
    }

    copyToClipboard(text, index, type) {

        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        if (type === 'urls') {
            this.setState({ copiedUrlIndex: index });
        } else if (type === 'pins') {
            this.setState({ copiedPinIndex: index });
        }
        setTimeout(() => {
            this.setState({ copiedPinIndex: null });
            this.setState({ copiedUrlIndex: null });

        }, 1500);
    }

    render() {
        const { activeFreebies, themeColors, isDeskTop } = this.props;
        const { copiedUrlIndex, copiedPinIndex, } = this.state;

        return (
            <div >
                {isDeskTop == true ?
                    <GiftCardHistoryDesktopHeader /> : null}
                {activeFreebies.map((item, index) => {
                    return (
                        isDeskTop == true ?
                            <GiftCardHistoryDesktopItem item={item}
                                index={index}
                                themeColors={themeColors}
                                copiedUrlIndex={copiedUrlIndex}
                                copiedPinIndex={copiedPinIndex}
                            />
                            :
                            <GiftCardHistoryMobileItem item={item}
                                index={index}
                                themeColors={themeColors}
                                copiedUrlIndex={copiedUrlIndex}
                                copiedPinIndex={copiedPinIndex}
                            />
                    )
                })
                }
            </div>
        )
    }
}
export default GiftCardHistoryComponent;
