import React, { useEffect, useRef } from "react";

const PaymentSubmit = ({
  pgDetails
}) => {
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
  }, []);
  return (
    <form ref={formRef} method={pgDetails.method} action={pgDetails.url}>
      {pgDetails.payload && Object.keys(pgDetails.payload).map(name => (
        <input
          type="hidden"
          name={name}
          value={pgDetails.payload[name]}
        ></input>
      ))}
    </form>
  );
};

export default PaymentSubmit;
