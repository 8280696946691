import React, { Component } from 'react'
import styles from '../styles/referral.css'

class LeaderBoardPage extends Component {
    render() {
        return (
            <div>
                <h1>Coming Soon...</h1>
            </div>
        )
    }
}

export default LeaderBoardPage;