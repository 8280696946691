import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setShareContent, getProfile } from '../reducers/referral/referral';
import ShareYourCodePage from '../components/ShareYourCodePage';

class ShareYourCode extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.setShareContent();
        this.props.getProfile();
    }

    render() {
        const { shareCodeContent, userInfo } = this.props;

        if (!shareCodeContent || !userInfo) {
            return null;
        }

        return <ShareYourCodePage shareCodeContent={shareCodeContent} userInfo={userInfo} />
    }
}

const mapState = (state, ownprops) => {

    return {
        shareCodeContent: state.referral.shareCodeContent,
        userInfo: state.referral.userInfo
    };
}

const mapDispatch = {
    setShareContent,
    getProfile
}

export default connect(mapState, mapDispatch)(ShareYourCode);