import React, { Component } from 'react'
import styles from './commonStyles.css';

export default function ContinueButton(props) {
  const { title, subTitle, button, actionTitle, action } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button onClick={action}
        className={styles['gft-small-btn']}>
        Continue
      </button>
    </div>
  )
}
