import React, { Component } from 'react'
import { connect } from 'react-redux';
import getImage from '../images';
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

class BenefitItem extends Component {
    render() {
        const { item, onclick, benefitStyles, type, icons, isHorizontalList } = this.props;
        const { listItemBackground, listItemTextColor, exclusiveItemBackground, exclusiveItemTextColor } = benefitStyles || {};
        const bgcolor = type == 'exclusive' ? exclusiveItemBackground || '#ddd' : listItemBackground || '#ddd';
        const color = type == 'exclusive' ? exclusiveItemTextColor || '#fff' : listItemTextColor || '#FFF';
        const { pointsIcon } = icons || {};

        return (

            <div className={`${styles['benefit-item-fr']} benefit-item`} style={{
                background: bgcolor || '#fff', marginRight: 10, overflow: isHorizontalList == true ? null : 'hidden'
            }}
                onClick={() => onclick()}
            >
                <div className={`${styles['modalProductImg-fr']} benefit-image-div`}>
                    <img src={item.image} alt="Img" style={{ width: 120, height: 120, objectFit: 'contain', borderRadius: 6 }} />
                </div>
                <div className={styles['modalProductDetails-fr']} style={{ color: color || '#000', width: isHorizontalList == true ? 250 : 'calc(100% - 120px)' }}>
                    <h5 style={{ color: color || '#000' }}>{item.name}</h5>
                    <p style={{ color: color || '#000' }}>{item.description || ''}</p>
                    <div className={`${styles['redeem-btn-small-div']} redeem-button-small-div`}>

                        <div className={`${styles['redeem-btn-small-div']}`}>
                            {Math.round(item.worth) == "0" ? null :
                                <span style={{ display: 'flex', alignItems: 'center', color: color || '#000' }}>
                                    <img src={pointsIcon || getImage('points_icon.png')} className="benefit-points-icon" style={{ width: 16, height: 16 }} alt="Img" />
                                    {item.worth}
                                </span>
                            }
                        </div>

                        <button style={{ color: color || '#000' }} className={`${styles['fr-link-black-btn']} reedem-button-small`} onClick={() => onclick()}>
                            <span className={`${styles['fr-link-btn-label']} reedem-button-label`}>Details</span>
                            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    const { benefitStyles, icons } = state.theme;

    return {
        benefitStyles,
        icons,
    };
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(BenefitItem);

