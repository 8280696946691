import React, { Component } from 'react';
import Heading from '../common/Heading';
import getImage from "../images";
import styles from '../styles/loyalty.css';
import ConversionCard from './ConversionCard';
import EarningRateCard from './EarningRateCard';
import BackButton from '../common/BackButton';
import ExclusiveBenefitItem from './ExclusiveBenefitItem';

export default class UpgradeCardPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedIndex: 0,
            selectedItem: this.props.upgradableCards && this.props.upgradableCards[0]
        }
    }

    

    componentDidMount() {
        this.props.getBenfits(this.state.selectedItem && this.state.selectedItem.id);
        this.props.getExclusiveBenfits(this.state.selectedItem && this.state.selectedItem.id);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({ selectedItem: this.props.upgradableCards && this.props.upgradableCards[0] });
        }
    }


    render() {

        const { buyLoyaltyCard, getExclusiveBenfits, currency, exclusiveBenefits, isSignUp, pushPath, upgradableCards, benefits, getBenfits, pointsLabel, displayConversionRateComponent, defaultCurrencyConversionRate } = this.props;

        const baseBenefits = benefits.length > 6 ? benefits.slice(0, 3).concat(benefits.slice(benefits.length - 1, 3)) : benefits;

        const selectCard = (item, index) => {
            getBenfits(item.id);
            getExclusiveBenfits(item.id);
            this.setState({ selectedItem: item, selectedIndex: index })
        }


        if (!isSignUp && upgradableCards?.length == 0) {
            return (
                <div>
                    <div>
                        <BackButton onclick={() => pushPath('/loyalty', null)} />
                        <hr />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span className={`${styles['fr-success-msg-1']} success-msg-1`}>You are already upgraded!</span><br />
                        <span className={`${styles['fr-success-msg-1']} success-msg-1`}>Thank You!</span>
                    </div>

                </div>
            )
        }


        return (
            <div style={{ minHeight: '100vh' }}>
                {!isSignUp &&
                    <div>
                        <BackButton onclick={() => pushPath('/loyalty', null)} />
                        <hr />
                    </div>}
                <div>
                    <Heading title="Available" subTitle={isSignUp ? "Cards" : "Card Upgrades"} />
                    <div style={{ display: 'flex' }}>
                        {upgradableCards && upgradableCards.map((item, index) => {
                            return <ProgramItem selectCard={() => selectCard(item, index)} item={item} key={index} isSelected={index === this.state.selectedIndex ? true : false} currency={currency} />
                        })}
                    </div>
                </div>

                <div>
                    <Heading title="Benefits Available" subTitle="With This Program" />
                    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                        {baseBenefits.concat(exclusiveBenefits).map((item, index) => {
                            return <ExclusiveBenefitItem key={index} item={item} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'upgrade' })} />
                        })}
                    </div>
                </div>


                {displayConversionRateComponent ?
                    <div className={`${styles['conversion-cards-div']}`}>
                        <ConversionCard defaultCurrencyConversionRate={defaultCurrencyConversionRate} pointsLabel={pointsLabel} />
                        <EarningRateCard conversionRate={this.state.selectedItem && this.state.selectedItem.pointsRate} />
                    </div>
                    : null}


                <div>
                    <Heading title="About" subTitle={this.state.selectedItem && this.state.selectedItem.name + " Program"} />
                    <div>
                        <p>{this.state.selectedItem && this.state.selectedItem.benefits}</p>
                    </div>
                </div>
                {this.state.selectedItem && this.state.selectedItem.worth && this.state.selectedItem.worth != 0 &&
                    <div>
                        <button className={`${styles['fr-redeem-btn']} fr-btn-cutom`} style={{ height: 50 }} onClick={() => buyLoyaltyCard(this.state.selectedItem && this.state.selectedItem.id)}>Proceed to payment<img src={getImage('arrow.svg')} alt="Img" /></button>
                    </div>}

            </div>
        )
    }
}

class ProgramItem extends Component {
    render() {
        const { item, isSelected, selectCard, currency } = this.props;

        const spend = item.upgradeConditions.length && item.upgradeConditions[0].spend;

        return (
            <div onClick={() => selectCard()} style={{ backgroundImage: `url(${item.backgroundImage})`, background: `${item.background}`, backgroundColor: `${item.color}`, boxShadow: `0 5px 10px ${item.bgColor}` }} className={styles['fr-tier-card']}>


                <span className={styles['fr-tier-card-title']} style={{ color: item && item.textColor }}>{item.name} Card</span>
                {item.worth != 0 &&
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <span className={styles['fr-card-offer-text']} style={{ color: item && item.textColor }}>Purchase for</span>
                        <span className={styles['fr-tier-card-amount']} style={{ color: item && item.textColor }}>{currency}{(item.worth).toLocaleString('en-IN')}</span>
                        <span className={styles['fr-card-offer-text']} style={{ color: item && item.textColor }}>per {item.subscriptionTerm || 'year'}</span>
                    </div>}
                {item.worth != 0 && <span className={styles['fr-tier-card-title']} style={{ color: item && item.textColor, width: '20%' }}>OR</span>}
                {spend != 0 && (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <span className={styles['fr-card-offer-text']} style={{ color: item && item.textColor }}>Purchase</span>
                    <span className={styles['fr-tier-card-amount']} style={{ color: item && item.textColor }}>{currency}{spend.toLocaleString('en-IN')}</span>
                    <span className={styles['fr-card-offer-text']} style={{ color: item && item.textColor }}>per {item.subscriptionTerm || 'year'}</span>
                </div>)}


                <span className={styles['fr-card-offer-text']}>{item.offer}</span>
                <img src={isSelected ? getImage('green_tick.svg') : getImage('grey_tick.svg')} alt="Img" className={styles['fr-tick-card-img']} />

            </div>
        )
    }
}

class BenefitItem extends Component {
    render() {
        const { item, pushPath } = this.props;


        return (
            <div className={styles['fr-benefit-small-card']} style={{ background: `${item.background ? item.background : "#586065"}` }}>
                <img src={item.image} alt="benefit" className={styles['fr-benefit-small-img']} />
                <span className={styles['fr-offer-card-title']}>{item.tag}</span>
                <span style={{ color: "#FFF", textAlign: 'center', fontSize: 12 }}>{item.name}</span>
                <button className={styles['fr-link-white-btn']} onClick={() => pushPath('/freebie-details', { freebie: item, type: 'upgrade' })}>
                    <span>Details</span><img src={getImage('white_arrow.svg')} alt="Img" />
                </button>

            </div>
        )
    }
}
