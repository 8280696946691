
import React, { Component, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import styles from '../../../styles/gifting.css';
import getImage from "../../../images";
import Offers from './Tab.Offers';
import TermsConditions from './Tab.TermsConditions'
function Offers_Terms(props) {
  const session = useSelector(state => state.session)
  const [currentTab, setCurrentTab] = useState('t&c')

  const onTabClick = (value) => {
    setCurrentTab(value)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} >
      {/* <div className={styles['gft-tabs-wrap']} >
        <div
          className={styles['gft-offer-tabs']}
        >
          <text
            className={currentTab === "my_card" ? `${styles['fr-loyalty-tab-active']}` : styles['fr-loyalty-tab']} onClick={() => onTabClick('my_card')}>
            offers
          </text>
          <div style={{ width: 2, backgroundColor: '#D9D9D9' }} />
          <text className={currentTab === "active" ? `${styles['fr-loyalty-tab-active']}` : styles['fr-loyalty-tab']} onClick={() => onTabClick('active')}>
            terms
          </text>
        </div>
      </div> */}

      {
        currentTab === "my_card" ?
          <Offers {...props} />
          : currentTab === "t&c" ?
            <TermsConditions />
            :
            null
      }

    </div>
  );
}

export default Offers_Terms;