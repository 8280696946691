import React from 'react';
import Heading from '../common/Heading';
import CurrentCard from '../containers/CurrentCard';

import styles from '../styles/loyalty.css';
import ConversionCard from './ConversionCard';
import EarningRateCard from './EarningRateCard';
import TierProgressCard from './TierProgressCard';
import OverAllProgressCard from './OverAllProgressCard';
import KnowMoreCard from './KnowMoreCard';
import BenefitItem from './BenefitItem';
import ExclusiveBenefitItem from './ExclusiveBenefitItem';
import ContentBox from './ContentBox';

export default function LoyaltyProfilePage(props) {
    const { userDetails, currentCardDetails, configuration, currentProgram, userPoints, horizontalStyle, pointsLabel, currency, aboutProgramComponentInfo, displayConversionRateComponent, defaultCurrencyConversionRate, nextCardDetails, freebies, exclusiveFreebies, textColor, rewards, redeemed, conversionRate, nextCardStatus, pushPath, knowMoreUrl, showManageProgram } = props;

    return (
        currentCardDetails ?
            <div className={`${styles['loyalty-home-fr']}`} style={{ color: textColor }}>

                <div className={styles['rewards1']}>
                    <Heading className={'manage-program-btn'} title="Rewards" subTitle="Overview" button={showManageProgram} actionTitle={'Manage program'} action={() => { pushPath('/programs', null) }} />
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'auto' }}> */}
                    <CurrentCard />
                    {/* </div> */}

                    <ConversionCard textColor={textColor} defaultCurrencyConversionRate={defaultCurrencyConversionRate} pointsLabel={pointsLabel} />


                    <EarningRateCard conversionRate={currentCardDetails && currentCardDetails.pointsRate} />

                    <ContentBox />

                    <TierProgressCard nextCardDetails={nextCardDetails} currentProgram={currentProgram} pushPath={pushPath} currentCardDetails={currentCardDetails} nextCardStatus={nextCardStatus} />

                    <OverAllProgressCard userDetails={userDetails} cards={currentProgram && currentProgram.cards} pushPath={pushPath} />

                    <KnowMoreCard />

                </div>
                <div className={styles['rewards2']}>
                    {freebies && freebies.length > 0 ?
                        <div>
                            <Heading title={currentCardDetails && currentCardDetails.name} subTitle="Benefits" button={freebies.length > 3 ? true : false} actionTitle={'View All'} action={() => { pushPath('/all-benefits', null) }} />
                            {
                                freebies.slice(0, 4).map((item, index) => {
                                    return <BenefitItem item={item} key={index} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} />
                                })
                            }
                        </div> : null}

                    {exclusiveFreebies && exclusiveFreebies.length > 0 ?
                        horizontalStyle ?
                            <div>
                                <Heading title={currentCardDetails && currentCardDetails.name} subTitle="Exclusive Benefits" button={freebies.length > 3 ? true : false} actionTitle={'View All'} action={() => { pushPath('/all-benefits', null) }} />
                                {
                                    exclusiveFreebies.slice(0, 4).map((item, index) => {
                                        return <BenefitItem item={item} key={index} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} type={'exclusive'} />
                                    })
                                }
                            </div> :
                            <div>
                                <div className="exclusive-heading">
                                    <Heading title={currentCardDetails && currentCardDetails.name} subTitle="Exclusive Benefits" button={false} actionTitle={'View All'} action={() => { pushPath('/all-benefits', null) }} />
                                </div>
                                <div style={{ marginTop: 5, flexDirection: 'row', display: 'flex', overflowX: 'scroll' }}>
                                    {
                                        exclusiveFreebies.slice(0, 4).map((item, index) => {
                                            return <ExclusiveBenefitItem item={item} key={index} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} />
                                        })
                                    }
                                </div>
                            </div>
                        : null}
                    {configuration && configuration.disableHistoryButton == '1' ? null :
                        <button className={styles['fr-history-btn']} onClick={() => pushPath('/points-history', null)}>
                            History
                        </button>
                    }
                </div>

            </div>
            : <div>
                <span>Your reward program is loading....</span>
            </div>
    );
}

