import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ColumnLayout from './ColumnLayout';
import RowLayout from './RowLayout';
import HowItWorks2 from './HowItworks2';
import ShareYourCode from './ShareYourCode';
import RewardBalanceCard from './RewardBalanceCard';
import Header from '../common/Header';
import CustomCard from './CustomCard';

const DynamicLayout = (props) => {

    const { layoutConfig, layoutContent,customeCss } = props;
    const {customCss} = customeCss;

    const { layoutDirection } = layoutConfig && layoutConfig || {};

    var sectionList = [];

    layoutContent?.forEach((obj) => {
        if (sectionList.indexOf(obj.section) == -1) {
            sectionList.push(obj.section);
        }
    });

    const direction = layoutDirection && layoutDirection || "row"

    var componentsMap = (item) => {
        switch (item.component) {
            case 'HowItWorks': return <HowItWorks2 />
            case 'ShareYourCode': return <ShareYourCode />
            case 'PointsHistory': return <PointsHistory />
            case 'RewardBalanceCard': return <RewardBalanceCard config={JSON.parse(item.config)} />
            case 'Header': return <Header {...JSON.parse(item.config)} />
            case 'CustomCard': return <CustomCard {...JSON.parse(item.config)} />
        }
    }

    return (<div>
      <style>{customCss}</style>
       { direction && direction == 'row' ?
            <RowLayout
                sectionList={sectionList || []}
                components={layoutContent}
                componentsMap={componentsMap}
            />
            :
            <ColumnLayout
                sectionList={sectionList || []}
                components={layoutContent}
                componentsMap={componentsMap}
            />}
            </div>
    )
}

const mapState = (state, ownprops) => {
    const { layoutConfig, layoutContent,customeCss } = state.referral;

    return {
        layoutConfig,
        layoutContent,
        customeCss
    };
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(DynamicLayout);
