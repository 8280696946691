import React, { Component } from 'react'
import styles from '../styles/referral.css'
import getImage from "../images";
import Header from '../common/Header';
import { Liquid } from 'liquidjs'

const engine = new Liquid();

export default class ReferralOverviewPage extends Component {

    constructor() {
        super();
        this.state = { shareContent: "" };
    }

    componentWillReceiveProps() {

        const { shareCodeContent, userInfo } = this.props;
        const { shareUrl, shareMessage } = shareCodeContent || {};
        if (shareCodeContent) {
            const tpl = engine.parse(shareMessage);
            engine.render(tpl, { code: userInfo?.referralCode, url: shareUrl }).then(t => this.setState({ shareContent: t }));
        }

    }

    render() {



        const { userInfo, program, steps, shareCodeContent, basicConfig } = this.props;

        const { getStartedBgColor } = basicConfig || {};
        const { shareUrl, shareTitle, fbAppId } = shareCodeContent || {};
        const { shareContent } = this.state;


        const facebookShareLink = () => {
            const shareUrl1 = encodeURIComponent(shareUrl);
            const currentUri = encodeURIComponent(window.location.href);
            return `https://www.facebook.com/dialog/share?app_id=${fbAppId}&display=popup&href=${shareUrl1}&redirect_uri=${currentUri}`;
        }
        const whatsappShareLink = () => {
            const text = encodeURIComponent(shareContent);
            return `https://api.whatsapp.com/send?text=${text}`;
        }

        const twitterShareLink = () => {
            const text = encodeURIComponent(shareContent);
            return `https://twitter.com/intent/tweet?text=${text}`;
        }

        const emailShareLink = () => {
            const text = encodeURIComponent(shareContent);
            const subject = encodeURIComponent(shareTitle);
            return `mailto:?body=${text}&subject=${subject}`;
        }

        const copyclipboard = () => {
            var copyText = document.getElementById("ref_code");
            copyText.select();
            copyText.setSelectionRange(0, 99999)
            document.execCommand("copy");
        }

        return (
            <div className={styles["aptrtonix-block"]}>
                <div className="header-display-none" >
                    <Header title="How" subTitle="Does It Work?" />
                </div>
                <div className={`${styles['fr-how-it-works-div']} how-it-works-one`}>
                    {program && program.map((item, index) => {
                        return (
                            <HowItWorksItem item={item} key={index} />
                        )
                    })}
                </div>
                <div className={`${styles['fr-how-it-works-div-two']} how-it-works-two`}>
                    <HowItWorksTwo />
                </div>
                <div className="header-display-none" >
                    <Header title="How" subTitle="To Get Started" />
                </div>
                {steps?.length > 0 &&
                    <div className={`${styles["how-does-box"]} referral-steps-block`} style={{ background: getStartedBgColor }}>
                        {steps.map((i, index) => {

                            return (
                                <div className={styles[`box${index + 1}`]} key={index}>
                                    <div className={styles["sub-div"]}>
                                        <div className={styles["step-icon-count"]}>
                                            <div className={styles["howodes-digit"]}>{index + 1}</div>
                                            <img src={i.stepIcon} className={styles["direction"]} />
                                        </div>
                                        <div className={styles["box-title"]} style={{ textTransform: 'uppercase', marginTop: 10 }}>
                                            {i.stepTitle}
                                        </div>
                                        <span className={styles["box-content"]}>
                                            {i.stepDescription}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                }
                <div className="header-display-none" >
                    <Header title="Share" subTitle="Your Code" />
                    {/* <span>{testString}</span> */}
                </div>
                <div className={`${styles["share-code-boxes"]} share-code-block`} style={{ background: shareCodeContent && shareCodeContent.backgroundColor }}>
                    <div className={styles["share-code-icon-div"]}>
                        <img src={shareCodeContent && shareCodeContent.icon} />
                    </div>
                    <div className={styles["cuponcode-box"]}>
                        <div className={`${styles["cuponcode-input-label"]} share-code-sub-title`} dangerouslySetInnerHTML={{ __html: shareCodeContent && shareCodeContent.subTitle }} />

                        <div className={styles["input-filed"]}>
                            <input type="text" id="ref_code" value={userInfo?.referralCode || ""} className={`${styles["cupon-input-box"]} share-code-input`} />
                            <button type="button" onClick={() => {
                                copyclipboard();
                            }} className={`${styles["copy-btn"]} copy-code-button`}>copy</button>
                        </div>
                    </div>
                    <div className={styles["share-links-box"]}>
                        <div className={`${styles["social-icons-block"]} share-icons-block`}>
                            <div className={styles["facebook-twitter-block"]}>
                                <div className={styles["facebook-icon"]}>
                                    <a href={facebookShareLink()} target="blank">
                                        <img src={getImage("facebook.svg")} />
                                    </a>
                                </div>
                                <div className={styles["twitter-icon"]}>
                                    <a href={twitterShareLink()} target="blank">
                                        <img src={getImage("twitter.svg")} />
                                    </a>
                                </div>
                            </div>
                            <div className={styles["whats-upload-block"]}>
                                <div className={styles["whatsapp-icon"]}>
                                    <a href={whatsappShareLink()} target="blank">
                                        <img src={getImage("whatsapp.svg")} />
                                    </a>
                                </div>
                                <div className={styles["upload-icon"]}>
                                    <a href={emailShareLink()} target="blank">
                                        <img src={getImage("upload.svg")} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class HowItWorksItem extends Component {
    render() {
        const { image, backgroundColor, content, title } = this.props.item;
        return (
            <div className={styles['fr-how-works-item']} style={{ background: backgroundColor }}>
                <div className={styles['fr-how-it-works-title-div']}>
                    {image && <div>
                        <img src={image} style={{ height: 100 }} />
                    </div>}
                    <div style={{ fontSize: 26, marginLeft: 10 }}>
                        <span>{title}</span>
                    </div>

                </div>
                <div className={`fr-program-content program-content`}>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>

            </div>
        )
    }
}

class HowItWorksTwo extends Component {
    render() {

        return (
            <div className={styles['fr-refer-block-two']} style={{ backgroundImage: `url(${getImage('refer-block-bg.png')})` }}>
                <div>
                    <span className={`block-two-title-one`}>REFERRAL PROGRAM</span>
                </div>
                <div>
                    <span className={`block-two-title-two`}>Refer your friends <br /> and you both win!</span>
                </div>

            </div>
        )
    }
}
