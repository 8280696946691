
import React, { Component } from 'react';
import GiftCards from './GiftCards';
import SendOption from '../../common/SendOption';
import HistoryButton from '../../common/Button.History';

class Dashboard extends Component {

  render() {
    return (
      <div  >
        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <SendOption />
          <HistoryButton />
        </div>

        <div style={{ marginLeft: 15, marginTop: 30, width: 350 }}>
          Select one of the themes to start.
        </div>

        <GiftCards />
      </div>
    );

  }
}

export default Dashboard;