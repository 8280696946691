
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pushPath } from '../reducers/navigation/navigation'
import { getBenfits, getExclusiveBenfits } from '../reducers/loyaltyprogram/loyaltyprogram'
import { buyLoyaltyCard, clearTransactionDetails } from '../reducers/loyalty/loyalty';
import UpgradeCardPage from '../components/UpgradeCardPage'
import Success from '../common/Success';
import PaymentSubmit from '../common/PaymentSubmit';
class UpgradeCard extends Component {


    constructor(props) {
        super(props)
        this.state = {
            authToken: this.props.authToken,
            merchantAccessToken: this.props.merchantAccessToken,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const { loyaltyprogram, navigation, getBenfits, loyalty, getExclusiveBenfits, buyLoyaltyCard, pushPath, clearTransactionDetails, isSignUp } = this.props;
        const { displayConversionRateComponent, pointsLabel, defaultCurrencyConversionRate, currency, transactionDetails, pgDetails } = loyalty;

        const { programList, selectedCardBenefits, exclusiveBenefits } = loyaltyprogram;

        const { params } = navigation;
        const { currentProgramId, currentCardId } = params || {};

        const currentProgram = isSignUp ? programList && programList[0] : programList && programList.find((i, index) => { return i.id === currentProgramId });


        const currentCard = isSignUp ? null : currentProgram && currentProgram.cards.find((i, index) => {
            return i.id === currentCardId
        });

        const currentCardIndex = isSignUp ? null : currentProgram?.cards?.findIndex((i) => i.id == currentCard.id);

        const upgradableCards = isSignUp ? currentProgram?.cards : currentProgram && currentProgram.cards.filter((i, index) => {
            return index > currentCardIndex;
        });

        if (transactionDetails && transactionDetails.success) {
            return (
                <Success isSignUp={isSignUp} transactionData={transactionDetails.data} pushPath={pushPath} clearTransactionDetails={clearTransactionDetails} />
            )
        }

        return (
            <div style={{ padding: 25 }}>
                <UpgradeCardPage isSignUp={isSignUp} buyLoyaltyCard={buyLoyaltyCard} currency={currency} exclusiveBenefits={exclusiveBenefits} pointsLabel={pointsLabel} defaultCurrencyConversionRate={defaultCurrencyConversionRate} displayConversionRateComponent={displayConversionRateComponent} benefits={selectedCardBenefits}
                    getExclusiveBenfits={(id) => getExclusiveBenfits(id)} getBenfits={(id) => getBenfits(id)} pushPath={pushPath} upgradableCards={upgradableCards} />
                {pgDetails && pgDetails.goToPg && <PaymentSubmit pgDetails={pgDetails} />}
            </div>
        );
    }
}


const mapState = (state, ownprops) => {
    return {
        loyaltyprogram: state.loyaltyprogram,
        loyalty: state.loyalty,
        navigation: state.navigation
    };
}

const mapDispatch = {
    pushPath,
    getBenfits,
    getExclusiveBenfits,
    buyLoyaltyCard,
    clearTransactionDetails,
}

export default connect(mapState, mapDispatch)(UpgradeCard);
