import React, { Component } from 'react';
import getImage from "../images";
import styles from '../styles/loyalty.css';
import Modal from 'react-modal';
import BackButton from '../common/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faStar, faGift, faPercent, faAngleDown } from '@fortawesome/free-solid-svg-icons'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '5px',
        overflow: 'hidden'
    },
    overlay: {
        background: '#000000e0'
    }
};

export default class BenefitPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            about: false,
            terms: false,
            isModalOpen: false,
            expand: false,
            hide: false
        }
    }

    componentDidMount() {
        if (this.props.freebie.validOutlets && this.props.freebie.validOutlets.length < 3) {
            this.setState({ expand: true })
        }
    }

    render() {


        const { freebie, type, redeemFreebie, userPoints, pushPath, redeemedFreebie, icon, themeColors, configuration } = this.props;

        const { expand } = this.state;
        const { themeColor } = themeColors || {};

        const validDays = freebie.validDays.split('');
        const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const expiryDate = redeemedFreebie && redeemedFreebie.freebie.freebieItem.expiryDate
        const points = parseInt(freebie.worth) - parseInt(userPoints)

        return (
            <div>
                <div >
                    <BackButton onclick={() => { this.setState({ hide: false }), pushPath('/loyalty', null) }} />
                    <hr />
                </div>

                {freebie ?
                    <div className={styles['fr-freebie-details-main']}>
                        <div className={styles['fr-freebie-image-div']} style={{ backgroundColor: this.state.hide ? `${themeColors.themeColor}80` : themeColors?.freebieBgColor || "#dfe1f6", borderRadius: 10 }}>
                            <div className={styles['fr-freebie-image-desc-div']} style={{}}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", marginBottom: 10, alignItems: "center" }}>
                                        {this.state.hide ?
                                            <div style={{ display: "contents" }}>
                                                <div style={{ display: "flex", marginTop: 20, width: "95%" }}>
                                                    <div style={{ display: "flex", flex: .3, }}>
                                                        <img src={freebie.image} alt="Img" style={{ display: "flex", width: 100, height: 100, borderRadius: 100, objectFit: "cover" }} />
                                                    </div>
                                                    <div className={`${styles['title-div']}`} style={{ display: "flex", flex: .6, flexDirection: "column", justifyContent: "center" }}>
                                                        <span className={`${styles['freebie-title']} fr-freebie-title`} style={{ color: themeColors?.headingColor, fontSize: 20 }}>{freebie.name}</span>
                                                        <span className={`${styles['freebie-description']} fr-freebie-description`} style={{ color: themeColors?.headingColor }}> {freebie.description || ''}</span>
                                                    </div>
                                                </div>
                                                <img src={redeemedFreebie && redeemedFreebie.freebie.freebieItem.qrCode || ""} alt="Img" className={styles['freebie-qr-img']} />
                                            </div>
                                            :
                                            <img src={freebie.image} alt="Img" className={styles['freebie-detail-img']} />}
                                    </div>
                                </div>
                                {this.state.hide ? null :
                                    <div style={{ width: "100%", height: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{ display: "flex", flexDirection: "column", width: "95%", height: 40, display: "flex", alignItems: "end" }}>
                                            <div style={{ height: 30, backgroundColor: "rgba(0, 0, 0, 0.1)", justifyContent: "center", alignItems: "center", borderRadius: 5, display: "flex", padding: 3, paddingLeft: 8, paddingRight: 8 }}>
                                                <FontAwesomeIcon icon={faStar} color='gold' />
                                                <span style={{ paddingLeft: 3 }}>{parseFloat(freebie.worth)} Points</span>
                                            </div>
                                        </div>
                                    </div>}
                                {this.state.hide ? null :
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
                                            <span className={`${styles['freebie-title']} fr-freebie-title`} style={{ color: themeColors?.buttonBgColor, fontSize: 20 }}>{freebie.name}</span>
                                            <span className={`${styles['freebie-description']} fr-freebie-description`} style={{ color: "#000", marginTop: 5 }}> {freebie.description || ''}</span>
                                        </div>
                                    </div>}
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", marginBottom: 10, alignItems: "center" }}>
                                        {/* <button style={{ color: '#000' }} className={`${styles['fr-link-black-btn2']} `} onClick={() => onclick()}>
                                            <span className={`${styles['link-btn-label']}`}>Buy Now for {parseFloat(freebie.worth)} Points</span>
                                            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                                        </button> */}
                                        {this.state.hide ?
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderStyle: "dashed", width: "95%", borderColor: themeColors?.buttonBgColor, borderRadius: 10, borderWidth: 2, marginBottom: 10, marginTop: 20, backgroundColor: "#fff" }}>
                                                    <span style={{ paddingLeft: 10, color: themeColors?.buttonBgColor, fontWeight: "bold" }}>{redeemedFreebie && redeemedFreebie.freebie.freebieItem.couponCode}</span>
                                                    <div style={{ display: "flex", padding: 15, backgroundColor: themeColors?.buttonBgColor }}>
                                                        <span>Copy</span>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", width: "95%", marginTop: 10, marginBottom: 20 }}>
                                                    <span>This will Expires in {expiryDate} days</span>
                                                </div>
                                            </div> :
                                            parseInt(userPoints) >= parseInt(freebie.worth) ?
                                                <button onClick={() => { this.setState({ hide: true }), redeemFreebie(freebie.id) }} style={{
                                                    marginTop: 20, justifyContent: "space-between", backgroundColor: themeColors?.buttonBgColor, color: "#fff", width: "100%", borderRadius: 5
                                                }} className={`${styles['fr-redeem-btn']} fr-btn-cutom`}>
                                                    <span className={`${styles['link-btn-label']}`}>Buy Now for {parseFloat(freebie.worth)} Points</span>
                                                    <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                                                </button> :
                                                <button onClick={() => { this.setState({ hide: true }), redeemFreebie(freebie.id) }} style={{
                                                    marginTop: 20, justifyContent: "space-between", backgroundColor: "rgb(235, 235, 228)", color: "#000", width: "100%", borderRadius: 5
                                                }} className={`${styles['fr-redeem-btn']} fr-btn-cutom`} disabled={true}>
                                                    <span className={`${styles['link-btn-label']}`}>Earn {points} more points to redeem</span>
                                                    <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                                                </button>
                                        }
                                    </div>
                                </div>

                                {/* <div className={`${styles['freebie-img-content']} fr-freebie-content`} style={{ background: themeColor }}>

                                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'space-between' }}>

                                        <span className={`${styles['freebie-title']} fr-freebie-title`}>{freebie.name}</span>
                                        <span className={`${styles['freebie-description']} fr-freebie-description`}> {freebie.description || ''}</span>
                                    </div>
                                    {freebie.worth != 0 && (<div className={styles['modalProductFooter-fr']}>
                                        <div>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={icon || getImage('points_icon.png')} className="benefit-points-icon" style={{ width: 20, height: 20 }} alt="Img" />
                                                {freebie.worth}
                                            </span>
                                        </div>
                                    </div>)}
                                </div> */}
                            </div>
                            {/* {configuration && configuration.disablePoints == '1' ? null :
                                <div className={`${styles['fr-shadow-benefit-details']} ${styles['fr-benefit-status-card']}`} style={{ padding: 15 }}>
                                    {type === "loyalty" ?
                                        <div>
                                            <p className={styles['fr-sub-head-text']}>Your points</p>
                                            <div>
                                                <span className={styles['fr-info-text']}>{userPoints}</span>
                                            </div>
                                        </div> : null}

                                    {freebie.freebieItem.isLive ?
                                        <div>
                                            <p className={styles['fr-sub-head-text']}>Status</p>
                                            <div>
                                                <span className={styles['fr-info-text']} style={{ color: '#33BC00' }}>ACTIVE</span>
                                            </div>
                                        </div> : null}
                                    {freebie.freebieItem.redeemedAt ?
                                        <div>
                                            <p className={styles['fr-sub-head-text']}>Redeemed At</p>
                                            <div>
                                                <span className={styles['fr-info-text']}>{new Date(freebie.freebieItem.redeemedAt).toLocaleDateString()}</span>
                                            </div>
                                        </div> : null}

                                    {freebie.freebieItem.expiryDate ? <div>
                                        <p className={styles['fr-sub-head-text']}>Expires on</p>

                                        <div>
                                            <span className={styles['fr-info-text']}>{freebie.freebieItem.expiryDate}</span>
                                        </div>
                                    </div>
                                        : null}
                                </div>
                            } */}
                            {/* {type === "active" && freebie && freebie.freebieItem ?
                                <div className={styles['fr-shadow-benefit-details']} style={{ padding: 15 }}>
                                    <div className={`${styles['fr-qr-code-div']} ${styles['fr-column-center']} ${styles['benefitCardPoints']}`}>
                                        <img src={freebie.freebieItem.qrCode || ""} alt="Img" />
                                        <div>
                                            <p style={{ textTransform: 'uppercase' }}>Your redemption code</p>
                                            <h2>{freebie.freebieItem.couponCode}</h2>
                                        </div>
                                    </div>
                                </div> : null
                            } */}
                            {/* <div style={{ margin: '10px 0px' }}>
                                {type === "loyalty" && parseInt(userPoints) >= parseInt(freebie.worth) ?
                                    freebie.canBuy ?
                                        <button onClick={() => { this.setState({ isModalOpen: true }) }} className={`${styles['fr-redeem-btn']} fr-btn-cutom`}>Redeem Now<img src={getImage('arrow.svg')} alt="Img" /></button>
                                        : null

                                    : type === "loyalty" && parseInt(userPoints) < parseInt(freebie.worth) ?

                                        <button className={styles['fr-locked-btn']} disabled>Locked</button>

                                        : type !== "active" && type != "upgrade" ? <button className={styles['fr-locked-btn']} disabled>Expired</button> : null
                                }
                            </div> */}
                        </div>
                        <div className={styles['fr-freebie-terms-div']}>
                            {/* <div className={styles['fr-tabs']}>
                                <a className={this.state.about ? styles['is-active'] : ''} style={{ background: this.state.about ? themeColor : '#F5F5F5' }} onClick={() => { this.setState({ about: true, terms: false }) }}>About</a>
                                <a className={this.state.terms ? styles['is-active'] : ''} style={{ background: this.state.terms ? themeColor : '#F5F5F5' }} onClick={() => { this.setState({ about: false, terms: true }) }}>Terms</a>
                            </div> */}
                            <div className={styles['fr-tabs']}>

                                <a className={`${this.state.terms ? styles['is-active'] : ''} ${styles['mob']}`} style={{
                                    background: `${themeColors.themeColor}80`, width: "100%", height: 50, marginBottom: 10, color: themeColors?.buttonTextColor, display: "flex", justifyContent: "space-between"
                                }} onClick={() => { this.setState({ about: true, terms: false }) }}>
                                    <div style={{ display: "flex", marginLeft: 20 }}>
                                        <div style={{
                                            display: "flex",
                                            width: 30,
                                            height: 30,
                                            borderRadius: 40, backgroundColor: "#fff", justifyContent: "center", alignItems: "center"
                                        }}>
                                            <FontAwesomeIcon icon={faGift} color={`${themeColors.themeColor}80`} />
                                        </div>
                                        <span style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
                                            About
                                        </span>
                                    </div>

                                    <div style={{ marginRight: 15 }}>
                                        <FontAwesomeIcon style={{ marginRight: 5 }} icon={faAngleDown} />
                                    </div>
                                </a>


                                {this.state.about ?
                                    <div style={{ margin: 25 }}>
                                        <div dangerouslySetInnerHTML={{ __html: freebie.about || '' }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: freebie.instructions || '' }}></div>
                                    </div> : null}

                                <a className={this.state.terms ? styles['is-active'] : ''} style={{ background: `${themeColors.themeColor}80`, width: "100%", height: 50, color: themeColors?.buttonTextColor, display: "flex", justifyContent: "space-between" }} onClick={() => { this.setState({ about: false, terms: true }) }}>
                                    <div style={{ display: "flex", marginLeft: 20 }}>
                                        <div style={{
                                            display: "flex",
                                            width: 30,
                                            height: 30,
                                            borderRadius: 40, backgroundColor: "#fff", justifyContent: "center", alignItems: "center"
                                        }}>

                                            <FontAwesomeIcon icon={faPercent} color={`${themeColors.themeColor}80`} />
                                        </div>
                                        <span style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
                                            Terms
                                        </span>
                                    </div>

                                    <div style={{ marginRight: 15 }}>
                                        <FontAwesomeIcon style={{ marginRight: 5 }} icon={faAngleDown} />
                                    </div>

                                </a>
                            </div>


                            {this.state.about ?
                                <div style={{ margin: 25 }}>
                                    {/* <div dangerouslySetInnerHTML={{ __html: freebie.about || '' }}></div>
                                    <div dangerouslySetInnerHTML={{ __html: freebie.instructions || '' }}></div> */}
                                </div>
                                :
                                <div style={{ margin: 25 }}>
                                    {this.state.terms ?
                                        <div dangerouslySetInnerHTML={{ __html: freebie.terms || '' }}></div> : null}
                                    {/* {freebie.validDays.includes('0') ?
                                        <div>
                                            {validDays && validDays.length > 0 && validDays.length <= 7 ?
                                                <div>
                                                    <span className={styles['fr-terms-header']}>Available days</span>
                                                    <hr className={styles['fr-hr']} />
                                                    <div style={{ display: 'flex', marginTop: 10 }}>
                                                        {days.map((item, index) => {
                                                            return <div style={{ backgroundColor: validDays[index] == "1" ? "#6EB5A5" : "#ddd" }} className={styles['fr-benefit-day']} key={index} ><span>{item}</span></div>
                                                        })}
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                        :
                                        null
                                    } */}
                                    {/* {
                                        freebie.validOutlets && freebie.validOutlets.length > 0 ?
                                            <div style={{ marginTop: 30 }}>
                                                <div onClick={() => this.setState({ expand: !expand })} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                    <span className={styles['fr-terms-header']}>Valid at following outlets</span>
                                                    <button onClick={() => this.setState({ expand: !expand })} style={{ transform: expand ? 'rotate(90deg)' : 'rotate(0deg)' }} className={styles['fr-icon-btn']}><img src={getImage('arrow.svg')} alt="Img" /></button>
                                                </div>
                                                <hr className={styles['fr-hr']} />

                                                {expand ?
                                                    <div>
                                                        {freebie.validOutlets.map((item, index) => {
                                                            return <li key={index} ><span>{item}</span></li>
                                                        })}
                                                    </div> : null}
                                            </div>
                                            : null
                                    } */}

                                    {/* {
                                        freebie.maximumAmount ?
                                            <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                <span className={styles['fr-terms-header']}>Maximum discount</span>
                                                <span>{freebie.maximumAmount}</span>
                                            </div>
                                            : null
                                    } */}

                                    {/* {
                                        freebie.minimumBillAmount ?
                                            <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                <span className={styles['fr-terms-header']}>Minimum Bill Amount</span>
                                                <span>{freebie.minimumBillAmount}</span>
                                            </div>
                                            : null
                                    } */}


                                </div>
                            }
                        </div>

                        {/* <Modal
                            isOpen={this.state.isModalOpen}
                            onRequestClose={() => { this.setState({ isModalOpen: false }) }}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <img src={getImage('close.svg')} alt="Img" className={styles['fr-close-btn']} onClick={() => { this.setState({ isModalOpen: false }) }} />
                            </div>
                            <div style={{ padding: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                    <span className={styles['fr-modal-title']}>Redeem Benefit </span>
                                    <span className={styles['fr-modal-sub-title']}>{freebie.name}</span>
                                </div>

                                {redeemedFreebie && redeemedFreebie.success ?
                                    <div>
                                        <span className={styles['fr-modal-sub-title']}>Please find the coupon code below or you can check it in the benefits section.</span>
                                        <div className={`${styles['fr-qr-code-div']} ${styles['fr-column-center']} ${styles['benefitCardPoints']}`}>
                                            <img src={redeemedFreebie && redeemedFreebie.freebie.freebieItem.qrCode || ""} alt="Img" className={styles['w-100']} />
                                            <div>
                                                <p style={{ textTransform: 'uppercase' }}>Your redemption code</p>
                                                <h2>{redeemedFreebie && redeemedFreebie.freebie.freebieItem.couponCode}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    <div>
                                        <span style={{ fontWeight: '600' }} className={styles['fr-modal-sub-title']}>Do you want to redeem it now?</span>
                                        <button onClick={() => redeemFreebie(freebie.id)} style={{ marginTop: 20 }} className={`${styles['fr-redeem-btn']} fr-btn-cutom`}>Redeem Now<img src={getImage('arrow.svg')} alt="Img" /></button>
                                    </div>

                                }
                            </div>
                        </Modal> */}

                    </div>

                    : null
                }
            </div>
        )
    }
}
