
const initialState = {
  path: '/',

  /* TEST-DEV */
  // path: '/gift-details',
  // path: '/gift-summary',
  // path: '/order-history',

  params: {
    id: 3,
    theme: 'https://picsum.photos/id/10/2500/1667'
  }
};

const SET_PATH = "navigation/SET_PATH";

export const pushPath = (path, params) => async (dispatch, getState) => {
  dispatch({
    type: SET_PATH,
    path,
    params
  })
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATH: {
      return {
        ...state,
        path: action.path,
        prevPath: action.prevPath,
        params: action.params,
      }
    }
    default: return state;
  }
}
export default navigationReducer;