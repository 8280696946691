
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pushPath } from '../reducers/navigation/navigation'
import { getThirdPartyRewards, getLoyaltyConfig } from '../reducers/loyalty/loyalty';
import VouchersList from '../components/VouchersPage';

class Vouchers extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getThirdPartyRewards();
        this.props.getLoyaltyConfig();
    }

    render() {
        const { thirdPartyRewards, pushPath } = this.props;
        const { currency } = this.props.loyalty;

        return (
            <div style={{}}>
                <VouchersList pushPath={pushPath} vouchers={thirdPartyRewards?.rewards} currency={currency} />
            </div>
        );
    }
}

const mapState = (state, ownprops) => {
    return {
        thirdPartyRewards: state.loyalty.thirdPartyRewards,
        loyalty: state.loyalty
    };
}

const mapDispatch = {
    pushPath,
    getThirdPartyRewards,
    getLoyaltyConfig
}

export default connect(mapState, mapDispatch)(Vouchers);
