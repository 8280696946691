import React from 'react';
import styles from '../styles/referral.css'

const RewardBalanceCardPage = ({ config, loyaltyInfo }) => {

    const { list, bgColor } = config || {} ;
    const { points, pointsRedeemed } = loyaltyInfo || {points:'-', pointsRedeemed:'-'};

    return (
        <div className={`${styles['RewardBalanceCardPage']}`} style={{ backgroundColor: bgColor || '#EAEFFF', padding: 20, display: 'flex', justifyContent: 'space-around', height: 150, borderRadius: 6,alignItems:'center' }}>
            {list ?
                list.map((i, index) => {
                    return (
                        <PointsItem count={loyaltyInfo[i.key]} title={i.title} key={index} />
                    )
                }) :
                <>
                    <PointsItem count={points} title={'BALANCE CASH POINTS'} />
                    <PointsItem count={pointsRedeemed} title={'REDEEMED CASH POINTS'} />
                </>
            }

        </div>
    )
}

const PointsItem = ({ count, title }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <h1 className={`${styles['pointscount']}`} style={{ fontSize: 34, fontWeight: 'normal',color:"white" }}>{count}</h1>
            <span className={`${styles['pointstitle']}`} style={{ fontSize: 16, fontWeight: 'normal', letterSpacing: 1.6, textTransform: 'uppercase',color:"white" }}>{title}</span>
        </div>
    )
}

export default RewardBalanceCardPage;
