import React, { Component, useEffect, useState } from 'react';
import getImage from '../images';
import { Liquid } from 'liquidjs';
import styles from '../styles/referral.css'

const engine = new Liquid();

class ShareYourCodePage extends Component {

    constructor() {
        super();
        this.state = { shareContent: "",
        linkCopied: false
       };
    }


    componentWillReceiveProps() {

        const { shareCodeContent, userInfo } = this.props;
        const { shareUrl, shareMessage } = shareCodeContent || {};
        const userCode = userInfo?.referralCode || ""
        if (shareCodeContent) {
            const tpl = engine.parse(shareMessage);
            engine.render(tpl, { code: userCode, url: shareUrl + userCode }).then(t => this.setState({ shareContent: t }));
        }

    }

    render() {

        const { shareCodeContent, userInfo } = this.props;
        const userCode = userInfo?.referralCode || ""

        const { shareUrl, backgroundColor, fbAppId, icon, shareMessage, shareTitle, subTitle } = shareCodeContent;

        const facebookShareLink = () => {
            const shareUrl1 = shareUrl;
            const currentUri = window.location.href;
            return `https://www.facebook.com/dialog/share?app_id=${fbAppId}&display=popup&href=${shareUrl1}&redirect_uri=${currentUri}`;
        }
        const whatsappShareLink = () => {
            const text = this.state.shareContent;
            return `https://api.whatsapp.com/send?text=${text}`;
        }

        const twitterShareLink = () => {
            const text = this.state.shareContent;
            return `https://twitter.com/intent/tweet?text=${text}`;
        }

        const emailShareLink = () => {
            const text = this.state.shareContent;
            const subject = shareTitle;
            if (navigator.share) {
              navigator.share({
                title: subject,
                text: text,
                // url:shareUrl + userInfo.referralCode,
              })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
            }else{
            return `mailto:?body=${text}&subject=${subject}`;
            }
        }
        const instagramShareLink = () => {
          const shareUrl = shareUrl;
          const currentUri = window.location.href;
          return `https://www.instagram.com/send?app_id=${fbAppId}&display=popup&href=${shareUrl}&redirect_uri=${currentUri}`;
      }
      const messengerShareLink = () => {
        const shareUrl1 = shareUrl;
        const currentUri = window.location.href;
        return `http://www.facebook.com/dialog/send?app_id=${fbAppId};link=${shareUrl1}&amp;redirect_uri=${currentUri}`;
    }

        const copyclipboard = () => {
            navigator.clipboard.writeText(shareUrl + userCode);
            this.setState({ linkCopied: true });

            setTimeout(() => {
                this.setState({ linkCopied: false });
            }, 3000);
        }

        return (

            <div className={`${styles["referral-main"]}`} style={{ background: backgroundColor }}>
                <div className={`${styles['subdiv-one']}`}>
                    <div dangerouslySetInnerHTML={{ __html: subTitle }} />
                    <div className={`${styles["copyclipboard"]}`} style={{ display: "flex", flexDirection: 'row', marginTop: "20px" }}>
                        <div className={`${styles["button-div"]}`}>
                            <span style={{ color: "#283E86" }} className={`${styles["font-blue"]} referralCode`}>{shareUrl + userCode}</span>
                        </div>
                        <div>
                            <button onClick={() => { copyclipboard(); }} className={`${styles["copy-button"]}`}>
                                {this.state.linkCopied ? 'Copied' : 'Copy Link'}
                            </button>
                        </div>
                    </div>

                    <div className={styles["share-links-box"]} style={{ marginTop: 20 }}>
                        <span className={styles["share-via"]} style={{ fontSize: 14, fontWeight: '900', marginBottom: 20 }}>Share Via </span>
                        <div className={`${styles["social-icons-block"]} share-icons-block`}>
                        <div className={styles["facebook-twitter-block"]}>

                                <div className={styles["whatsapp-icon"]}>
                                    <a target="blank" href={whatsappShareLink()}>
                                        <img className={styles["Social-icon"]} style={{width:40,height:40}} src={getImage("whatsapp.png")} />
                                    </a>
                                </div>

                                <div className={styles["messenger-icon"]}>
                                  <a target="blank" href={messengerShareLink()}>
                                      <img className={styles["Social-icon"]} style={{width:40,height:40}} src={getImage("iconsmessenger.png")} />
                                  </a>
                              </div>
                                <div className={styles["instagram-icon"]}>

                                    <a target="blank" href={instagramShareLink()}>
                                        <img className={styles["Social-icon"]} style={{width:45,height:45}} src={getImage("iconsinstagram.png")} />
                                    </a>
                                </div>

                                <div className={styles["facebook-icon"]}>
                                    <a target="blank" href={facebookShareLink()}>
                                        <img className={styles["Social-icon"]} style={{width:40,height:40}} src={getImage("facebook.png")} />
                                    </a>
                                </div>

                                <div className={styles["twitter-icon"]}>
                                    <a target="blank" href={twitterShareLink()}>
                                        <img className={styles["Social-icon"]} style={{width:40,height:40}} src={getImage("iconstwitterx.png")} />
                                    </a>
                                </div>

                                <div className={styles["upload-icon"]}>
                                  <a onClick={emailShareLink}>
                                    <img className={`${styles["Social-icon"]} ${styles["email-icon"]}`} style={{ width: 40, height: 40 }} src={getImage("email.png")} alt="Email Icon" />
                                    <img className={`${styles["Social-icon"]} ${styles["share-icon"]}`} style={{ width: 40, height: 40 }} src={getImage("shareimage.png")} alt="Share Icon" />
                                  </a>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles["subdiv-two"]}>
                    <img src={icon} className={styles["share-family-section-image"]} />
                </div>
            </div>
        )
    }
}

export default ShareYourCodePage;
