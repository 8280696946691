import React from 'react'
import styles from '../../../styles/gifting.css';
import { useSelector } from 'react-redux';
import getImage from "../../../images";

function OrderSuccess(props) {
  const placeOrder = useSelector(state => state.placeOrder)

  return (
    <div style={{ display: 'flex', height: '90vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ alignSelf: 'center', marginBottom: 30 }} className="modalErrors worng">
        <img src={getImage('successIcon.svg')} alt="Success" />
      </div>
      <span className={styles['fr-modal-title']}>Payment Successful! </span>
      <span className={styles['fr-modal-sub-title']}>
        Transaction ID: {placeOrder.transactionStatus?.transactionReference}
      </span>
      <span className={styles['fr-modal-sub-title']}>{placeOrder.transactionStatus?.message}</span>
    </div>
  )
}


export default OrderSuccess
