import React, { Component } from 'react'
import styles from '../styles/loyalty.css';

export default class CustomDatePicker1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date: null
        }
    }


    render() {
        const { item, value, onChange } = this.props;
        return (

            <div className={styles["date-custom-field-temp1"]}>
                <div className={styles["date-custome-field-inner-temp1"]}>
                    <span className={styles["date-custom-field-label-temp1"]}>
                        {item.label}{item.required ? <span style={{ color: 'red' }}> *</span> : null}
                    </span>
                    <input onChange={(e) => {
                        onChange(e.target.value);
                        this.setState({ date: e.target.value });
                    }} type={'date'} id="date-picker" className={styles['date-custom-date-field-temp1']}
                        value={this.state.date || value || `Please select your ${item && item.label}`} />
                </div>
            </div>

        )
    }

}


