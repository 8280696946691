
import React, { Component } from 'react';
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faStar, faGift, faPercent, faAngleDown } from '@fortawesome/free-solid-svg-icons'

class VoucherAboutAndTermsPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    constructor(props) {
        super(props)
        this.state = {
            terms: false,
            about: true,
        }
    }

    render() {
        const { aboutVoucher, termsVoucher, themeColors } = this.props;
        return (
            <div style={{ margin: 10 }}>
                <div className={styles['fr-tabs']}>
                    <a className={`${this.state.terms ? styles['is-active'] : ''} ${styles['mob']}`} style={{
                        background: themeColors.themeColor, width: "100%", height: 50, marginBottom: 10, color: themeColors?.buttonTextColor, display: "flex", justifyContent: "space-between"
                    }} onClick={() => { this.state.about ? this.setState({ about: false }) : this.setState({ about: true }) }}>
                        <div style={{ display: "flex", marginLeft: 20 }}>
                            <div style={{
                                display: "flex",
                                width: 30,
                                height: 30,
                                borderRadius: 40, backgroundColor: "#fff", justifyContent: "center", alignItems: "center"
                            }}>
                                <FontAwesomeIcon icon={faGift} color={themeColors.themeColor} />
                            </div>
                            <span style={{ display: "flex", alignItems: "center", marginLeft: 10, color: "#fff" }}>
                                About
                            </span>
                        </div>
                        <div style={{ marginRight: 15 }}>
                            <FontAwesomeIcon style={{ marginRight: 5, color: "#fff" }} icon={faAngleDown} />
                        </div>
                    </a>
                    {this.state.about ?
                        <div style={{ margin: 25 }}>
                            <div dangerouslySetInnerHTML={{ __html: aboutVoucher || '' }}></div>
                        </div> : null}

                    <a className={this.state.terms ? styles['is-active'] : ''} style={{ background: themeColors.themeColor, width: "100%", height: 50, color: themeColors?.buttonTextColor, display: "flex", justifyContent: "space-between" }} onClick={() => { this.state.terms ? this.setState({ terms: false }) : this.setState({ terms: true }) }}>
                        <div style={{ display: "flex", marginLeft: 20 }}>
                            <div style={{
                                display: "flex",
                                width: 30,
                                height: 30,
                                borderRadius: 40, backgroundColor: "#fff", justifyContent: "center", alignItems: "center"
                            }}>

                                <FontAwesomeIcon icon={faPercent} color={themeColors.themeColor} />
                            </div>
                            <span style={{ display: "flex", alignItems: "center", marginLeft: 10, color: "#fff" }}>
                                Terms
                            </span>
                        </div>

                        <div style={{ marginRight: 15 }}>
                            <FontAwesomeIcon style={{ marginRight: 5, color: "#fff" }} icon={faAngleDown} />
                        </div>

                    </a>
                </div>


                <div style={{ margin: 25 }}>
                    {this.state.terms &&
                        <div dangerouslySetInnerHTML={{ __html: termsVoucher || '' }}></div>
                    }
                </div>

            </div>
        );
    }
}

export default VoucherAboutAndTermsPage;
