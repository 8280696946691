import session from './session/session';
import referral from './referral/referral';
import pointshistory from './pointshistory/pointshistory';

const referralReducer = {
    session,
    referral,
    pointshistory
}


export default referralReducer;
