// import React, { useState, useEffect } from 'react';
import styles from '../styles/loyalty.css';
import getImage from "../images";

// const DateSelect = ({ item, onChange }) => {
// const [date, setDate] = useState()
// const months = [
//     { month: 'January', Length: 31 },
//     { month: 'February', Length: 29 },
//     { month: 'March', Length: 31 },
//     { month: 'April', Length: 30 },
//     { month: 'May', Length: 31 },
//     { month: 'June', Length: 30 },
//     { month: 'July', Length: 31 },
//     { month: 'August', Length: 31 },
//     { month: 'September', Length: 30 },
//     { month: 'October', Length: 31 },
//     { month: 'November', Length: 30 },
//     { month: 'December', Length: 31 }
// ]
// const [selectedMonth, setSelectedMonth] = useState()
// const [selectedYear, setSelectedYear] = useState("0000")
// const [selectedDate, setSelectedDate] = useState()
// const [monthLength, setMonthLength] = useState(31)
// const [year, setYear] = useState()

// useEffect(() => {
//     const getDate = []
//     for (var i = 1; i <= monthLength; i++) {
//         getDate.push(i)
//     }
//     const getYear = []
//     for (var i = new Date().getFullYear(); i >= 1950; i--) {
//         getYear.push(i)
//     }
//     setDate(getDate)
//     setYear(getYear)
//     sendData()
// }, [monthLength, selectedDate, selectedYear, selectedMonth]);

//     function sendData() {
//         let month = `${selectedMonth}`
//         let date = `${selectedDate}`
//         if (date.length == 1) {
//             date = 0 + date
//         }
//         if (month.length == 1) {
//             month = 0 + month
//         }

//         if (date != `` && month != '13' && month != `` && month != "undefined" && date != "undefined") {
//             onChange(`${selectedYear}-${month}-${date}`)
//         }
//         else if (month == 13) {
//             setMonthLength(31)
//         }
//     }

//     function checkLeapYear(year) {
//         if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
//             setMonthLength(29)
//             months[1].Length = 29
//         } else {
//             setMonthLength(28)
//             months[1].Length = 28
//         }
//         setSelectedYear(year)
//     }
//     return (
//         <div> <div className={styles["fr-custom-field-label"]} style={{ textAlign: 'start' }} >{item.label}</div>
//             <div className={styles["fr-date-container"]} >
//                 <div className={styles["fr-custom-field"]} style={{ flex: 0.4 }}>
//                     <div className={styles["fr-custome-field-inner"]}>
//                         <span className={styles["fr-custom-field-label"]}>
//                             Day{item.required ? <span style={{ color: 'red' }}>*</span> : null}
//                         </span>
//                         <select className={styles["fr-custom-select"]} onChange={(e) => { setSelectedDate(e.target.value) }} id="select" required>
//                             {date?.map((i) => {
//                                 return <option value={i}> {i}</option>
//                             })}
//                             <option value="" selected> {item.placeholder || `Please select day`}</option>
//                         </select>
//                     </div>
//                     <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
//                 </div>
//                 <div className={styles["fr-custom-field"]} style={{ flex: 0.4 }}>
//                     <div className={styles["fr-custome-field-inner"]}>
//                         <span className={styles["fr-custom-field-label"]}>
//                             Month{item.required ? <span style={{ color: 'red' }}>*</span> : null}
//                         </span>
//                         <select className={styles["fr-custom-select"]} onChange={(e) => {
//                             setSelectedMonth(e.target.selectedIndex + 1)
//                             setMonthLength(e.target.value)
//                         }} id="select" required>
//                             {months?.map((i) => {
//                                 return <option value={i.Length}> {i.month}</option>
//                             })}
//                             <option value="" selected> {item.placeholder || `Please select month`}</option>
//                         </select>
//                     </div>
//                     <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
//                 </div>
//                 <div className={styles["fr-custom-field"]} style={{ flex: 0.4 }}>
//                     <div className={styles["fr-custome-field-inner"]}>
//                         <span className={styles["fr-custom-field-label"]}>
//                             Year
//                         </span>
//                         <select className={styles["fr-custom-select"]} onChange={(e) => { checkLeapYear(e.target.value) }} id="select" required>
//                             {year?.map((i) => {
//                                 return <option value={i}> {i}</option>
//                             })}
//                             <option value="" selected> {item.placeholder || `Please select year`}</option>
//                         </select>
//                     </div>
//                     <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
//                 </div>
//             </div></div>
//     )
// }


// export default DateSelect

import React, { Component } from 'react'

export default class DateSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            selectedMonth: null,
            selectedYear: '0000',
            selectedDate: null,
            monthLength: 31,
            year: null,
            months: [
                { month: 'January', Length: 31 },
                { month: 'February', Length: 29 },
                { month: 'March', Length: 31 },
                { month: 'April', Length: 30 },
                { month: 'May', Length: 31 },
                { month: 'June', Length: 30 },
                { month: 'July', Length: 31 },
                { month: 'August', Length: 31 },
                { month: 'September', Length: 30 },
                { month: 'October', Length: 31 },
                { month: 'November', Length: 30 },
                { month: 'December', Length: 31 }
            ]
        };
    }
    componentDidMount() {
        const getDate = []
        for (var i = 1; i <= this.state.monthLength; i++) {
            getDate.push(i)
        }
        const getYear = []
        for (var i = new Date().getFullYear(); i >= 1950; i--) {
            getYear.push(i)
        }
        this.setState({ date: getDate })
        this.setState({ year: getYear })
    }



    render() {
        function sendData(data) {
            const getDate = []
            const getYear = []
            for (var i = 1; i <= data.state.monthLength; i++) {
                getDate.push(i)
            }
            for (var i = new Date().getFullYear(); i >= 1950; i--) {
                getYear.push(i)
            }
            let month = `${data.state.selectedMonth}`
            let date = `${data.state.selectedDate}`
            data.setState({ date: getDate })
            data.setState({ year: getYear })
            if (!getDate.includes(parseInt(data.state.selectedDate))) {
                data.setState({ selectedDate: null })
            }
            if (date.length == 1) {
                date = 0 + date
            }
            if (month.length == 1) {
                month = 0 + month
            }
            if (date != `` && month != '13' && month != `` && month != "undefined" && date != "undefined") {
                data.props.onChange(`${data.state.selectedYear}-${data.state.selectedMonth}-${data.state.selectedDate}`)
            }
            else if (month == 13) {
                data.setState({ monthLength: 31 })
            }
        }

        function checkLeapYear(year, data) {
            if (year == "") {
                data.setState({ selectedYear: '0000' })
            }
            else {
                if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
                    data.setState({ monthLength: 29 })
                    data.state.months[1].Length = 29
                } else {
                    data.setState({ monthLength: 28 })
                    data.state.months[1].Length = 28
                }
                data.setState({ selectedYear: year })
            }
        }

        return (
            // <div> <div className={styles["fr-custom-field-label"]} style={{ textAlign: 'start' }} >{this.props.item.label}</div>
            //     <div className={styles["fr-date-container"]} >
            //         <div className={styles["fr-custom-field"]} style={{ flex: 0.4 }}>
            //             <div className={styles["fr-custome-field-inner"]}>
            //                 <span className={styles["fr-custom-field-label"]}>
            //                     Day{this.props.item.required ? <span style={{ color: 'red' }}>*</span> : null}
            //                 </span>
            //                 <select className={styles["fr-custom-select"]} onChange={(e) => {
            //                     sendData(this)
            //                     this.setState({ selectedDate: e.target.value })
            //                 }} id="select" required>
            //                     {this.state.date?.map((i) => {
            //                         return <option value={i}> {i}</option>
            //                     })}
            //                     {/* <option value="1"> 1</option> */}
            //                     <option value="" selected> {this.props.item.placeholder || `Please select day`}</option>
            //                 </select>
            //             </div>
            //             <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
            //         </div>
            //         <div className={styles["fr-custom-field"]} style={{ flex: 0.4 }}>
            //             <div className={styles["fr-custome-field-inner"]}>
            //                 <span className={styles["fr-custom-field-label"]}>
            //                     Month{this.props.item.required ? <span style={{ color: 'red' }}>*</span> : null}
            //                 </span>
            //                 <select className={styles["fr-custom-select"]} onChange={async (e) => {
            //                     await this.setState({ selectedMonth: e.target.selectedIndex + 1 })
            //                     await this.setState({ monthLength: e.target.value })
            //                     sendData(this)
            //                 }} id="select" required>
            //                     {this.state.months?.map((i) => {
            //                         return <option value={i.Length}> {i.month}</option>
            //                     })}
            //                     <option value="" selected> {this.props.item.placeholder || `Please select month`}</option>
            //                 </select>
            //             </div>
            //             <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
            //         </div>
            //         <div className={styles["fr-custom-field"]} style={{ flex: 0.4 }}>
            //             <div className={styles["fr-custome-field-inner"]}>
            //                 <span className={styles["fr-custom-field-label"]}>
            //                     Year
            //                 </span>
            //                 <select className={styles["fr-custom-select"]} onChange={async (e) => {
            //                     checkLeapYear(e.target.value, this)
            //                     await sendData(this)
            //                 }} id="select" required>
            //                     {this.state.year?.map((i) => {
            //                         return <option value={i}> {i}</option>
            //                     })}
            //                     <option value="" selected> {this.props.item.placeholder || `Please select year`}</option>
            //                 </select>
            //             </div>
            //             <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
            //         </div>
            //     </div></div>


            <div style={{ display: "flex", justifyContent: "center" }}> <div className={styles["fr-custom-field-label"]} style={{ textAlign: 'start' }} >{this.props.item.label}</div>
                <div className={styles["fr-date-container"]} >
                    <div className={styles["fr-custom-field-Temp1"]} style={{ flex: 0.4 }}>
                        <div className={styles["fr-custome-field-inner-Temp1"]}>
                            <span className={styles["fr-custom-field-label"]}>
                                Day{this.props.item.required ? <span style={{ color: 'red' }}>*</span> : null}
                            </span>
                            <select className={styles["fr-custom-select"]} onChange={async (e) => {
                                await this.setState({ selectedDate: e.target.value })
                                await sendData(this)
                            }} id="select" required>
                                <option value="" selected> {this.props.item.placeholder || `Please select day`}</option>
                                {this.state.date?.map((i) => {
                                    return <option value={i}> {i}</option>
                                })}
                                {/* <option value="1"> 1</option> */}
                            </select>
                        </div>
                        <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
                    </div>
                    <div className={styles["fr-custom-field-Temp1"]} style={{ flex: 0.4 }}>
                        <div className={styles["fr-custome-field-inner-Temp1"]}>
                            <span className={styles["fr-custom-field-label"]}>
                                Month{this.props.item.required ? <span style={{ color: 'red' }}>*</span> : null}
                            </span>
                            <select className={styles["fr-custom-select"]} onChange={async (e) => {
                                await this.setState({ selectedMonth: e.target.selectedIndex })
                                await this.setState({ monthLength: e.target.value })
                                sendData(this)
                            }} id="select" required>
                                <option value='31' selected> {this.props.item.placeholder || `Please select month`}</option>
                                {this.state.months?.map((i) => {
                                    return <option value={i.Length}> {i.month}</option>
                                })}
                            </select>
                        </div>
                        <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
                    </div>
                    <div className={styles["fr-custom-field-Temp1"]} style={{ flex: 0.4 }}>
                        <div className={styles["fr-custome-field-inner-temp1"]}>
                            <span className={styles["fr-custom-field-label"]}>
                                Year
                            </span>
                            <select className={styles["fr-custom-select"]} onChange={async (e) => {
                                await checkLeapYear(e.target.value, this)
                                await sendData(this)
                            }} id="select" required>
                                <option value="" selected> {this.props.item.placeholder || `Please select year`}</option>
                                {this.state.year?.map((i) => {
                                    return <option value={i}> {i}</option>
                                })}
                            </select>
                        </div>
                        <img alt="Img" src={getImage('select_arrow.svg')} onClick={() => document.getElementById('select').click()} />
                    </div>
                </div></div>
        )
    }
}
