import React, { Component } from 'react';
import MultiSelectDropdown from 'multiselect-react-dropdown';
import styles from '../styles/loyalty.css';

class MultiSelectItem extends Component {
    render() {
        const MultiSelect = MultiSelectDropdown.default || MultiSelectDropdown;
        const { options, placeholder, themeColors, setSelectedItems } = this.props;

        const choiceOptions = options.map((option) => ({
            id: option.id,
            name: option.label,
        }));
        const bgColor = themeColors.themeColor

        return (
            <div className={styles["filter-dropdown-item"]}>
                <MultiSelect
                    options={choiceOptions}
                    selectedValues={setSelectedItems}
                    onSelect={(selectedList, selectedItem) => {
                        this.props.setSelectedItem(selectedItem.id);
                    }}
                    onRemove={(selectedList, removedItem) => {
                        this.props.removeSelectedItem(removedItem.id);
                    }}
                    displayValue="name"
                    placeholder={placeholder}
                    showArrow={true}
                    showCheckbox={true}
                    showSelectedItems={false}
                    style={{
                        chips: {
                            marginBottom: 0,
                            fontSize: 14,
                            lineHeight: "0px",
                            paddingTop: 3,
                            paddingBottom: 4,
                            paddingRight: 4,
                            paddingLeft: 5,
                            background: bgColor,
                            color: "#fff",
                            marginTop: 2
                        },
                        searchBox: { padding: 3 },
                        option: {
                            color: "#000",
                            background: "none",
                            display: "flex"

                        },
                        inputField: {
                            margin: 4
                        },
                        checkbox: {
                            marginRight: 10
                        }
                    }}
                />
            </div>
        );
    }
}

export default MultiSelectItem;
