import React from 'react';
import styles from '../styles/referral.css';

const HistoryPointsBalancePage = ({ loyaltyInfo, userInfo }) => {

    if (!loyaltyInfo) {
        return null;
    }

    const { successReferralCount } = userInfo || {successReferralCount:'-'};
    const { pointsEarned, pointsRedeemed, points } = loyaltyInfo || {pointsEarned:'-', pointsRedeemed:'-', points:'-'};

    return (
        <div style={{ background: 'linear-gradient(to left, #283E86 , #283E86)',display: 'flex',flexDirection:'column',borderRadius: 6,padding: 20, }}>
          <h2 style={{textAlign: 'center',color:'white'}}>Your Referral Summary</h2>
          <div className={`${styles["referral-Points-card"]}`} style={{ padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-around', }}>
            <PointsItem count={successReferralCount} title={'Total Referral'} />
            <PointsItem count={pointsEarned} title={'Cash Points Earned'} />
            <PointsItem count={pointsRedeemed} title={'Redeemed Cash Points'} />
            <PointsItem count={points} title={'Balance Cash Points'} />
            </div>
        </div>
    )
}

const PointsItem = ({ count, title }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
            <span style={{ fontSize: 34, fontWeight: 'normal',color:'white' }}>{count}</span>
            <span className={`${styles["referral-PointsItem"]}`}  style={{ fontSize: 16, fontWeight: 'normal', letterSpacing: 1.6, textTransform: 'uppercase',color:'white' }}>{title}</span>
        </div>
    )
}

export default HistoryPointsBalancePage;
