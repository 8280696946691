import React from "react";
import styles from '../styles/referral.css'

const CustomCard = ({ buttonLink, buttonText, title }) => {

    if (!title) {
        return null;
    }

    return (
        <div className={`${styles["redeem-div"]} fr-custom-card`}>
            <div className={`${styles["div-claim"]}`}>
                <span style={{ fontWeight: "400", fontSize:22,color:'white',marginBottom:10 }} className={`${styles["font-claim"]}`}>{title}</span>
                <a href={buttonLink} className={`${styles["button-claim"]}`}>{buttonText}</a>
            </div>
        </div>
    )

}

export default CustomCard;
