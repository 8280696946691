import React, { useState } from 'react'
import Modal from 'react-modal';
import styles from '../../styles/gifting.css';
import getImage from '../../images'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '5px',
    // height: '90vh',
    alignItems: 'center',
    overflow: 'hidden'
  },
  overlay: {
    background: '#000000e0'
  }
};
function MessageModal(props) {
  const { messageModalStatus, handleMessageModal } = props || {}

  const handleClick = () => {
    handleMessageModal({
      status: false,
      message: ''
    })
  }

  return (
    <div>
      <Modal
        isOpen={messageModalStatus?.status}
        onRequestClose={() => handleClick(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <img src={getImage('close.svg')} alt="Img" className={styles['fr-close-btn']} onClick={() => handleClick(false)} />
        </div>
        <div style={{ padding: 20 }}>
          <div style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'center', justifyContent: 'space-between', minHeight: 70, marginBottom: 20
          }}>
            <span className={styles['fr-modal-title']}>Something went wrong.</span>
            <span className={styles['fr-modal-sub-title']}>{messageModalStatus.message}</span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MessageModal