import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Heading from '../common/Heading';
import ConversionCard from '../components/ConversionCard';
import KnowMoreCard from '../components/KnowMoreCard';
import OverAllProgressCard from '../components/OverAllProgressCard';
import {
    getLoyaltyPageConfig,
    getloyaltyPageLayout
} from '../reducers/theme/theme';
import ColumnLayout from './ColumnLayout';
import CurrentCard from './CurrentCard';
import RowLayout from './RowLayout';
import styles from '../styles/loyalty.css';
import EarningRateCard from '../components/EarningRateCard';
import TierProgressCard from '../components/TierProgressCard';
import BenefitList from '../components/BenefitList';
import ExclusiveBenefitList from '../components/ExclusiveBenefitList';
import LightCurrentCard from './LightCurrentCard';
import GiftCardHistory from './GiftCardHistory';
import Vouchers from './Vouchers';


const DashboardDynamicLayout = (props) => {

    // useEffect(() => {
    //     props.getLoyaltyPageConfig();
    //     props.getloyaltyPageLayout();
    // }, []);

    const { loyaltyLayoutConfig, loyaltyLayoutContent, themeColors, termsUrl } = props;
    const { layoutDirection } = loyaltyLayoutConfig && loyaltyLayoutConfig || {};
    const { themeColor, buttonTextColor, buttonBgColor } = themeColors || {};

    var sectionList = [];

    loyaltyLayoutContent?.forEach((obj) => {
        if (sectionList.indexOf(obj.section) == -1) {
            sectionList.push(obj.section);
        }
    });

    const direction = layoutDirection && layoutDirection || "row"

    var componentsMap = (item) => {
        switch (item.component) {
            case 'LoyaltyCard': return <CurrentCard />
            case 'Vouchers': return <Vouchers />
            case 'LightCurrentCard': return <LightCurrentCard />
            case 'KnowMoreCard': return <KnowMoreCard />
            case 'ProgressCard': return <TierProgressCard />
            case 'OverallProgressCard': return <OverAllProgressCard />
            case 'EarningCard': return <EarningRateCard />
            case 'ConversionCard': return <ConversionCard />
            case 'ContentBox': return <ContentBox />
            case 'HeadTitle': return <Heading {...JSON.parse(item?.config)} />
            case 'ViewTerms': return <div style={{ display: 'flex', justifyContent: 'flex-end' }}><a href={termsUrl || ''} target="_blank" className={styles['fr-terms-btn']} style={{ background: buttonBgColor || '#000', color: buttonTextColor || '#fff' }}>VIEW T&C</a></div>
            case 'BenefitList': return <BenefitList {...JSON.parse(item?.config || "{}")} />
            case 'ExclusiveBenefitList': return <ExclusiveBenefitList {...JSON.parse(item?.config)} />
            case 'GiftCardHistory': return <GiftCardHistory />
        }
    }

    return (
        direction && direction == 'row' ?
            <RowLayout
                sectionList={sectionList || []}
                components={loyaltyLayoutContent}
                componentsMap={componentsMap}
            />
            :
            <ColumnLayout
                sectionList={sectionList || []}
                components={loyaltyLayoutContent}
                componentsMap={componentsMap}
            />
    )
}

const mapState = (state, ownprops) => {
    const { themeColors, redirectUrls, loyaltyLayoutConfig, loyaltyLayoutContent } = state.theme;
    return {
        loyaltyLayoutConfig,
        loyaltyLayoutContent,
        themeColors,
        termsUrl: redirectUrls && redirectUrls.termsUrls || {}
    };
}

const mapDispatch = {
    getLoyaltyPageConfig,
    getloyaltyPageLayout
}

export default connect(mapState, mapDispatch)(DashboardDynamicLayout);