import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/loyalty.css';
import { setSelectedVoucher } from '../reducers/loyalty/loyalty';

class VoucherItem extends Component {

    constructor(props) {
        super(props)
        this.state = {
            denomination: null,
            minInputvalue: {
                minBoolean: false,
            },
            errorValue: {
                error: false,
            },
        }
    }

    handleInputChange = (e) => {
        const inputValue = e.target.value;
        const { minValue, maxValue } = this.props.item;
        const parsedValue = parseFloat(inputValue);

        if ((!isNaN(parsedValue) && parsedValue <= maxValue)) {

            this.setState({ denomination: inputValue });
            this.setState({ minBoolean: false })
        }
        if (inputValue.length == 0) {
            this.setState({ denomination: '' });
            this.setState({ minBoolean: false })
        }
        if (parsedValue <= minValue) {
            this.setState({ minBoolean: parsedValue <= minValue });
        }
        if ((!isNaN(parsedValue) && parsedValue == maxValue)) {
            this.setState({ denomination: inputValue });
            this.setState({ minBoolean: true })
        }
        if ((!isNaN(parsedValue) && parsedValue >= minValue)) {

            this.setState({ denomination: inputValue });
            this.setState({ minBoolean: false })
        }
        if ((!isNaN(parsedValue) && parsedValue > maxValue)) {
            this.setState({ denomination: inputValue });
            this.setState({ minBoolean: true })
        }

    }

    render() {
        const { item, onclick, themeColors, setSelectedVoucher, denomination } = this.props;
        const color = '#FFF';
        const type = item?.denominationType
        const denominations = item.denominationValues.length > 0 ? item.denominationValues.split(',') : [];
        const min = this.state.minBoolean;
        const errorShow = this.state.error
        const minValue = item.minValue
        const maxValue = item.maxValue




        return (
            <div style={{ flex: 1 }}>
                <div style={{
                    marginTop: 10,
                    marginBottom: 15,
                    backgroundColor: "#dfe1f6",
                    padding: 15,
                    display: "flex", borderRadius: 6,
                    flexDirection: "column"
                }}>
                    <img src={item.image} alt="Img" style={{ width: "100%", height: 150, objectFit: "cover", borderRadius: 6, margin: 10, alignSelf: "center" }} />
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ color: themeColors?.themeColor, marginTop: 10, fontSize: 15 }}>{item.productName.length > 30 ? `${item.productName.slice(0, 30)}...` : item.productName}</span>
                            <div className={styles['voucher-description']} dangerouslySetInnerHTML={{ __html: item.description || '' }} />
                            <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 12, marginRight: 12, marginTop: 10, gap: 10, width: "100%" }}>
                                <div style={{ flex: 0.5, display: "flex", flexDirection: "column" }}>
                                    <span style={{ color: "#9ea5ce", marginBottom: 5 }}>Order Value</span>
                                    <div style={{ width: "100%", height: 36, borderRadius: 5, display: "flex", alignItems: "center" }}>
                                        {type === 'fixed_denomination' && denominations.length > 0 ?
                                            <select onChange={(e) => { this.setState({ denomination: e.target.value }) }} style={{ width: "100%", height: 36, backgroundColor: "#fff", borderRadius: 5, display: "flex", alignItems: "center", border: "none" }}>
                                                <option value={""}>Select</option>
                                                {denominations.map((i, index) => {
                                                    return <option value={i}>{i}</option>
                                                })}
                                            </select>
                                            :
                                            <input
                                                type="number"
                                                value={this.state.denomination}
                                                onChange={this.handleInputChange}
                                                style={{ width: "100%", height: 34, backgroundColor: "#fff", borderRadius: 5, display: "flex", alignItems: "center", border: "none" }}
                                            />
                                        }
                                    </div>
                                </div>
                                <div style={{ flex: 0.5, display: "flex", flexDirection: "column" }}>
                                    <div style={{ width: "100%", borderRadius: 5, display: "flex", alignItems: "end", flexDirection: "column" }}>
                                        <span style={{ color: "#9ea5ce", width: "100%", marginBottom: 5 }}>Quantity</span>
                                        <span style={{ width: "100%", height: 36, backgroundColor: "#fff", borderRadius: 5, display: "flex", alignItems: "center" }} >1</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {errorShow && type == 'open_value' ?
                        <span style={{ fontSize: 14, color: "red", paddingTop: 6 }}>Minimum {minValue} value to Maximum {maxValue} value</span> : null}
                    {errorShow && this.state.denomination == null ?
                        <span style={{ fontSize: 14, color: "red", paddingTop: 6 }}>Select Order Value</span> : null}
                    <div className={`${styles['btn-div2']}`} style={{ marginBottom: 20, width: "100%" }}>
                        <button style={{ color: color || '#000', backgroundColor: themeColors?.buttonBgColor, borderRadius: 5, height: 40 }} className={`${styles['fr-link-black-btn2']} `}

                            onClick={async () => {
                                if (!min && this.state.denomination != null) {
                                    await setSelectedVoucher({ voucherId: item.thirdPartyRewardId, value: this.state.denomination });
                                    onclick();
                                    this.setState({ error: false });
                                } else {
                                    this.setState({ error: true });

                                }

                            }}
                        >
                            <span className={`${styles['link-btn-label']}`} style={{ fontWeight: "bold" }}>Buy Now</span>
                            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                        </button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    const { benefitStyles, icons, themeColors } = state.theme;

    return {
        benefitStyles,
        icons,
        themeColors
    };
}

const mapDispatch = {
    setSelectedVoucher
}

export default connect(mapState, mapDispatch)(VoucherItem);

