import React, { Component } from 'react'
import { connect } from 'react-redux';
import ReferralPage from '../components/ReferralPage';
import { intialize } from "../reducers/session/session";
import DynamicLayout from './DynamicLayout';
import { getLayoutConfig, getLayoutContent,getCustomeCss } from '../reducers/referral/referral';

class Referral extends Component {

    constructor(props) {
        super(props)
        this.props.intialize(this.props.merchantAccessToken, this.props.authToken);
    }

    componentDidMount() {
        this.props.getLayoutConfig();
        this.props.getLayoutContent();
        this.props.getCustomeCss();
    }

    render() {
    }

    render() {
        const { referral } = this.props;
        const { redirectUrls, themeColors, basicConfig } = referral;

        if (basicConfig && basicConfig.enableDynamicLayout == "1") {
            return <ReferralPage termsUrl={redirectUrls && redirectUrls.termsUrl} themeColors={themeColors} tabStyle={basicConfig && basicConfig.tabStyle} />
        }

        return <DynamicLayout />


    }
}

const mapState = (state, ownprops) => {
    return {
        referral: state.referral,
    };
}

const mapDispatch = {
    intialize,
    getLayoutConfig,
    getLayoutContent,
    getCustomeCss
}

export default connect(mapState, mapDispatch)(Referral);
