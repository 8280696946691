import React, { Component } from 'react'
import getImage from "../images";
import styles from '../styles/loyalty.css';


export default class LightCurrentCardPage extends Component {
    render() {

        const { currentCardDetails, lightcard } = this.props;
        const textColor = currentCardDetails && currentCardDetails.textColor || '#FFF';
        const userDetails = this.props.userDetails && this.props.userDetails || {}

        return (

            <div className={`${styles['goldTierCard']} current-card`} style={{ backgroundColor: "#fff5e2", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: `url(${currentCardDetails && currentCardDetails.backgroundImage})` }}>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: 10, flex: 1 }}>
                    {lightcard && lightcard.map((key, index) => (
                        <div className={`${styles['goldCardFooter3']} current-card-footer`} style={{
                            flex: 3, justifyContent: "center", alignItems: "center",
                            borderRight: (index === lightcard.length - 1) ? "none" : "1px solid rgba(0,0,0,0.1)"
                        }}>
                            <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
                                <text style={{ color: "#000", fontSize: 45 }} >
                                    {userDetails[key.key]}
                                </text>
                                <span style={{ color: "#000" }} className={`${styles['fr-card-status-text']} fr-card-status-info`}>{key.title}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        )
    }
}
