import React, { Component } from 'react'
import Header from '../common/Header';
import getImage from '../images';
import styles from '../styles/referral.css'

class MilestonesPage extends Component {

    render() {
        const { userInfo, milestones, mileStoneType, basicConfig, getCouponCode } = this.props;
        const { referralCode, successReferralCount, pendingReferralCount, totalCount, referrerName } = userInfo || {};

        const { progressBgColor, progressBarColor } = basicConfig || {};

        const nextProgram = milestones && milestones.find((i, index) => {
            if (i.noOfReferrals > successReferralCount) {
                return milestones[index + 1];
            }
        });

        const referralMessage = nextProgram && nextProgram.noOfReferrals - successReferralCount + ` more referrals to unlock ${nextProgram.title}!`;
        const maxReferralCount = Math.max.apply(Math, milestones.map(function (o) { return o.noOfReferrals; }))

        return (
            <div className={styles["aptrtonix-block"]}>
                <Header title="Your" subTitle="Progress" />
                <div className={styles["progress-blk"]} >
                    <div className={`${styles["linear-blk"]} your-progress-div`} style={{ background: progressBgColor }}>
                        <div className={styles["points-blk"]}>
                            <div style={{ color: '#fff' }} className={styles["para-blk"]}>
                                <span className="user-name" style={{ color: '#fff', fontWeight: 600, fontSize: 16 }}>Hey {userInfo?.name}</span><br />
                                {referrerName && <div><span className="user-referral-status" style={{ color: '#FFFFFF90', fontSize: 14 }}>You have been referred by <b>{referrerName}</b></span><br /></div>}
                                <span className="user-referral-status" style={{ color: '#FFFFFF90', fontSize: 14 }}>You have made over <b>{successReferralCount}</b> referrals so far!</span><br />
                                <span className="user-unlock-goal" style={{ color: '#FEBB27', fontWeight: 600, fontStyle: 'italic', fontSize: 12, paddingTop: 10 }}>{referralMessage}</span>
                            </div>
                        </div>
                        <div className={styles["divider-blk"]}></div>
                        <div className={styles["progressing-bar"]}>
                            <div>
                                <input type="range" min="0" max={maxReferralCount} value={successReferralCount} />
                                <div className={styles["range-num"]}>
                                    <p>0</p>
                                    <p>{maxReferralCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles["miletone-blk"]}>
                    <Header title="Milestones" />

                    <div className={styles["block-flexing"]} style={{ overflowX: 'scroll' }}>
                        {milestones && milestones.map((item, index) => {
                            return <MilestoneItem mileStoneType={mileStoneType} getCouponCode={getCouponCode} item={item} index={index} key={index} successReferralCount={successReferralCount} />

                        })}
                    </div>
                </div>

            </div>
        )
    }
}

class MilestoneItem extends Component {

    constructor() {
        super();
        this.state = { coupon: null };
    }

    async componentDidMount() {

        const { item, getCouponCode } = this.props;

        if (item.freebie_item_id) {
            const couponCode = await getCouponCode(item.freebie_item_id);
            this.setState({ coupon: couponCode });
            // console.log(couponCode);
        }
    }

    render() {
        const { item, successReferralCount, index, mileStoneType } = this.props;

        if (mileStoneType == 2) {
            return (
                <div className={`${styles["referrals"]} milestone-${index + 1}`} style={{ background: item.backgroundColor || '#000', minWidth: 300, boxShadow: '1px 10px 10px 0px #41434ac4' }}>
                    <div>
                        <div className={`${styles["milestone-title-head"]} milestone-title-${index + 1}`}>
                            <span className={styles['milestone-title-second']}>0{index + 1}</span>
                            {item?.title && <div className={`${styles['v-line']} `}></div>}
                            <span className={styles['milestone-title-second']}>{item.title}</span>
                        </div>
                        <div className={`${styles["fr-milestone-count-head"]}`}>
                            <span className={`${styles["milestone-count-large"]} milestone-count-large`}>{item.noOfReferrals}</span>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <span className={styles['milestone-state']}>{item.status == "pending" ? "Reach upto" : "Reached"}</span>
                                <span className={styles['milestone-referral-text']}>Referrals</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles["rewards-blk"]}>
                        <img className="lock-icon-second" src={item.status == "pending" ? getImage('locked.svg') : getImage('unlocked.svg')} style={{ height: 40, width: 40 }} alt="" />
                        <p className={`${styles['fr-ref-ml-type']} lock-tag`}>{item.status == "pending" ? "LOCKED" : "UNLOCKED"}</p>
                        <span style={{ color: '#fff', fontSize: 12 }}>
                            {item.status == "pending" ? item.description : 'Congratulations!'}
                        </span>
                    </div>
                    {
                        item.status == "pending" ?
                            <div style={{ textAlign: 'center' }} className={styles['fr-milestone-goal-info-head']}>
                                <span className={styles['milestone-info-title']}>{item?.referralsToGo || item.noOfReferrals - successReferralCount}</span><br />
                                <span className={styles['milestone-info-sub-title']}>more referrals to go</span><br />
                                {item?.daysToGo && <span className={styles['milestone-info-sub-title']}> within <b>{`${item?.daysToGo} days.`}</b></span>}
                            </div>
                            :
                            <div>
                                <img src={item.reward && item.reward.image} style={{ width: '70%', margin: '0 15%' }} />
                                <div className={styles['reward-info-block']}>
                                    <span className={styles['reward-info-offer-text']}>{item && item.offerText}</span><br />
                                    <span className={styles['reward-info-subtitle']}>Use the coupon code below</span>
                                </div>
                                <div className={styles["copy-text-blk"]}>
                                    <p>{this.state.coupon}</p>
                                    <img src={getImage('copy.svg')} alt="" />
                                </div>
                            </div>
                    }
                </div >
            )
        }
        return (
            <div className={`${styles["referrals"]} milestone-${index + 1}`} style={{ boxShadow: '1px 10px 10px 0px #41434ac4', background: item.backgroundColor }}>
                <div className={`${styles["fr-milestone-head"]} milestone-title`}>
                    <span>{item.title}</span>
                </div>
                <div className={styles["rewards-blk"]}>
                    <img className="lock-icon" src={item.status == "pending" ? getImage('locked.svg') : getImage('unlocked.svg')} style={{ height: 20, width: 20 }} alt="" />
                    <p className={`${styles["rewards-blk-para"]} milestone-count`}>{item.noOfReferrals}</p>
                    <p className={`${styles['fr-ref-ml-type']} regerrals-tag`}>REFERRALS</p>

                </div>
                <span className={`${styles['fr-bg-count']} milestone-bg-count`}>{item.noOfReferrals}</span>
            </div>
        )
    }
}

export default MilestonesPage;