import apiClient from '../../api';
import { getPrograms } from '../loyaltyprogram/loyaltyprogram';
import { setupIntroInfo } from '../session/session';

const initialState = {
    userDetails: null,
    userPoints: null,
    loyaltyId: null,
    loading: false,
    loyaltyCardId: null,
    expiredFreebies: [],
    activeFreebies: [],
    freebies: [],
    exclusiveFreebies: [],
    baseFreebies: [],
    nextCardStatus: null,
    cards: [],
    conversionRate: null,
    isSignUpModalOpen: false,
    showOtpDiv: false,
    showForm: false,
    pointsLabel: null,
    currency: null,
    displayConversionRateComponent: null,
    defaultCurrencyConversionRate: null,
    aboutProgramComponentInfo: null,
    redeemedFreebie: null,
    showLogoOnCard: null,
    logo: null,
    pgDetails: null,
    transactionDetails: null,
    openFailureModal: false,
    freebiefilters: [],
    selectedVoucher: null,
    canBuyVoucherInfo: null,
    buyVoucherResponse: null,
};

const GET_CONFIG = "loyalty/GET_CONFIG";
const GET_USER_DETAILS_SUCCESS = "loyalty/GET_USER_DETAILS_SUCCESS";
const GET_EXPIRED_FREEBIES_SUCCESS = "loyalty/GET_EXPIRED_FREEBIES_SUCCESS";
const GET_ACTIVE_FREEBIES_SUCCESS = "loyalty/GET_ACTIVE_FREEBIES_SUCCESS";
const GET_NEXT_CARD_STATUS_SUCCESS = "loyalty/GET_NEXT_CARD_STATUS_SUCCESS";
const GET_FREEBIES_SUCCESS = 'loyalty/GET_FREEBIES_SUCCESS';
const GET_THIRD_PARTY_REWARDS_SUCCESS = 'loyalty/GET_THIRD_PARTY_REWARDS_SUCCESS';
const GET_EXCLUSIVE_FREEBIES_SUCCESS = 'loyalty/GET_EXCLUSIVE_FREEBIES_SUCCESS';
const GET_ALL_CARDS_SUCCESS = "loyalty/GET_ALL_CARDS_SUCCESS";
const GET_FREEBIE_BUY_SUCCESS = 'loyalty/GET_FREEBIE_BUY_SUCCESS';
const BUY_LOYALTY_CARD_SUCCESS = "loyalty/BUY_LOYALTY_CARD_SUCCESS";
const GET_BASE_FREEBIES = 'loyalty/GET_BASE_FREEBIES';
const CONFIGURATION = 'loyalty/CONFIGURATION';
const GET_FREEBIES_FILTER = 'loyalty/GET_FREEBIES_FILTER';
const SET_LOYALTY_CARD_ID = "loyalty/SET_LOYALTY_CARD_ID";
const SET_USER_POINTS = "loyalty/SET_USER_POINTS";
const ERROR = "loyalty/ERROR";
const SET_LOYALTY_DETAILS = "loyalty/SET_LOYALTY_DETAILS";
const OPEN_SIGN_UP_MODAL = "loyalty/OPEN_SIGN_UP_MODAL";
const CLOSE_SIGN_UP_MODAL = "loyalty/CLOSE_SIGN_UP_MODAL";
const GET_LOYALTY_OTP = "loyalty/GET_LOYALTY_OTP";
const VALIDATE_LOYALTY_OTP = "loyalty/VALIDATE_LOYALTY_OTP";
const CARD_BUY_TRANSACTION_DETAILS = "loyalty/CARD_BUY_TRANSACTION_DETAILS";
const CLOSE_FAILURE_MODAL = "loyalty/CLOSE_FAILURE_MODAL";
const SET_SELECTED_VOUCHER = "loyalty/SET_SELECTED_VOUCHER";
const GET_CAN_BUY = "loyalty/GET_CAN_BUY";
const BUY_VOUCHER = "loyalty/BUY_VOUCHER";

export const setUserPoints = (userPoints) => ({
    type: SET_USER_POINTS,
    userPoints,
});

export const setLoyaltyCardId = (loyaltyCardId) => async (dispatch, getState) => {
    dispatch({
        type: SET_LOYALTY_CARD_ID,
        loyaltyCardId,
    });
};

export const initializeLoyalty = (loyaltyId) => (dispatch, getState) => {
    dispatch({
        type: SET_LOYALTY_DETAILS,
        loyaltyId,
    });
};

export const setSelectedVoucher = (selectedVoucher) => (dispatch, getState) => {
    dispatch({
        type: SET_SELECTED_VOUCHER,
        selectedVoucher,
    });
};

export const callApis = () => async (dispatch, getState) => {
    dispatch(getNextCardStatus());
    dispatch(getAllCards());
    dispatch(getFreebies());
    dispatch(getExclusiveFreebies());
    dispatch(getconfiguration());
    dispatch(getfreebiefilters());
};

export const callBaseFreebiesApis = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.cards(loyaltyId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            if (response.cards.length > 0) {

                const baseCard = response.cards.filter((item) => {
                    return item.isBaseCard;
                });
                if (baseCard && baseCard[0]) {
                    dispatch(getBaseFreebies(baseCard[0].id));
                }
            }
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};


export const callMyFreebies = () => async (dispatch, getState) => {
    dispatch(getExpiredFreebies());
    dispatch(getActiveFreebies());
};

export const getUserDetails = (routeName) => async (dispatch, getState) => {

    const state = getState();
    const { authToken, merchantAccessToken, loyaltyId } = state.session;

    try {

        const response = await apiClient.get(apiClient.Urls.userDetails(loyaltyId), {
            authToken,
            merchantAccessToken,
        });
        if (response.success) {

            await dispatch({
                type: GET_USER_DETAILS_SUCCESS,
                userDetails: response,
            });

            dispatch(setUserPoints(response.points));
            dispatch(getPrograms());
            await dispatch(setLoyaltyCardId(response.currentCardId));
            dispatch(callApis());
            dispatch(setupIntroInfo(response.showIntroduction, response.isEnrolled, routeName));

        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};



export const getLoyaltyConfig = () => async (dispatch, getState) => {

    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;
    try {
        const response = await apiClient.get(apiClient.Urls.config(loyaltyId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_CONFIG,
                pointsLabel: response.pointsLabel,
                currency: response.currency,
                displayConversionRateComponent: response.displayConversionRateComponent,
                defaultCurrencyConversionRate: response.defaultCurrencyConversionRate,
                aboutProgramComponentInfo: response.aboutProgramComponentInfo,
                showLogoOnCard: response.showLogoOnCard,
                logo: response.logo,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getExpiredFreebies = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken, loyaltyId } = state.session;

    try {
        const response = await apiClient.get(apiClient.Urls.expiredBenefits(loyaltyId), {
            authToken,
            merchantAccessToken,
        });



        if (response.success) {
            dispatch({
                type: GET_EXPIRED_FREEBIES_SUCCESS,
                expiredFreebies: response.freebies,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));

    }
};

export const buyLoyaltyCard = (upgradeCardId) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken, loyaltyId } = state.session;


    try {

        const response = await apiClient.get(apiClient.Urls.buyLoyaltyCard(loyaltyId, upgradeCardId), {
            authToken,
            merchantAccessToken,
            returnUrl: window.location.href,
        });

        if (response.success) {
            dispatch({
                type: BUY_LOYALTY_CARD_SUCCESS,
                pgDetails: response,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const buyRewardVoucher = (handleNavigate, redeemPoints) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken, loyaltyId } = state.session;
    const { selectedVoucher } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.buyVoucher(loyaltyId), {
            authToken,
            merchantAccessToken,
            successUrl: window.location.origin + '/voucher-order-status/success',
            failureUrl: window.location.origin + '/voucher-order-status/failure',
            thirdPartyRewardId: selectedVoucher.voucherId,
            denomination: selectedVoucher.value,
            loyaltyPointsToRedeem: redeemPoints,
        });
        if (response.success) {
            dispatch({
                type: BUY_VOUCHER,
                buyVoucherResponse: response,
            });
            if (response.goToPg) {
                handleNavigate(response.response.url);
            }
            else {
                handleNavigate(window.location.origin + '/voucher-order-status/success');
            }
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getCanBuyVoucher = (loyaltyPointsToRedeem) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken, loyaltyId } = state.session;
    const { selectedVoucher } = state.loyalty;
    try {
        const response = await apiClient.get(apiClient.Urls.getCanBuyVoucher(loyaltyId), {
            authToken,
            merchantAccessToken,
            loyaltyPointsToRedeem,
            denomination: selectedVoucher.value,
        });

        if (response) {
            dispatch({
                type: GET_CAN_BUY,
                canBuyVoucherInfo: response,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const clearTransactionDetails = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: CARD_BUY_TRANSACTION_DETAILS,
            transactionDetails: null,
        });

    } catch (e) {

    }
};


export const getActiveFreebies = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken, loyaltyId } = state.session;


    try {
        const response = await apiClient.get(apiClient.Urls.activeBenefits, {
            authToken,
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: GET_ACTIVE_FREEBIES_SUCCESS,
                activeFreebies: response.freebies,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getNextCardStatus = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.nextCardStatus(loyaltyId), {
            authToken,
            merchantAccessToken,
        });



        if (response.success) {
            dispatch({
                type: GET_NEXT_CARD_STATUS_SUCCESS,
                nextCardStatus: { percent: response.percent, text: response.text },
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};


export const getAllCards = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.cards(loyaltyId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_ALL_CARDS_SUCCESS,
                cards: response.cards,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getFreebies = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId, loyaltyCardId } = state.loyalty;


    try {
        const response = await apiClient.get(apiClient.Urls.getCardFreebies(loyaltyId, loyaltyCardId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_FREEBIES_SUCCESS,
                freebies: response.freebies,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getThirdPartyRewards = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.getThirdPartyRewards(loyaltyId), {
            authToken,
            merchantAccessToken,
            showItems: 50,
        });

        if (response.success) {
            dispatch({
                type: GET_THIRD_PARTY_REWARDS_SUCCESS,
                thirdPartyRewards: response.data,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getfreebiefilters = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;
    try {
        const response = await apiClient.get(apiClient.Urls.getfreebiefilters(loyaltyId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_FREEBIES_FILTER,
                freebiefilters: response.filters,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getExclusiveFreebies = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId, loyaltyCardId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.getExclusiveFreebies(loyaltyId, loyaltyCardId), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {
            dispatch({
                type: GET_EXCLUSIVE_FREEBIES_SUCCESS,
                freebies: response.freebies,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};

export const getBaseFreebies = (id) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.getCardFreebies(loyaltyId, id), {
            authToken,
            merchantAccessToken,
        });

        if (response.success) {

            dispatch({
                type: GET_BASE_FREEBIES,
                baseFreebies: response.freebies,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};


export const redeemFreebie = (freebieId) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    const { loyaltyId, loyaltyCardId } = state.loyalty;

    try {
        const response = await apiClient.get(apiClient.Urls.redeemFreebie(loyaltyId, loyaltyCardId, freebieId), {
            authToken,
            merchantAccessToken,
        });

        if (response) {
            dispatch({
                type: GET_FREEBIE_BUY_SUCCESS,
                res: response,
            });
        } else {
            dispatch(displayError('', response.message));
        }
    } catch (e) {
        dispatch(displayError('EXCEPTION', e.message));
    }
};


export const getconfiguration = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    try {
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'configuration',
            template: 'loyalty-sdk',
            authToken,
            merchantAccessToken,
        });

        console.log("configuration", response);

        if (response.success) {
            dispatch({
                type: CONFIGURATION,
                configuration: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};



const displayError = (title, message, loading = 'loading') => ({
    type: ERROR,
    title,
    message,
    loading,
});

export const openSignUpModal = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: OPEN_SIGN_UP_MODAL,
        });
    }
    catch (e) {

    }
}

export const closeSignUpModal = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLOSE_SIGN_UP_MODAL,
        });
    }
    catch (e) {

    }
}

export const closeFailureModal = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLOSE_FAILURE_MODAL
        })
    }
    catch (e) {

    }
}

export const getLoyaltyOtp = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_LOYALTY_OTP,
        });
    }
    catch (e) {

    }
}

export const validateLoyaltyOtp = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: VALIDATE_LOYALTY_OTP,
        });
    }
    catch (e) {

    }
}

const loyaltyReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_CONFIG: {
            return {
                ...state,
                pointsLabel: action.pointsLabel,
                currency: action.currency,
                displayConversionRateComponent: action.displayConversionRateComponent,
                defaultCurrencyConversionRate: action.defaultCurrencyConversionRate,
                aboutProgramComponentInfo: action.aboutProgramComponentInfo
            }
        }

        case SET_LOYALTY_DETAILS: {
            return {
                ...state,
                loyaltyId: action.loyaltyId,
            }
        }

        case CONFIGURATION: {
            return {
                ...state,
                configuration: action.configuration,
            }
        }

        case OPEN_SIGN_UP_MODAL: {
            return {
                ...state,
                isSignUpModalOpen: true,
            }
        }
        case CLOSE_SIGN_UP_MODAL: {
            return {
                ...state,
                isSignUpModalOpen: false,
                showOtpDiv: false,
                showForm: false,
            }
        }
        case GET_LOYALTY_OTP: {
            return {
                ...state,
                showOtpDiv: true,
            }
        }
        case VALIDATE_LOYALTY_OTP: {
            return {
                ...state,
                showForm: true,
                showOtpDiv: false,
            }
        }
        case BUY_LOYALTY_CARD_SUCCESS: {
            return {
                ...state,
                pgDetails: action.pgDetails,
                transactionDetails: null,
                openFailureModal: false,
            }
        }
        case GET_USER_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                userDetails: action.userDetails
            }
        }
        case SET_USER_POINTS: {
            return {
                ...state,
                userPoints: action.userPoints,
            }
        }
        case SET_LOYALTY_CARD_ID: {
            return {
                ...state,
                loyaltyCardId: action.loyaltyCardId
            }
        }
        case GET_BASE_FREEBIES: {
            return {
                ...state,
                baseFreebies: action.baseFreebies
            }
        }
        case GET_FREEBIES_FILTER: {
            return {
                ...state,
                freebiefilters: action.freebiefilters
            }
        }

        case GET_EXCLUSIVE_FREEBIES_SUCCESS: {
            return {
                ...state,
                exclusiveFreebies: action.freebies
            }
        }
        case SET_SELECTED_VOUCHER: {
            return {
                ...state,
                selectedVoucher: action.selectedVoucher
            }
        }

        case ERROR: {
            return {
                ...state,
                [action.loading]: false,
                error: {
                    title: action.title,
                    message: action.message,
                }
            }
        }
        case GET_EXPIRED_FREEBIES_SUCCESS: {
            return {
                ...state,
                expiredFreebies: action.expiredFreebies
            }
        }

        case GET_ACTIVE_FREEBIES_SUCCESS: {
            return {
                ...state,
                activeFreebies: action.activeFreebies
            }
        }
        case GET_NEXT_CARD_STATUS_SUCCESS: {
            return {
                ...state,
                nextCardStatus: action.nextCardStatus
            }
        }
        case GET_ALL_CARDS_SUCCESS: {
            return {
                ...state,
                cards: action.cards
            }
        }

        case GET_FREEBIES_SUCCESS: {
            return {
                ...state,
                freebies: action.freebies
            }
        }
        case GET_THIRD_PARTY_REWARDS_SUCCESS: {
            return {
                ...state,
                thirdPartyRewards: action.thirdPartyRewards,
            }
        }
        case GET_FREEBIE_BUY_SUCCESS: {
            return {
                ...state,
                redeemedFreebie: action.res
            }
        }
        case CARD_BUY_TRANSACTION_DETAILS: {
            return {
                ...state,
                transactionDetails: action.transactionDetails,
                pgDetails: null,
                openFailureModal: action.openFailureModal
            }
        }
        case GET_CAN_BUY: {
            return {
                ...state,
                canBuyVoucherInfo: action.canBuyVoucherInfo
            }
        }
        case BUY_VOUCHER: {
            return {
                ...state,
                buyVoucherResponse: action.buyVoucherResponse
            }
        }
        case CLOSE_FAILURE_MODAL: {
            return {
                ...state,
                openFailureModal: false,
                transactionDetails: null
            }
        }

        default: return state;
    }

}


export default loyaltyReducer;
