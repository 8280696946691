import React from 'react'
import styles from '../../styles/gifting.css';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import getImage from "../../images";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '5px',
    // height: '90vh',
    overflow: 'hidden'
  },
  overlay: {
    background: '#000000e0'
  }
};

function FailureModal(props) {
  const { isModalOpen, handlesetisModalOpen, } = props
  const placeOrder = useSelector(state => state.placeOrder)
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => handlesetisModalOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src={getImage('close.svg')} alt="Img" className={styles['fr-close-btn']} onClick={() => handlesetisModalOpen(false)} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: 20 }}>
        <div style={{ alignSelf: 'center', marginBottom: 30 }} className="modalErrors worng">
          <img src={getImage('wrongIcon.svg')} alt="Wrong" />
        </div>
        <span className={styles['fr-modal-title']}>Payment Failed! </span>
        <span className={styles['fr-modal-sub-title']}>{placeOrder.transactionStatus?.message}</span>
      </div>
    </Modal>
  )
}

FailureModal.propTypes = {

}

export default FailureModal

