
import React, { Component } from 'react';
import styles from '../styles/loyalty.css';
import BackButton from '../common/BackButton';
import VoucherAboutAndTermsPage from './VoucherAboutAndTermsPage';

class VoucherCartPage extends Component {



    constructor(props) {
        super(props)
        this.state = {
            terms: false,
            about: false,
            activeOption: 'all_point_use',
            customInputValue: '',
            erroMessage: false,
            inputFocused: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.userPoints !== 0) {
            this.props.getCanBuyVoucher(this.props.userPoints);
        }
    }

    handleInputChange = (event) => {
        this.setState({ customInputValue: event.target.value })

    };
    handleCheckButtonClick = () => {
        const inputValue = this.state.customInputValue;
        this.props.getCanBuyVoucher(inputValue)
        this.setState({ erroMessage: true })
    };

    handleOptionChange = (option) => {
        this.setState({ activeOption: option });
        this.props.getCanBuyVoucher(this.props.userPoints)
        this.setState({ erroMessage: false })
    };
    handleInputFocus = () => {
        this.setState({ inputFocused: true });
        this.setState({ activeOption: 'custom' });
    };

    handleInputBlur = () => {
        this.setState({ inputFocused: false });
    };

    render() {
        const { voucher, pushPath, selectedVoucher, themeColors, userPoints, buyRewardVoucher, canBuyVoucherInfo, currency } = this.props;
        const cashValue = canBuyVoucherInfo && canBuyVoucherInfo?.availablePointsValue || 0;
        const availablePointsToRedeem = canBuyVoucherInfo?.availablePointsToRedeem

        return (
            <div>
                <div>
                    <BackButton onclick={() => pushPath('/loyalty', null)} />
                    <hr />
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={`${styles['cart-main']}`}>
                        <div className={`${styles['gift-voucher-info']}`}>
                            <div className={`${styles['giftvocher-div']}`}>
                                <span style={{ marginBottom: "20px" }}>Gift Voucher <span style={{ color: "#ED754D" }}>(1 item)</span></span>
                                <div className={`${styles['cart-item-main']}`} style={{ borderTop: "1px solid #E0EAF0", paddingTop: "10px" }}>
                                    <div style={{ display: "flex", flex: "40%" }}>
                                        <img className={`${styles['voucher-img']}`} style={{ objectFit: "contain", height: "auto" }} src={voucher.image} />
                                    </div>
                                    <div style={{ flex: "80%", padding: '10px' }} className={`${styles['flex-column']}`}>
                                        <div className={`${styles['flex-column']}`}>
                                            <span style={{ color: themeColors?.themeColor, marginTop: 10, fontSize: 20 }}>{voucher.productName.length > 30 ? `${voucher.productName.slice(0, 30)}...` : voucher.productName}</span>
                                            <div className={styles['voucher-description']} dangerouslySetInnerHTML={{ __html: voucher.description || '' }} />
                                        </div>
                                        <div className={`${styles['order-buttons']}`} >
                                            <div className={`${styles['flex-column']}`} style={{ marginRight: "10px" }}>
                                                <span>Order Value</span>
                                                <button className={`${styles['button-cart']}`}>{selectedVoucher?.value}</button>
                                            </div>
                                            <div className={`${styles['flex-column']}`}>
                                                <span>Quantity</span>
                                                <button className={`${styles['button-cart']}`}>1</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <span className={`${styles['info-button']} font-grey`}>Lorem ipsum dolor sit amet, consetetur sadipscing</span> */}
                            </div>
                            <VoucherAboutAndTermsPage themeColors={themeColors} aboutVoucher={voucher.redemptionInstructions} termsVoucher={voucher.termsAndConditions} />
                            <div className={`${styles["payment-div"]}`}>
                                <span style={{ marginTop: 5, marginBottom: 10, display: "flex" }}>Payment</span>
                                <div className={`${styles["payment-sub-div"]}`}>
                                    <span className={`${styles["font-grey"]}`}></span>
                                    <div className={`${styles["font-points"]}`}>
                                        <h4>Cash Point Balance :</h4>
                                        <span style={{ color: "#013ADD", paddingLeft: 5 }}>{userPoints} Cash Points</span>
                                    </div>
                                </div>

                                <div style={{ width: "100%", display: "flex", flexDirection: "column", backgroundColor: "#f1f7fa", borderRadius: 5, border: '1px solid rgba(0,0,0,0.1)' }}>
                                    <label className={`${styles["paymentDiv"]}`} style={{ alignItems: "center" }}>
                                        <input type="radio" name="site_name"
                                            value="all_point_use"
                                            checked={this.state.activeOption === "all_point_use"}
                                            onChange={() => this.handleOptionChange("all_point_use")}
                                        />
                                        <span style={{ paddingLeft: 5, fontWeight: "bold" }}>Use {availablePointsToRedeem} Cash Points</span>
                                    </label>
                                    <div style={{ width: "96%", border: '1px solid rgba(0,0,0,0.1)', display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "center" }}></div>
                                    <label className={`${styles["paymentmainDiv"]}`} style={{}}>
                                        <div className={`${styles["paymentDiv1"]}`}>
                                            <input type="radio" name="site_name"
                                                value="custom"
                                                checked={this.state.activeOption === "custom"}
                                                onChange={() => this.handleOptionChange("custom")}
                                            />
                                            <span style={{ paddingLeft: 5, fontWeight: "bold" }}>Use Custom Points</span>
                                        </div>
                                        <div className={`${styles["paymentDiv2"]}`} style={{ display: "flex" }}>
                                            <input
                                                type="number"
                                                value={this.state.customInputValue}
                                                onChange={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                                onBlur={this.handleInputBlur}
                                                style={{ width: 100, height: 36, backgroundColor: "#fff", borderRadius: 5, display: "flex", alignItems: "center", marginLeft: 20, borderStyle: "solid", borderColor: this.state.inputFocused ? "blue" : "#DBDBDB" }}
                                            />

                                            <button
                                                onClick={
                                                    this.state.activeOption === "custom" ?
                                                        this.handleCheckButtonClick : null
                                                }
                                                style={{ width: 60, border: "none", height: 36, alignItems: "center", backgroundColor: "#ED754D", color: "#fff", borderRadius: 5, marginLeft: 15, marginRight: 15 }}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </label>
                                    {this.state.erroMessage == true ?
                                        <span style={{ color: "red", fontSize: 14, paddingLeft: 10, paddingBottom: 20 }}>{canBuyVoucherInfo?.message}</span>
                                        :
                                        null
                                    }

                                </div>

                            </div>
                        </div>
                        <div className={`${styles['order-div']}`} style={{ backgroundColor: "#f1f7fa" }}>
                            <span style={{ paddingTop: 15, paddingLeft: 10, paddingBottom: 15, fontWeight: "bold" }}>Order summary</span>
                            <div style={{ width: "96%", border: '1px solid rgba(0,0,0,0.1)', display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "center" }}></div>
                            <div className={`${styles['total-div']}`}>
                                <div className={`${styles['font-order']}`}>
                                    <span style={{ paddingLeft: 10 }}>Net order value</span>
                                    <span style={{ paddingRight: 10 }}>{currency}{selectedVoucher && selectedVoucher?.value}</span>
                                </div>
                                <div className={`${styles['font-order']}`}>
                                    <span style={{ paddingLeft: 10 }}>Cash Points Value ({canBuyVoucherInfo?.availablePointsToRedeem || 0})</span>
                                    <span style={{ paddingRight: 10 }}>{currency}{cashValue && cashValue}</span>
                                </div>
                            </div>
                            <div style={{ width: "96%", border: '1px solid rgba(0,0,0,0.1)', display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "center" }}></div>
                            <div className={`${styles['total']}`}>
                                <div className={`${styles['font-order']}`}>
                                    <span style={{ paddingLeft: 10, fontWeight: "bold" }}>Total Payment</span>
                                    <span style={{ paddingRight: 10 }}>{currency}{selectedVoucher && selectedVoucher?.value - cashValue}</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: 10 }}>
                                <button onClick={() => {
                                    buyRewardVoucher();
                                }} className={`${styles["checkout-button"]}`}>Pay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VoucherCartPage;
