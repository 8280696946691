import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer'

export default function () {
    const middleWares = [
        thunk
    ];
    return createStore(
        combineReducers(rootReducer),
        undefined,
        applyMiddleware(...middleWares)
    );
};
