import React, { Component } from 'react'
import { connect } from 'react-redux';
import styles from '../styles/referral.css';

class Header extends Component {
    render() {
        const { title, subTitle, themeColors,customeCss } = this.props;
        const { headingColor } = themeColors || {};
        const {customCss} = customeCss;
        console.log(customCss,"customCss")
        return (
          <div>
            <style>{customCss}</style>

            <div className={`${styles['fr-heading-div']} heading-section-main`}>
                <div className={`heading-section`}>
                    <span  style={{ color: headingColor, fontSize: 22 }} className={`heading-title`}>{title}</span>
                    <span className={`heading-title`} style={{ color: headingColor, marginLeft: 5, fontSize: 22 }}>{subTitle}</span>
                </div>
            </div>
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        themeColors: state.referral.themeColors,
        customeCss:state.referral.customeCss
    };
}

export default connect(mapState, {})(Header);
