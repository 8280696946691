import React, { Component } from 'react'
import { connect } from 'react-redux';
import LightCurrentCardPage from '../components/LightCurrentCardPage';
import { getloyaltyCardLabels } from '../reducers/theme/theme';

class LightCurrentCard extends Component {

    componentDidMount() {
        this.props.getloyaltyCardLabels();
    }

    render() {
        const { userDetails, userPoints, loading, loyaltyCardId, cards, pointsLabel, showLogoOnCard, logo, currency, configuration, } = this.props.loyalty;
        const { lightcurrentcard } = this.props
        const currentCardDetails = cards.find((i) => {
            return i.id === loyaltyCardId;
        });
        const lightcard = lightcurrentcard?.lightcurrentcard

        return (
            <LightCurrentCardPage currentCardDetails={currentCardDetails} lightcard={lightcard} loyaltyCardLabels={this.props.loyaltyCardLabels} spointsLabel={pointsLabel} currency={currency} icons={this.props.icons}
                userPoints={userPoints} configuration={configuration} textColor={'#fff'} userDetails={userDetails} isFrom={this.props.from} showLogoOnCard={showLogoOnCard} logo={logo} />
        )
    }
}

const mapState = (state, ownprops) => {
    const { icons, loyaltyCardLabels } = state.theme;
    return {
        loyalty: state.loyalty,
        icons: icons,
        loyaltyCardLabels,
        lightcurrentcard: state.theme,
    };
}

const mapDispatch = {
    getloyaltyCardLabels,
}

export default connect(mapState, mapDispatch)(LightCurrentCard);
