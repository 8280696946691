import React, { Component } from 'react'
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'


export default class GiftCardHistoryMobileItem extends Component {

    render() {
        const { item, index, themeColors, copiedUrlIndex, copiedPinIndex } = this.props
        const urlType = item.freebieItem?.couponInfo && item.freebieItem?.couponInfo?.type
        const url = item.freebieItem?.couponInfo && item.freebieItem?.couponInfo?.voucherCode
        const pin = item.freebieItem?.couponInfo && item.freebieItem?.couponInfo?.pin
        const pinLconColor = copiedPinIndex === index
        const urlLconColor = copiedUrlIndex === index

        return (

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>

                <div style={{ width: "100%", backgroundColor: "#EFF4F7", marginTop: 10, marginBottom: 10, borderRadius: 10, border: '1px solid rgba(0,0,0,0.1)', justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", padding: 10, paddingTop: 12, paddingBottom: 12 }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={item.image} alt="Img" style={{ width: 36, height: 36, borderRadius: 50, objectFit: "cover" }} />
                            <span className={`${styles['someText']}`} style={{ fontSize: 15, fontWeight: 900, paddingLeft: 7 }} >{item.name}</span>
                        </div>
                    </div>
                    <div style={{ border: '1px solid rgba(0,0,0,0.1)' }}></div>
                    <div style={{ display: "flex", padding: 10, paddingTop: 12, paddingBottom: 12 }}>
                        <div style={{ display: "flex", flex: 4, flexDirection: "column", justifyContent: "center" }}>
                            <span style={{ fontSize: 12, color: "#909192" }} >DATE</span>
                            <span style={{ fontSize: 13, fontWeight: 900, paddingTop: 3 }} >{item.freebieItem?.redeemedAt || "---"}</span>
                        </div>
                        <div style={{ display: "flex", flex: 4, flexDirection: "column", justifyContent: "center", paddingLeft: 3, paddingRight: 3 }}>
                            <span style={{ fontSize: 12, color: "#909192", }} >EXPIRY DATE</span>
                            <span style={{ fontSize: 13, fontWeight: 900, paddingTop: 3, }} >{item.freebieItem?.expiryDate || "---"}</span>
                        </div>
                        <div style={{ display: "flex", flex: 2.4, flexDirection: "column", justifyContent: "center" }}>
                            <span style={{ fontSize: 12, color: "#909192" }} >POINTS</span>
                            <span style={{ fontSize: 13, fontWeight: 900, paddingTop: 3 }} >{parseFloat(item.worth)}</span>
                        </div>
                    </div>
                    <div style={{ border: '1px solid rgba(0,0,0,0.1)' }}></div>
                    <div style={{ display: "flex", padding: 10, paddingTop: 12, paddingBottom: 12 }}>
                        {urlType == 'code' ?
                            <div style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", display: "flex", width: "100%" }}>
                                <div style={{ borderStyle: "dashed", borderWidth: 1, borderRadius: 5, backgroundColor: 'rgba(0,0,0,0.1)', display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%", borderColor: themeColors?.buttonBgColor }}>
                                    <div style={{ padding: 8 }}>
                                        <span className={`${styles['someText1']}`}>{url}</span>
                                    </div>
                                    <div style={{
                                        padding: 8, backgroundColor: copiedUrlIndex === index ? 'green' : themeColors?.buttonBgColor, borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5, cursor: 'pointer'
                                    }}
                                        onClick={() => this.copyToClipboard(url, index, 'urls')}
                                    >
                                        <FontAwesomeIcon style={{ paddingLeft: 10, color: urlLconColor ? themeColors?.buttonBgColor : "#ddd" }} icon={faCopy} />
                                    </div>
                                </div>
                            </div>
                            :
                            urlType == 'codePin' ?
                                <div style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", display: "flex", width: "100%" }}>
                                    <div style={{
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderRadius: 5,
                                        backgroundColor: 'rgba(0,0,0,0.1)',
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                        width: "100%",
                                        borderColor: themeColors?.buttonBgColor,
                                    }}>
                                        <div style={{ padding: 8 }}>
                                            <span className={`${styles['someText1']}`}>{url}</span>
                                        </div>
                                        <div style={{
                                            padding: 8, backgroundColor: urlLconColor ? 'green' : themeColors?.buttonBgColor, borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5, cursor: 'pointer'
                                        }}
                                            onClick={() => this.copyToClipboard(url, index, 'urls')}
                                        >
                                            <FontAwesomeIcon style={{ paddingLeft: 10, color: copiedUrlIndex === index ? themeColors?.buttonBgColor : "#ddd" }} icon={faCopy} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                                            <div style={{ padding: 8 }}>
                                                <span className={`${styles['someText1']}`}>PIN: {pin}</span>
                                            </div>
                                            <div style={{ padding: 8, cursor: 'pointer' }}
                                                onClick={() => this.copyToClipboard(pin, index, 'pins')}
                                            >
                                                <FontAwesomeIcon style={{ paddingLeft: 10, color: pinLconColor ? 'green' : themeColors?.buttonBgColor }} icon={faCopy} />
                                            </div>
                                        </div>
                                    </div >
                                </div>
                                :
                                urlType == 'urlPin' ?
                                    <div style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", display: "flex", width: "100%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "50%" }}>
                                            <button
                                                onClick={() => window.open(url, '_blank')}
                                                style={{ backgroundColor: themeColors?.buttonBgColor, borderStyle: "none", borderRadius: 5, border: '1px solid rgba(0,0,0,0.1)', padding: 10, width: "100%" }}
                                            >
                                                <span className={`${styles['active-btn']}`}>Claim Voucher</span>
                                            </button>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                                                <div style={{ padding: 8 }}>
                                                    <span className={`${styles['someText1']}`}>PIN: {pin}</span>

                                                </div>
                                                <div style={{ padding: 8, cursor: 'pointer' }}
                                                    onClick={() => this.copyToClipboard(pin, index, 'pins')}
                                                >
                                                    <FontAwesomeIcon style={{ paddingLeft: 10, color: pinLconColor ? 'green' : themeColors?.buttonBgColor }} icon={faCopy} />
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                    :
                                    urlType == 'url' ?
                                        <div style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", display: "flex", width: "100%" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                                                <button
                                                    onClick={() => window.open(url, '_blank')}
                                                    style={{ backgroundColor: themeColors?.buttonBgColor, borderStyle: "none", borderRadius: 5, border: '1px solid rgba(0,0,0,0.1)', padding: 10, width: "100%" }}
                                                >
                                                    <span className={`${styles['active-btn']}`}>Claim Voucher</span>
                                                </button>
                                            </div>
                                        </div> : null
                        }
                    </div>
                </div>

            </div>

        )
    }
}
