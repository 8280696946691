
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pushPath } from '../reducers/navigation/navigation';
import VoucherCartPage from '../components/VoucherCartPage';
import { getCanBuyVoucher, buyRewardVoucher, getLoyaltyConfig } from '../reducers/loyalty/loyalty';

class VoucherCart extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        getLoyaltyConfig();
    }

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { navigation, selectedVoucher, icons, themeColors, getCanBuyVoucher, userDetails, buyRewardVoucher, canBuyVoucherInfo, } = this.props;
        const { currency } = this.props.loyalty;
        const { params, } = navigation;
        const { voucher } = params;
        const redeemPoints = canBuyVoucherInfo?.availablePointsToRedeem

        const handleNavigate = (url) => {
            window.location.href = url;

        }

        return (
            <VoucherCartPage voucher={voucher}
                getCanBuyVoucher={getCanBuyVoucher}
                buyRewardVoucher={() => buyRewardVoucher(handleNavigate, redeemPoints)}
                currency={currency}
                userPoints={userDetails.points || 0} pushPath={this.props.pushPath}
                themeColors={themeColors} selectedVoucher={selectedVoucher} canBuyVoucherInfo={canBuyVoucherInfo} />
        );
    }
}

const mapState = (state, ownprops) => {
    const { icons, themeColors } = state.theme;
    return {
        navigation: state.navigation,
        selectedVoucher: state.loyalty.selectedVoucher,
        userDetails: state.loyalty.userDetails,
        canBuyVoucherInfo: state.loyalty.canBuyVoucherInfo,
        icons,
        themeColors,
        loyalty: state.loyalty
    };
}

const mapDispatch = {
    pushPath,
    getCanBuyVoucher,
    buyRewardVoucher,
    getLoyaltyConfig
}

export default connect(mapState, mapDispatch)(VoucherCart);
