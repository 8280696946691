import React, { Component } from 'react';
import { connect } from 'react-redux';
import AllBenefitsPage from '../components/AllBenefitsPage';
import { pushPath } from '../reducers/navigation/navigation';


class AllBenefits extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { themeColors } = this.props
        const { freebies, exclusiveFreebies, freebiefilters } = this.props.loyalty;
        return (
            <AllBenefitsPage pushPath={this.props.pushPath} exclusiveFreebies={exclusiveFreebies} freebies={freebies} freebiefilters={freebiefilters} themeColors={themeColors} />
        )
    }
}


const mapState = (state, ownprops,) => {
    const { themeColors } = state.theme;
    return {
        themeColors,
        loyalty: state.loyalty,
        navigation: state.navigation,
    };
}

const mapDispatch = {
    pushPath,
}

export default connect(mapState, mapDispatch)(AllBenefits);