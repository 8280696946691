import React, { Component } from 'react'
import BackButton from '../common/BackButton';
import Heading from '../common/Heading';
import styles from '../styles/loyalty.css';
import BenefitItem from './BenefitItem';
import ExclusiveBenefitItem from './ExclusiveBenefitItem';
import MultiSelectItem from './MultiSelectItem';

export default class AllBenefitsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFilters: {},
        };
    }


    render() {
        const { pushPath, freebies, exclusiveFreebies, freebiefilters, themeColors } = this.props;

        const selectedFiltersCount = Object.keys(this.state.selectedFilters).length;

        const filteredFreebies = selectedFiltersCount == 0
            ? freebies
            : freebies.filter((freebie) => {
                for (let filterId in this.state.selectedFilters) {
                    const selectedOptions = this.state.selectedFilters[filterId];
                    if (!selectedOptions || selectedOptions.length == 0) {
                        continue;
                    }
                    if (!(filterId in freebie.filters)) {
                        return false;
                    }
                    if (!selectedOptions.includes(freebie.filters[filterId])) {
                        return false;
                    }
                }
                return true;
            });

        return (
            <div>
                <div>
                    <BackButton onclick={() => { pushPath('/loyalty', null); }} />
                    <hr />
                </div>
                <div>
                    <Heading title="Showing" subTitle="Rewards Boutique" />
                    <div className={styles['benefit-filters-div']}>
                        {freebiefilters.map((filter, index) => (
                            <MultiSelectItem
                                options={filter.options}
                                placeholder={filter.label}
                                key={filter.id}
                                themeColors={themeColors}
                                selectedItems={this.state.selectedFilters[filter.id] || []}
                                setSelectedItem={(optionId) => {
                                    const selectedFilter = (this.state.selectedFilters[filter.id] || []).slice();
                                    selectedFilter.push(optionId);
                                    this.setState({
                                        selectedFilters: {
                                            ...this.state.selectedFilters,
                                            [filter.id]: selectedFilter,
                                        }
                                    });
                                }}
                                removeSelectedItem={(optionId) => {
                                    const selectedFilter = (this.state.selectedFilters[filter.id] || []).slice();
                                    this.setState({
                                        selectedFilters: {
                                            ...this.state.selectedFilters,
                                            [filter.id]: selectedFilter.filter(oid => oid != optionId),
                                        }
                                    });
                                }}
                            />
                        ))}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {filteredFreebies.length > 0 ?
                            filteredFreebies.map((item, index) => {
                                return <div className={styles['fr-freebie-width']}>
                                    <BenefitItem item={item} key={index} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} />
                                </div>
                            })
                            :
                            <div className={styles['orderBox']}>
                                <h6>No Benefits available now.</h6>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <Heading title="Exclusive" subTitle="Benefits" />
                    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                        {exclusiveFreebies.length > 0 ?
                            exclusiveFreebies.map((item, index) => {
                                return <ExclusiveBenefitItem item={item} key={index} onclick={() => pushPath('/freebie-details', { freebie: item, type: 'loyalty' })} />
                            })
                            :
                            <div className={styles['orderBox']}>
                                <h6>No Benefits available now.</h6>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}



