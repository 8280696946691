import React, { Component } from 'react'
import Heading from '../common/Heading';
import styles from '../styles/loyalty.css';
import BackButton from '../common/BackButton';
import getImage from "../images";
export default class TransactionHistoryPage extends Component {

    render() {
        let Pamount = 0
        let paid = 0
        const { pushPath, pointsInfo, transactions, isMainComponent, merchantInfo, themeColors, transactionHistory } = this.props;
        transactions && transactions.map((item, index) => {
            return Pamount += parseFloat(item.amount)
        })
        transactions && transactions.map((item, index) => {
            item.payments && item.payments.map((pay) => { paid += parseInt(pay.amount) })
        })

        return (
            <div>
                {!isMainComponent ?
                    <BackButton onclick={() => pushPath('/loyalty', null)} />
                    : null}
                <div>
                    <Heading showSearch={true} title="Transaction" subTitle="Overview" merchantInfo={merchantInfo} />
                    <div className={styles['fr-history-div']} style={{ background: themeColors && themeColors.themeColor }}>
                        <div className={styles['fr-history-sub-div']}>
                            <span style={{ fontSize: 36 }}>{transactions && transactions.length || 0}</span>
                            <div className={styles['fr-history-inner-div']}>
                                <span>Total</span>
                                <span>number of transactions</span>
                            </div>
                        </div>
                        <div className={styles['fr-vr-pipe']}>

                        </div>
                        <div className={styles['fr-history-sub-div']}>
                            <span style={{ fontSize: 36 }}>{transactions && <span><sup>&#8377;</sup>{Intl.NumberFormat('en-IN').format(parseInt(Pamount))}</span> || 0}</span>
                            <div className={styles['fr-history-inner-div']}>
                                <span>Total</span>
                                <span>Amount before tax value</span>
                            </div>
                        </div>
                        <div className={styles['fr-vr-pipe']}>

                        </div>
                        <div className={styles['fr-history-sub-div']}>
                            <span style={{ fontSize: 36 }}>{transactions && <span><sup>&#8377;</sup>{Intl.NumberFormat('en-IN').format(paid)}</span> || 0}</span>
                            <div className={styles['fr-history-inner-div']}>
                                <span>Total</span>
                                <span>Amount Paid</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Heading showSearch={false} title="All" subTitle="Transactions" merchantInfo={merchantInfo} />
                    <div className={styles['fr-history-list']} style={{ borderRight: '1px solid #000' }}>
                        <table>
                            <tr>
                                <th>Invoice<br></br>Date</th>
                                <th>Brand</th>
                                <th>Invoice<br></br>Number</th>
                                <th>Amount<br></br> before Tax</th>
                                <th>Total<br></br>GST</th>
                                <th>Net<br></br>Amount</th>
                                <th style={{ textAlign: 'center', padding: '5px' }} >
                                    Payment Section
                                    <hr></hr>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                            <div style={{ fontWeight: 900 }}>Amount</div>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                            <div style={{ fontWeight: 900 }}>Date</div>
                                        </div>
                                    </div>
                                </th>

                                <th>Invoice<br></br> Link</th>
                            </tr>
                            {transactions &&
                                transactions.map((item, index) => {
                                    var type = item.type === "B" ? 'Benefit' : item.type === "R" ? "Referral" : "Loyalty";
                                    var classType = item.type === "B" ? 'fr-type-benefit' : item.type === "R" ? "fr-type-ref" : "fr-type-loyalty";
                                    var classPoints = item.type === "B" ? 'fr-red-text' : "fr-green-text";
                                    var symbol = item.type === "B" ? '-' : "+";
                                    return (
                                        <tr key={index}>
                                            {/* <td><span className={styles[classType]}>{type}</span></td>
<td className={`${styles['fr-text']} points-date`}>{item.date}</td>
<td className={`${styles[classPoints]} points-text`}>{symbol}{item.points}</td>
<td className={`${styles[classPoints]} points-status`}>{item.status}</td> */}

                                            <td className={`${styles['fr-table_data']}`} style={{ whiteSpace: 'nowrap' }}>{item.date}</td>
                                            <td className={`${styles['fr-table_data']}`}>{item.merchantName}</td>
                                            <td className={styles['fr-table_data']}>{item.invoiceNumber}</td>
                                            <td className={`${styles['fr-table_data']}`} style={{ textAlign: 'right' }}>{item.amount}</td>
                                            <td className={`${styles['fr-table_data']}`} style={{ textAlign: 'right' }}>{parseFloat(item.tax).toFixed(2)}</td>
                                            <td className={`${styles['fr-table_data']}`} style={{ textAlign: 'right' }}>{item.netAmount}</td>
                                            <td className={`${styles['fr-table_data']}`} style={{ padding: '10px 0' }} >
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {item.payments && item.payments.map((pay) => {
                                                        return <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'right' }}>
                                                            <div style={{ flex: 1, width: '35%', borderRight: '1px solid #000', }}>
                                                                <div>{pay.amount}</div>
                                                            </div>
                                                            <div style={{ flex: 1, borderRight: '1px solid #000', whiteSpace: "nowrap" }}>
                                                                <div>{pay.date.date && pay.date.date.split(' ')[0]}</div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </td>
                                            <td className={`${styles[classPoints, 'fr-table_data']} `}>{item && item.invoicePdfUrl ? <a href={item.invoicePdfUrl} download>
                                                <img src={getImage('download_icon.png')} style={{ height: '23px', width: '23px' }} alt="download_image" target="_blank" /></a> : null}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {transactions &&
                                transactions.length === 0 && (
                                    <tr>
                                        <td colSpan="5">
                                            No Data Found
                                        </td>
                                    </tr>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

