import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import getImage from '../../../images'
import styles from './selectedGift.css'
import commonStyles from '../../../styles/gifting.css';
import PaymentButton from '../../common/PaymentButton';

function Offers(props) {
  const denominationConfig = useSelector(state => state.session.denominationConfig)
  const navigation = useSelector(state => state.navigation)
  const gifting = useSelector(state => state.gifting)
  const [selectedCard, setselectedCard] = useState([])

  const { offerList, currencyInfo } = denominationConfig?.data || {}

  const handleCrard = (params) => {
    if (selectedCard.offerId === params.offerId) {
      setselectedCard([])
    }
    else
      setselectedCard(params)
  }

  return (
    <div style={{
      display: 'flex', flexWrap: 'wrap', alignSelf: 'center'
    }}>
      <div style={{
        display: 'flex', flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}>


        {offerList?.map((item, index) =>
          <div
            key={index}
            className={styles['gft-offers-card']}
          // onClick={() => handleCrard(item)}
          >
            {
              (parseInt(gifting.giftDetails.amount) === parseInt(item.minimumBillAmount))
                ?
                <img
                  className={commonStyles['fr-tick-mark-img']}
                  src={(parseInt(gifting.giftDetails.amount) === parseInt(item.minimumBillAmount)) ? getImage('green_tick.svg') : getImage('grey_tick.svg')} alt="Img"
                />
                :
                null
            }

            <div className={styles['gft-subcard']}  >
              <text
                style={{ font: 'normal normal bold 26px/19px Open Sans' }}>
                {
                  item.offerType === 'flat'
                    ?
                    currencyInfo?.symbol + item.value
                    :
                    item.value + '%'
                }
              </text>

              <text
                style={{
                  fontSize: 14, fontfamily: 'Open Sans',
                }}>
                {item.offerType}
              </text>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
              <text>
                {item.name}
              </text>
              {/* <text >
                Get discount
                &nbsp;{currencyInfo?.symbol}&nbsp;
                {item.value}&nbsp;
                when you purchase a gift card worth
                &nbsp;{currencyInfo?.symbol}&nbsp;
                {item.minimumBillAmount}
              </text> */}
            </div>
          </div>
        )}
      </div>
      {/* <PaymentButton /> */}
    </div>
  )
}

export default Offers