
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserDetails, redeemFreebie } from '../reducers/loyalty/loyalty';
import { pushPath } from '../reducers/navigation/navigation'
import BenefitPage from '../components/BenefitPage'


class Benefit extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    constructor(props) {
        super(props)
        this.state = {
            authToken: this.props.authToken,
            merchantAccessToken: this.props.merchantAccessToken,
        }
    }

    render() {

        const { redeemFreebie, loyalty, navigation, icons, themeColors } = this.props;
        const { params } = navigation;
        const { freebie, type } = params;
        const { userDetails, userPoints, redeemedFreebie ,configuration } = loyalty;
        const { pointsIcon } = icons || {};

        const textColor = "#FFF";

        return (
            <BenefitPage
                configuration={configuration}
                userDetails={userDetails}
                textColor={textColor}
                userPoints={userPoints}
                pushPath={this.props.pushPath}
                redeemFreebie={redeemFreebie}
                freebie={freebie}
                type={type}
                redeemedFreebie={redeemedFreebie}
                icon={pointsIcon}
                themeColors={themeColors}
            />
        );
    }
}

const mapState = (state, ownprops) => {
    const { icons, themeColors } = state.theme;
    return {
        loyalty: state.loyalty,
        navigation: state.navigation,
        icons,
        themeColors,
    };
}

const mapDispatch = {
    getUserDetails,
    redeemFreebie,
    pushPath,
}

export default connect(mapState, mapDispatch)(Benefit);
