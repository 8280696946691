import React, { Component, useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { pushPath } from '../../reducers/navigation';
import styles from '../../styles/gifting.css';

function Loader() {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      // dispatch(pushPath('/gifting'))
    }, 1000);
  }, [])


  return (
    <div style={{ display: 'flex', minHeight: '100vh', minWidth: '100vw', justifyContent: 'center', alignItems: 'center' }}>
      {/* <span className={styles['fr-modal-sub-title']} style={{ fontSize: 20 }}>Your Gifts are</span> */}
      <span className={styles['fr-modal-title']}>Loading...</span>
    </div>
  )
}
export default Loader