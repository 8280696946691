import React, { Component } from 'react';
import { connect } from 'react-redux';
import PointHistoryPage from '../components/PointsHistoryPage';
import { getReferrals } from '../reducers/pointshistory/pointshistory';
import { getUserLoyaltyInfo, getProfile,getCustomeCss } from '../reducers/referral/referral';
import { intialize } from "../reducers/session/session";
import HistoryPointsBalancePage from '../components/HistoryPointsBalancePage';

class PointsHistory extends Component {

    constructor(props) {
        super(props)
        this.props.intialize(this.props.merchantAccessToken, this.props.authToken);
    }

    componentDidMount() {
        this.props.getReferrals();
        this.props.getCustomeCss();
        if (this.props.isRoot) {
            this.props.getUserLoyaltyInfo();
            this.props.getProfile();
        }
    }
    render() {
        const { referrals, basicConfig, isRoot, loyaltyInfo, userInfo,customeCss } = this.props;
        return (
            <div>
                {isRoot && <HistoryPointsBalancePage loyaltyInfo={loyaltyInfo} userInfo={userInfo} />}
                <PointHistoryPage transactions={referrals} basicConfig={basicConfig} customeCss={customeCss}/>
            </div>
        );
    }
}

const mapState = (state, ownprops) => {
    return {
        referrals: state.pointshistory.referrals,
        basicConfig: state.referral.basicConfig,
        loyaltyInfo: state.referral.loyaltyInfo,
        userInfo: state.referral.userInfo,
        customeCss:state.referral.customeCss
    };
}

const mapDispatch = {
    getReferrals,
    intialize,
    getUserLoyaltyInfo,
    getProfile,
    getCustomeCss
}

export default connect(mapState, mapDispatch)(PointsHistory);
