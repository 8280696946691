import apiClient from '../api';
import { setLoading } from './loader';


const initialState = {
  horizontalStyle: null,
};
const THEME_ERROR = 'giftingprogram/THEME_ERROR';

const SET_THEME = 'giftingprogram/SET_THEME';
const CATEGORY_THEMES_LIST = 'giftingprogram/CATEGORY_THEMES_LIST';

const displayError = (title, message, loading = 'loading') => ({
  type: THEME_ERROR,
  title,
  message,
  loading,
});

export const setTheme = (horizontalStyle) => async (dispatch, getState) => {
  await dispatch({
    type: SET_THEME,
    horizontalStyle
  });
}

export const getThemesList = () => async (dispatch, getState) => {
  const state = getState();
  const { authToken, merchantAccessToken, baseUrl } = state.session;
  try {
    dispatch(setLoading(true))
    const response = await apiClient.get(apiClient.Urls.themesList(baseUrl), {
      // authToken,
      merchantAccessToken,
    });
    if (!!response) {
      dispatch({
        type: CATEGORY_THEMES_LIST,
        payload: response
      });
      dispatch(setLoading(false))
    } else {
      dispatch(setLoading(false))
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(displayError('EXCEPTION', e.message));
  }
};


const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case THEME_ERROR: {
      return {
        ...state,
        [action.loading]: false,
        error: {
          title: action.title,
          message: action.message,
        }
      }
    }

    case CATEGORY_THEMES_LIST: {
      return {
        ...state,
        themesList: action.payload
      }
    }

    case SET_THEME: {
      return {
        ...state,
        horizontalStyle: action.horizontalStyle
      }
    }
    default: return state;
  }
}
export default themeReducer;