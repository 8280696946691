import React, { Component } from 'react'
import { connect } from 'react-redux';
import styles from '../styles/loyalty.css';

class ContentBox extends Component {
    render() {
        const { contentBox } = this.props;
        const bgColor = contentBox && contentBox.backgroundColor;
        const color = contentBox && contentBox.textColor;
        const content = contentBox && contentBox.content;

        return (
            content &&
            <div className={`${styles['earnRateCard']} fr-content-card`} style={{ background: bgColor || '#131313', color: color || '#FFF' }}>
                <div dangerouslySetInnerHTML={{ __html: content || '' }}></div>
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        contentBox: state.theme && state.theme.contentBox,
    };
}

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(ContentBox);