import apiClient from "../../api";

const initialState = {
    horizontalStyle: null,
    redirectUrls: null,
    successLabels: null,
    themeColors: null,
    icons: null,
    earningRateBox: null,
    conversionRateBox: null,
    tierProgress: null,
    loyaltyKnowMore: null,
    benefitStyles: null,
    customCss: '',
    overallTierProgress: '',
    contentBox: null,
    signUpConfiguration: null,
    loyaltyLayoutConfig: null,
    loyaltyLayoutContent: null,
    basefreebiestemplate: '',
    lightcurrentcard: '',
};

const SET_THEME = 'theme/SET_THEME';
const REDIRECT_URLS = 'theme/REDIRECT_URLS';
const THEME_COLORS = 'theme/THEME_COLORS';
const ICONS = 'theme/ICONS';
const EARNING_RATE_BOX = 'theme/EARNING_RATE_BOX';
const CONVERSION_RATE_BOX = 'theme/CONVERSION_RATE_BOX';
const CONTENT_BOX = 'theme/CONTENT_BOX';
const TIER_PROGRESS = 'theme/TIER_PROGRESS';
const OVERALL_TIER_PROGRESS = 'theme/OVERALL_TIER_PROGRESS';
const LOYALTY_KNOW_MORE = 'theme/LOYALTY_KNOW_MORE';
const BENEFIT_STYLES = 'theme/BENEFIT_STYLES';
const CUSTOM_CSS = 'theme/CUSTOM_CSS';
const SUCCESS_LABELS = 'theme/SUCCESS_LABELS';
const LOYALTYCARD_LABELS = 'theme/LOYALTYCARD_LABELS';
const GET_SIGNUP_TEMPLATE = 'loyaltyprogram/GET_SIGNUP_TEMPLATE';
const LOYALTY_LAYOUT_CONFIG = 'theme/LOYALTY_LAYOUT_CONFIG';
const LOYALTY_LAYOUT_CONTENT = 'theme/LOYALTY_LAYOUT_CONTENT';
const BASEFREEBIES_TEMPLE_CONFIG = 'theme/BASEFREEBIES_TEMPLE_CONFIG';
const LIGHTCURRENT_CARD_CONFIG = 'theme/LIGHTCURRENT_CARD_CONFIG'

export const setTheme = (horizontalStyle) => async (dispatch, getState) => {

    await dispatch({
        type: SET_THEME,
        horizontalStyle
    });
}

export const getThemeConfig = () => async (dispatch, getState) => {
    try {
        dispatch(getRedirectUrls());
        dispatch(getSuccessLabels());
        dispatch(getThemeColors());
        dispatch(getIcons());
        dispatch(getEarningRateBoxTheme());
        dispatch(getConversionRateBoxTheme());
        dispatch(getTierProgressInfo());
        dispatch(getOverTierProgressInfo());
        dispatch(getKnowMoreInfo());
        dispatch(getBenefitStyles());
        dispatch(getCustomCss());
        dispatch(getContentBoxInfo());
        dispatch(getSignUpTemplate())
        dispatch(getloyaltyCardLabels());
        dispatch(getBaseFreebiesTemplate());
        dispatch(getLightCurrentCard());
    } catch (error) {
    }
}

export const getLoyaltyPageConfig = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'loyaltyPageConfig',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: LOYALTY_LAYOUT_CONFIG,
                loyaltyLayoutConfig: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getBaseFreebiesTemplate = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'basefreebiestemplate',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: BASEFREEBIES_TEMPLE_CONFIG,
                basefreebiestemplate: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getLightCurrentCard = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'lightcurrentcard',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: LIGHTCURRENT_CARD_CONFIG,
                lightcurrentcard: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getloyaltyPageLayout = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'loyaltyPageLayout',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        console.log(response, "response loyaltyLayoutContent");
        if (response.success) {
            dispatch({
                type: LOYALTY_LAYOUT_CONTENT,
                loyaltyLayoutContent: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};


export const getRedirectUrls = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'redirectUrls',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });


        if (response.success) {
            dispatch({
                type: REDIRECT_URLS,
                redirectUrls: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getSuccessLabels = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'successLabels',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });


        if (response.success) {
            dispatch({
                type: SUCCESS_LABELS,
                successLabels: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getThemeColors = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'themeColors',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        console.log(response, "response themeColors");
        if (response.success) {
            dispatch({
                type: THEME_COLORS,
                themeColors: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getloyaltyCardLabels = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'loyaltyCardLabels',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: LOYALTYCARD_LABELS,
                loyaltyCardLabels: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getCustomCss = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'customCss',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: CUSTOM_CSS,
                customCss: response.data && response.data.customCss,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getIcons = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'loyaltyIcons',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: ICONS,
                icons: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getEarningRateBoxTheme = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'earningRate',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: EARNING_RATE_BOX,
                earningRateBox: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getContentBoxInfo = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'contentBox',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: CONTENT_BOX,
                contentBox: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getConversionRateBoxTheme = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'conversionRate',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: CONVERSION_RATE_BOX,
                conversionRateBox: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getTierProgressInfo = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'tierProgress',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: TIER_PROGRESS,
                tierProgress: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};
export const getOverTierProgressInfo = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'overallTierProgress',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: OVERALL_TIER_PROGRESS,
                overallTierProgress: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getKnowMoreInfo = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'knowMore',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: LOYALTY_KNOW_MORE,
                loyaltyKnowMore: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getBenefitStyles = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'benefitStyles',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: BENEFIT_STYLES,
                benefitStyles: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

export const getSignUpTemplate = () => async (dispatch, getState) => {

    try {
        const state = getState();
        const { merchantAccessToken } = state.session;
        const response = await apiClient.get(apiClient.Urls.themeConfig, {
            model: 'signUpConfiguration',
            template: 'loyalty-sdk',
            merchantAccessToken,
        });
        if (response.success) {
            dispatch({
                type: GET_SIGNUP_TEMPLATE,
                signUpConfiguration: response.data,
            });
        } else {
        }
    } catch (error) {
    }
};

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_THEME: {
            return {
                ...state,
                horizontalStyle: action.horizontalStyle
            }
        }
        case REDIRECT_URLS: {
            return {
                ...state,
                redirectUrls: action.redirectUrls,
            }
        }
        case SUCCESS_LABELS: {
            return {
                ...state,
                successLabels: action.successLabels,
            }
        }
        case THEME_COLORS: {
            return {
                ...state,
                themeColors: action.themeColors,
            }
        }
        case ICONS: {
            return {
                ...state,
                icons: action.icons,
            }
        }
        case CUSTOM_CSS: {
            return {
                ...state,
                customCss: action.customCss,
            }
        }
        case EARNING_RATE_BOX: {
            return {
                ...state,
                earningRateBox: action.earningRateBox
            }
        }
        case CONVERSION_RATE_BOX: {
            return {
                ...state,
                conversionRateBox: action.conversionRateBox
            }
        }
        case CONTENT_BOX: {
            return {
                ...state,
                contentBox: action.contentBox
            }
        }
        case LOYALTYCARD_LABELS: {
            return {
                ...state,
                loyaltyCardLabels: action.loyaltyCardLabels
            }
        }
        case TIER_PROGRESS: {
            return {
                ...state,
                tierProgress: action.tierProgress
            }
        }

        case OVERALL_TIER_PROGRESS: {
            return {
                ...state,
                overallTierProgress: action.overallTierProgress
            }
        }

        case LOYALTY_KNOW_MORE: {
            return {
                ...state,
                loyaltyKnowMore: action.loyaltyKnowMore
            }
        }
        case BENEFIT_STYLES: {
            return {
                ...state,
                benefitStyles: action.benefitStyles
            }
        }
        case LOYALTY_LAYOUT_CONFIG: {
            return {
                ...state,
                loyaltyLayoutConfig: action.loyaltyLayoutConfig,
            }
        }
        case BASEFREEBIES_TEMPLE_CONFIG: {
            return {
                ...state,
                basefreebiestemplate: action.basefreebiestemplate,
            }
        }
        case LIGHTCURRENT_CARD_CONFIG: {
            return {
                ...state,
                lightcurrentcard: action.lightcurrentcard,
            }
        }
        case LOYALTY_LAYOUT_CONTENT: {
            return {
                ...state,
                loyaltyLayoutContent: action.loyaltyLayoutContent,
            }
        }

        case GET_SIGNUP_TEMPLATE: {
            return {
                ...state,
                signUpConfiguration: action.signUpConfiguration
            }
        }

        default: return state;
    }

}


export default themeReducer;