import React, { Component, useState, useEffect } from 'react';
import getImage from "../../../images";
import styles from '../../../styles/gifting.css';
import { useDispatch, useSelector } from 'react-redux'
import { pushPath } from '../../../reducers/navigation';
import BackButton from '../../common/Button.Back';
import ProceedButton from '../../common/Button.Proceed';
import { placeOrderAction } from '../../../reducers/placeOrder';
import FailureModal from '../../common/FailureModal';
import PaymentSubmit from './PaymentSubmit';
import MessageModal from '../../common/MessageModal';

export const GiftingSummary = (props) => {
  const dispatch = useDispatch()
  const navigation = useSelector(state => state.navigation)
  const giftDetails = useSelector(state => state.gifting.giftDetails)
  const placeOrder = useSelector(state => state.placeOrder)
  const denominationConfig = useSelector(state => state.session.denominationConfig)
  const [isModalOpen, setisModalOpen] = useState(false)
  const [messageModalStatus, setmessageModalStatus] = useState({
    status: false,
    message: ''
  })
  const { config, currencyInfo, offerList } = denominationConfig?.data || {}


  const handleBack = () => {
    dispatch(pushPath('/gift-details', navigation.params))
  }
  const handleProceed = () => {
    dispatch(placeOrderAction(result => {
      if (result.success === false || !!result.message) {
        setmessageModalStatus({
          status: true,
          message: result.message
        })

      }
    }))
  }


  useEffect(() => {
    const { transactionStatus } = placeOrder

    if (transactionStatus?.paymentStatus === 'failure') {
      handlesetisModalOpen(true)
    }
    else if (transactionStatus?.paymentStatus === 'success') {
      dispatch(pushPath('/order-success', navigation.params))
    }
    else {

    }
  }, [placeOrder.transactionStatus])

  const handlesetisModalOpen = (param) => {
    setisModalOpen(param)
  }

  const handleMessageModal = (param) => {
    setmessageModalStatus(param)
  }
  return (
    <div style={{ padding: 15, position: 'relative' }}>
      <FailureModal
        isModalOpen={isModalOpen}
        handlesetisModalOpen={handlesetisModalOpen}
      />
      <BackButton
        onclick={handleBack}
        buttonText='Back to Card Details'
      />
      <MessageModal
        messageModalStatus={messageModalStatus}
        handleMessageModal={handleMessageModal}
      />
      <hr style={{ borderColor: '#E8E8E8', opacity: 0.4 }} />

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{
          display: 'flex', flexDirection: 'column',
          alignItems: 'flex-start',
          fontSize: 14, fontfamily: 'Open Sans',
          textAlign: 'center',
          fontweight: 'bold',
          marginTop: 30,
          marginBottom: 60
        }}>
          <img
            src={giftDetails.theme}
            style={{
              width: 347, height: 186, borderRadius: 10,
              boxShadow: '0px 20px 30px -15px rgba(0,0,0,0.86)'
            }}
            alt="Img" />
        </div>

        <div className={styles['gft-grid-container']} >

          <div className={styles['gft-summary-label-div']}>
            <text >Gifting Amount</text>
            <text
              className={styles['gft-label-bold']}
            >{currencyInfo?.symbol}{giftDetails?.amount}</text>
          </div>

          {
            giftDetails?.type !== 'self'
              ?
              <div className={styles['gft-summary-label-div']}>
                <text >Your Message</text>
                <text
                  className={styles['gft-label-bold']}
                >{giftDetails?.message}</text>
              </div>
              : null
          }

          <div />
          {giftDetails.type === 'self' ?
            <div />
            : null}

          <div className={styles['gft-summary-label-div']}>
            <text >Your Name</text>
            <text
              className={styles['gft-label-bold']}
            >{giftDetails?.username}</text>
          </div>
          <div className={styles['gft-summary-label-div']}>
            <text >Your email</text>
            <text
              className={styles['gft-label-bold']}
            >{giftDetails?.email}</text>
          </div>
          <div className={styles['gft-summary-label-div']}>
            <text >Your Phone Number</text>
            <text
              className={styles['gft-label-bold']}
            >{giftDetails?.customerPhone}</text>
          </div>

          {
            giftDetails?.type !== 'self'
              ?
              <div className={styles['gft-summary-label-div']}>
                <text >Receiver's Name</text>
                <text
                  className={styles['gft-label-bold']}
                >{giftDetails?.receiverName}</text>
              </div>
              :
              null}
          {
            giftDetails?.type !== 'self'
              ?
              <div className={styles['gft-summary-label-div']}>
                <text >Receiver's email</text>
                <text
                  className={styles['gft-label-bold']}
                >{giftDetails?.receiverEmail}</text>
              </div>
              :
              null}
          {
            giftDetails?.type !== 'self'
              ?
              <div className={styles['gft-summary-label-div']}>
                <text >Receiver's Phone Number</text>
                <text
                  className={styles['gft-label-bold']}
                >{giftDetails?.receiverPhoneNumber}</text>
              </div>
              :
              null}
        </div>

        <div className={styles['gft-summary-label-div']}>
          <text >Gift card will be delivered to the above email and mobile number.</text>
        </div>
        <div style={{ alignSelf: 'center' }}>
          <ProceedButton onclick={handleProceed} />
        </div>

      </div>
      {placeOrder.pgResponse && <PaymentSubmit pgDetails={placeOrder.pgResponse} />}
    </div>
  )
}
export default GiftingSummary