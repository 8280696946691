let DEDICATED_BASE_URL = 'https://www.froogal.in/';


const URLS = {
    getProfile: 'dedicated-api/referral/user/profile',
    getReferrals: "dedicated-api/referral/user/referrals",
    getMilestones: "dedicated-api/referral/user/milestones",
    getUser: "dedicated-api/loyalty/v2/default/user/details",
    config: 'dedicated-api/config',
    themeConfig: 'dedicated-api/content',
    getFreebie: (freebieId) => "dedicated-api/loyalty/v2/freebies/" + freebieId
};

const apiClient = {
    Urls: URLS,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    setBaseUrl(baseUrl) {

        if (baseUrl[baseUrl.length - 1] !== '/') {
            baseUrl = baseUrl + '/';
        }

        DEDICATED_BASE_URL = baseUrl;

    },
    make(url, method, params = {}) {
        params.merchantAccessToken = params.merchantAccessToken || window.merchantAccessToken;

        const reqUrl = method === 'GET' ? `${url}?${new URLSearchParams(params).toString()}` : url;
        return fetch(DEDICATED_BASE_URL + reqUrl, {
            method,
            headers: {
                ...apiClient.headers, 'Authorization': `Bearer ${params.authToken}`
            },
            ...(method === 'GET' ? {} : { body: JSON.stringify(params) }),
        }).then(response => response.json());
    },

    post(url, params) {
        return this.make(url, 'POST', params);
    },

    get(url, params) {
        return this.make(url, 'GET', params);
    },

}

export default apiClient;
