import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import getImage from "../images";
import styles from '../styles/loyalty.css';
import { closeIntroModal } from '../reducers/session/session'

class LoyaltyIntro extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { isModalOpen, closeIntroModal } = this.props;


        return (
            <Modal
                isOpen={isModalOpen}
                className={styles['modal-custom-styles']}
                contentLabel="Intro"
            >
                <div>
                    <div className={styles['fr-signup-head']}>
                        <span className={styles['fr-modal-sub-title']} style={{ fontSize: 20 }}>Presenting</span>
                        <span className={styles['fr-modal-title']}>Loyalty Program!</span>
                    </div>
                    <div style={{ padding: '0px 10px', marginTop: 15 }}>
                        <p style={{ fontSize: 15, textAlign: 'center' }}>We have built this program keeping our customers in mind. This program offers exciting rewards, benefits & more!</p>
                    </div>
                    <div style={{ padding: '0px 10px' }}>
                        <p style={{ fontSize: 15, textAlign: 'center' }}>We cant wait to show you what we have in store for you so lets get started!</p>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <button onClick={() => {
                        closeIntroModal();
                    }}
                        className={`${styles['fr-redeem-btn']} fr-btn-cutom`} style={{ width: '100%', height: 40 }}>Lets Go!<img src={getImage('arrow.svg')} alt="Img" /></button>
                </div>
            </Modal>
        )
    }
}
const mapState = (state, ownprops) => {
    return {
        isModalOpen: state.session.showIntroduction,
    };
}

const mapDispatch = {
    closeIntroModal,
}

export default connect(mapState, mapDispatch)(LoyaltyIntro);
