let baseUrl = null;

export function setBaseUrl(_baseUrl) {
    baseUrl = _baseUrl.replace(/\/$/g, '');
}

function getImageUsingRequire(imageName) {
    const map = {
        'bottle.svg': require('./images/bottle.svg'),
        'clock.svg': require('./images/clock.svg'),
        'locked.svg': require('./images/locked.svg'),
        'unlocked.svg': require('./images/unlocked.svg'),
        'cup.svg': require('./images/cup.svg'),
        'direction.svg': require('./images/direction.svg'),
        'facebook.png': require('./images/facebook.png'),
        'gift.svg': require('./images/gift.svg'),
        'iconstwitterx.png': require('./images/iconstwitterx.png'),
        'iconsinstagram.png': require('./images/iconsinstagram.png'),
        'iconsmessenger.png': require('./images/iconsmessenger.png'),
        'iconsgmail.png': require('./images/iconsgmail.png'),
        'email.png': require('./images/email.png'),
        'whatsapp.png': require('./images/whatsapp.png'),
        'friend_get_bg.svg': require('./images/friend_get_bg.svg'),
        'you_get_bg.svg': require('./images/you_get_bg.svg'),
        'tick.svg': require('./images/tick.svg'),
        'copy.svg': require('./images/copy.svg'),
        'avathar.png': require('./images/avathar.png'),
        'avathar_gym.png': require('./images/avathar_gym.png'),
        'iPhone.png': require('./images/iPhone.png'),
        'airpods.png': require('./images/airpods.png'),
        'airpods1.png': require('./images/airpods1.png'),
        'shaker.png': require('./images/shaker.png'),
        'hoodie.png': require('./images/hoodie.png'),
        'bag.jpg': require('./images/bag.jpg'),
        "what_u_get.png": require('./images/what_u_get.png'),
        "what_friend_get.png": require('./images/what_friend_get.png'),
        "refer-block-bg.png": require('./images/refer-block-bg.png'),
    };
    return map[imageName] || require(`./images/${imageName}`);
}

export default function getImage(imageName) {
    if (baseUrl) {
        return `${baseUrl}/${imageName}`;
    } else {
        return getImageUsingRequire(imageName);
    }
}
