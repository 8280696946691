import React, { Component } from 'react'
import { connect } from 'react-redux';
import MilestonesPage from '../components/MilestonesPage';
import { getCouponCode } from '../reducers/referral/referral'

class Milestones extends Component {
    render() {
        const { userInfo, milestones, basicConfig } = this.props.referral;

        return (
            <MilestonesPage userInfo={userInfo} getCouponCode={this.props.getCouponCode} basicConfig={basicConfig} milestones={milestones} mileStoneType={this.props.mileStoneType} />
        )
    }
}
const mapState = (state, ownprops) => {
    return {
        referral: state.referral,
    };
}

const mapDispatch = {
    getCouponCode
}

export default connect(mapState, mapDispatch)(Milestones);