import React, { Component } from 'react'
import LeaderBoardPage from '../components/LeaderBoardPage';

class LeaderBoard extends Component {
    render() {
        return (
            <LeaderBoardPage />
        )
    }
}

export default LeaderBoard;