import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getActiveFreebies, getExpiredFreebies } from '../reducers/loyalty/loyalty';
import { initializeBenefits } from '../reducers/session/session';
import Heading from '../common/Heading';
import GiftCardHistoryComponent from '../components/GiftCardHistoryComponent';

class GiftCardHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
            isLoading: true,
        };

        this.props.initializeBenefits(this.props.merchantAccessToken, this.props.authToken, this.props.loyaltyId);
    }

    async componentDidMount() {

        await this.props.initializeBenefits(this.props.merchantAccessToken, this.props.authToken, this.props.loyaltyId);
        this.props.getActiveFreebies();
        // this.props.getExpiredFreebies();
        window.addEventListener('resize', this.handleResize);
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    render() {
        const { themeColors } = this.props;
        const { activeFreebies, expiredFreebies } = this.props.loyalty;
        const isDeskTop = this.state.windowWidth && this.state.windowWidth >= 768

        if (this.state.isLoading) {
            return (
                <div>
                    <Heading title="My Gift Cards" />
                    <p>Loading...</p>
                </div>
            );
        }
        if (activeFreebies && activeFreebies.length > 0) {
            return (
                <div>
                    <Heading title="My Gift Cards" />
                    <GiftCardHistoryComponent activeFreebies={activeFreebies} themeColors={themeColors} isDeskTop={isDeskTop} />
                </div>
            );
        } else {
            return (
                <div>
                    <Heading title="My Gift Cards" />
                    <h4>Gift Cards Not available.</h4>
                </div>
            );
        }
    }
}
const mapState = (state, ownprops) => {
    const { themeColors } = state.theme;
    return {
        loyalty: state.loyalty,
        themeColors,
    };
}

const mapDispatch = {
    getActiveFreebies,
    getExpiredFreebies,
    initializeBenefits
}

export default connect(mapState, mapDispatch)(GiftCardHistory);
