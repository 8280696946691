import apiClient from '../api';

const initialState = {

};

const USER_INFO = 'user/USER_INFO';
const ERROR = "gifting/ERROR";

const displayError = (title, message, loading = 'loading') => ({
  type: ERROR,
  title,
  message,
  loading,
});

export const getUserInfoAction = () => async (dispatch, getState) => {
  const state = getState();
  const { authToken, merchantAccessToken, baseUrl } = state.session;
  try {
    const response = await apiClient.get(apiClient.Urls.getProfile(baseUrl), {
      authToken,
      merchantAccessToken,
    });
    if (!!response) {
      dispatch({
        type: USER_INFO,
        payload: response
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};


const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO: {
      return {
        ...state,
        profile: action.payload
      }
    }

    case ERROR: {
      return {
        ...state,
        [action.loading]: false,
        error: {
          title: action.title,
          message: action.message,
        }
      }
    }

    default: return state;
  }
}
export default userReducer;