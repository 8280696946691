import React, { useState, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input';
import styles from '../../../styles/gifting.css';
import { useSelector, useDispatch } from 'react-redux';
import { setGiftDetails } from '../../../reducers/gifting';
import ContinueButton from '../../common/Button.Continue';
import { pushPath } from '../../../reducers/navigation';

function Step1Form(props) {
  const dispatch = useDispatch()
  const gifting = useSelector(state => state.gifting)
  const session = useSelector(state => state.session)
  const userProfile = useSelector(state => state.user.profile)
  const navigation = useSelector(state => state.navigation)

  const [phone, setPhone] = useState();

  const formatPhone = (param) => {
    const countryCode = param && formatPhoneNumberIntl(param).split(' ')[0];
    const formattedPhone = param && formatPhoneNumber(param).slice(1).split(' ').join('');
    const body = {
      mobileNumber: formattedPhone,
      countryCode,
    };
    return body;
  }
  useEffect(() => {
    const { name, email, phoneNumber, gender, birthday, anniversary, countryCode }
      = userProfile?.profile || {}

    console.log({ name, email, phoneNumber, gender, birthday, anniversary })
    if (!!session.authToken === true) {
      let format = formatPhone(`+${countryCode}${phoneNumber}`)
      dispatch(setGiftDetails({
        username: name,
        email: email,
        customerPhone: `+${countryCode}${phoneNumber}`,
        customerPhoneCountryCode: format.countryCode
      }))
    }
  }, [])



  const handleOnChangeText = (e) => {
    console.log(e.currentTarget)
    const { name, value } = e.currentTarget;
    dispatch(setGiftDetails({ [name]: value }))
  }

  const handleSenderPhoneInput = (param) => {
    let format = formatPhone(param)
    dispatch(setGiftDetails({
      countryCode: format.countryCode,
      customerPhone: param,
    }))

  }
  const handleRecepientPhoneInput = (param) => {
    let format = formatPhone(param)
    dispatch(setGiftDetails({
      receiverCountryCode: format.countryCode,
      receiverPhoneNumber: param,
    }))

  }

  const handleContinue = () => {
    let validation = props.validationFormTwo()
    // if (gifting.giftDetails.type === 'gift') {
    //   if (validation === true) {
    //     window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    //   }
    // }
    // else {
    //   if (validation === true) {
    //     window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    //   }
    // }


    if (validation === true) {
      dispatch(pushPath('/gift-summary', navigation.params))
    }


  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{}} className={styles['gft-container']}>
        {/* SENDER DETAILS */}

        <fieldset className={styles['gft-fieldset']}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', marginTop: 30, padding: 25, borderWidth: 0.5, borderRadius: 14, borderColor: '#00000034' }}>
          <legend className={styles['gft-fieldset-legend']} >Your Details</legend>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <text className={styles['gft-label']}>Name</text>
            <input className={styles['gft-input']}
              name='username'
              type='text'
              defaultValue=""
              value={gifting.giftDetails?.username}
              onChange={(e) => handleOnChangeText(e)}
              contentEditable={true}
            />
            <text className={styles['gft-warning-label']}>
              {props.errors['username']}
            </text>
          </div>

          <div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <text className={styles['gft-label']}>e-mail</text>
              <input className={styles['gft-input']}
                name='email'
                type='email'
                defaultValue=""
                disabled={!!userProfile?.profile?.email === true ? true : false}
                value={gifting.giftDetails?.email}
                onChange={(e) => handleOnChangeText(e)}
                contentEditable={true}
              />
              <text className={styles['gft-warning-label']}>
                {props.errors['email']}
              </text>
            </div>

            <div className={styles['countryFlag']} style={{ display: 'flex', flexDirection: 'column' }}>
              <text className={styles['gft-label']}>Phone number</text>
              <PhoneInput
                name='customerPhone'
                disabled={!!userProfile?.profile?.customerPhone === true ? true : false}
                defaultCountry={session.location?.countryCode}
                value={gifting.giftDetails?.customerPhone}
                onChange={(e) => handleSenderPhoneInput(e)} />
              <text className={styles['gft-warning-label']}>
                {props.errors['customerPhone']}
              </text>
            </div>
          </div>

        </fieldset>

        {/* RECEIVER'S DETAILS */}
        {gifting.giftDetails.type === 'gift'
          ?
          <fieldset className={styles['gft-fieldset']}
            style={{
              display: 'flex',
              marginTop: window.innerWidth < 860 ? 60 : 30, padding: 25,
              flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around',
              borderWidth: 0.5, borderRadius: 14, borderColor: '#00000034'
            }}>
            <legend className={styles['gft-fieldset-legend']}>Receiver's Details</legend>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <text className={styles['gft-label']}>Receiver's name</text>
              <input className={styles['gft-input']}
                name='receiverName'
                type='text'
                defaultValue=""
                value={gifting.giftDetails?.receiverName}
                onChange={(e) => handleOnChangeText(e)}
              />
              <text className={styles['gft-warning-label']}>
                {props.errors['receiverName']}
              </text>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <text className={styles['gft-label']}>Receiver's e-mail</text>
              <input className={styles['gft-input']}
                name='receiverEmail'
                type='email'
                defaultValue=""
                value={gifting.giftDetails?.receiverEmail}
                onChange={(e) => handleOnChangeText(e)}
              />
              <text className={styles['gft-warning-label']}>
                {props.errors['receiverEmail']}
              </text>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <text className={styles['gft-label']}>Receiver's phone number</text>
              <div style={{ display: 'flex' }}>
                <PhoneInput
                  className={styles['countryFlag']}
                  name='receiverPhoneNumber'
                  // placeholder="Enter phone number"
                  defaultCountry={session.location?.countryCode}
                  value={gifting.giftDetails?.receiverPhoneNumber}
                  onChange={(e) => handleRecepientPhoneInput(e)} />
              </div>

              {/* <input className={styles['gft-input']}
              name='receiverPhoneNumber'
              type='number'
              defaultValue=""
              value={gifting.giftDetails?.receiverPhoneNumber}
              onChange={(e) => handleOnChangeText(e)}
            /> */}
              <text className={styles['gft-warning-label']}>
                {props.errors['receiverPhoneNumber']}
              </text>
            </div>

          </fieldset>
          :
          null
        }


      </div>
      <div style={{ alignSelf: 'center' }}>
        <ContinueButton
          actionTitle={'Continue'}
          action={() => handleContinue()} />
      </div>

    </div>
  )
}

Step1Form.propTypes = {

}

export default Step1Form

