import React, { Component } from 'react';
import getImage from "../images";
import styles from '../styles/loyalty.css';
import OtpInput from 'react-otp-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

export default class OtpForm1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            phoneNumber: '',
            otp: '',
            error: false,
            country: props.defaultCountry || 'IN',
            count: 15,
            toggle: false,
            data: null
        }
    }

    componentDidMount() {
        const phonenumber = this.props.preFillData && this.props.preFillData.filter((i) => i.slug == 'phoneNumber')
        if (this.props.preFillData && phonenumber.length > 0) {
            this.setState({ phoneNumber: phonenumber[0].value })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.toggle !== this.state.toggle) {
            let counter;
            if (this.state.toggle) {
                counter = setInterval(() => this.setState({ count: this.state.count - 1 }), 1000);
            }
            else {
                clearInterval(counter)
            }
        }
    }


    render() {

        const { getLoginOtp, verifyOtp, isOtpSent, isMultiCountryEnable, themeColors, status } = this.props;

        const onchange = (phoneNumber) => {
            if (isMultiCountryEnable) {
                this.setState({ phoneNumber: phoneNumber, error: false });
            }
            else {
                if (phoneNumber.length === 10) {
                    this.setState({ phoneNumber: phoneNumber, error: false });
                } else if (phoneNumber.length < 10) {
                    this.setState({ phoneNumber: phoneNumber, error: true });
                }
                else if (phoneNumber.length > 10) {
                }
            }
        }
        const { toggle } = this.state;

        return (
            <div style={{ backgroundColor: "#F7F8FA", padding: 10, height: "100%" }}>
                <div className={`${styles['fr-signup-head']} form-head`} style={{ marginBottom: 15 }}>
                    <span className={`${styles['fr-modal-title']} form-title`}>{"Please verify your phone number"}</span>
                    {/* <span className={`${styles['fr-modal-sub-title']} form-subtitle`}>{isOtpSent ? "" : "Please enter phone number to validate"}</span> */}
                </div>
                <div style={{}}>
                    {!toggle &&
                        <div style={{ marginBottom: 10, }}>
                            <div className={styles["fr-custom-field2"]} style={{ marginBottom: 10 }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <span className={styles["fr-custom-field-label2"]}>
                                        Mobile Number
                                    </span>
                                    <div className={styles["custome-field"]}  >
                                        <div className={styles["fr-custome-field-inner2"]} style={{ backgroundColor: "#FFFFFF" }}>
                                            <input placeholder="Please enter phone number" type="number" disabled={toggle} value={this.state.phoneNumber} onChange={(e) => { onchange(e.target.value) }} />
                                        </div>
                                        <img alt="Img" src={getImage('phone.svg')} />
                                    </div>
                                </div>
                            </div>
                            {this.state.error ? <span style={{ color: 'red', fontSize: 12, }}>Enter a valid mobile number</span> : null}
                        </div>}
                    {toggle ?
                        <div>
                            <div className={`${styles["flx_cntr"]} ${styles["flx_clm"]}`} >

                                <div>
                                    <span className={styles["fr_info_text"]}>Please enter the verfication code sent to </span>
                                    <div className={styles["flx_cntr"]} style={{ marginBottom: 30 }}>
                                        <span>{this.state.phoneNumber}</span>
                                        <button className={styles["fr_button_link"]} onClick={() => {
                                            onchange(this.state.phoneNumber);
                                            this.setState({ count: 15, toggle: false });
                                        }}>Change</button>
                                    </div>
                                </div>

                                <OtpInput
                                    value={this.state.otp}
                                    onChange={(otp) => { this.setState({ otp: otp }) }}
                                    numInputs={6}
                                    shouldAutoFocus={true}
                                    inputStyle={styles['fr-otp-input']}
                                />

                                <span style={{ color: 'red', fontSize: 12, textAlign: 'center' }}>{status && status.message || ''}</span>

                                <button onClick={() => {
                                    verifyOtp(this.state.phoneNumber, this.state.otp)
                                }}
                                    className={styles["fr-redeem-vrf-btn2"]} style={{ backgroundColor: themeColors && themeColors.buttonBgColor, color: themeColors && themeColors.buttonTextColor }}>
                                    Verify OTP
                                    <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowCircleRight} />
                                </button>
                            </div>
                            <div className={styles["flx_cntr"]} style={{ marginTop: 30 }}>
                                {this.state.count > 0 ?
                                    <span className={styles["fr_info_text"]}>Did't receive code? Resend in {this.state.count}</span> :
                                    <button className={styles["fr_button_link"]} onClick={e => {
                                        getLoginOtp(this.state.phoneNumber);
                                        this.setState({ count: 15 })
                                    }}>Did't receive code? Resend</button>}
                            </div>

                        </div>
                        :
                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                            <button
                                onClick={() => {
                                    if (!this.state.error && this.state.phoneNumber.length != 0) {
                                        getLoginOtp(this.state.phoneNumber);
                                        this.setState({ count: 15, toggle: true })
                                    }
                                }}
                                className={styles["fr-redeem-btn2"]}
                                style={{ backgroundColor: themeColors && themeColors.buttonBgColor, color: themeColors && themeColors.buttonTextColor }}
                            >
                                Get OTP<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowCircleRight} />
                            </button>
                        </div>}
                </div>

            </div>
        )
    }
}