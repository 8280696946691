import React from 'react';

const RowLayout = (props) => {
    const { sectionList, components, componentsMap } = props;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'space-between' }}>
            {sectionList?.map((i, index) => {
                return (
                    <RenderSection key={index} componentsMap={componentsMap} components={components.filter(item => item.section == i)} />
                )
            })}
        </div>
    )
}

const RenderSection = ({ components, componentsMap }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: window.innerWidth >= 768 ? 'row' : 'column',
            gap: 10, alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            {components?.map((item, index) => {
                const width = window.innerWidth >= 768 ? `${item.size}%` : '100%';
                return (
                    <div key={index} style={{ width }}>
                        {componentsMap(item)}
                    </div>
                )
            })}
        </div>
    )
}

export default RowLayout;