import apiClient from '../api';
import { setLoading } from './loader';

const initialState = {
  giftDetails: {
    type: 'gift', //mandatory

    /* TEST-DEV */
    // type: 'gift',
    // brandId: 3,
    // categoryId: 1,
    // giftingCardMasterId: 1,
    // themeId: 2,
    // message: 'fihfeifhefihfih',
    // amount: '520',
    // username: 'sameer',
    // email: 'sameer@gmail.com',
    // customerPhone: '+917030552747',
    // receiverName: 'receivername',
    // receiverEmail: 'tdtdtd@gsa.co',
    // receiverCountryCode: '+91',
    // receiverPhoneNumber: '+919876543456'
  }
};

const GET_CONFIG = "gifting/GET_CONFIG";
const GET_USER_DETAILS_SUCCESS = "gifting/GET_USER_DETAILS_SUCCESS";
const ERROR = "gifting/ERROR";
const SET_GIFT_DETAILS = "gifting/SET_GIFT_DETAILS";
const SELECTED_AMOUNT = "gifting/SELECTED_AMOUNT";
const ORDER_HISTORY = "gifting/ORDER_HISTORY";

export const setGiftDetails = (param) => ({
  type: SET_GIFT_DETAILS,
  payload: param,
});

export const setUserPoints = (userPoints) => ({
  type: SET_USER_POINTS,
  userPoints,
});




const displayError = (title, message, loading = 'loading') => ({
  type: ERROR,
  title,
  message,
  loading,
});



export const addAmountAction = (param) => async (dispatch, getState) => {
  const state = getState();
  const { authToken, merchantAccessToken, baseUrl } = state.session;
  const { amount, } = state.gifting.giftDetails;
  try {
    dispatch(setLoading(true))
    const response = await apiClient.post(apiClient.Urls.addAmount(baseUrl), {
      // authToken,
      merchantAccessToken,
      billAmount: parseFloat(amount)
    });
    if (!!response) {
      dispatch({
        type: SELECTED_AMOUNT,
        payload: response
      });
      dispatch(setLoading(false))
    } else {
      dispatch(setLoading(false))
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(displayError('EXCEPTION', e.message));
  }
};


export const getOrderHistoryAction = (param) => async (dispatch, getState) => {
  const state = getState();
  const { authToken, merchantAccessToken, baseUrl } = state.session;
  // let baseUrl = 'https://beta.froogal.in/dedicated-api/'
  try {
    dispatch(setLoading(true))
    const response = await apiClient.get(apiClient.Urls.orderHistory(baseUrl), {
      authToken,
      merchantAccessToken,
    });
    if (!!response) {
      dispatch({
        type: ORDER_HISTORY,
        payload: response
      });
      dispatch(setLoading(false))
    } else {
      dispatch(setLoading(false))
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(setLoading(false))
    dispatch(displayError('EXCEPTION', e.message));
  }
};



export default function (state = initialState, action) {
  switch (action.type) {

    case GET_CONFIG: {
      return {
        ...state,
        pointsLabel: action.pointsLabel,
        currency: action.currency,
        displayConversionRateComponent: action.displayConversionRateComponent,
        defaultCurrencyConversionRate: action.defaultCurrencyConversionRate,
        aboutProgramComponentInfo: action.aboutProgramComponentInfo
      }
    }

    case SELECTED_AMOUNT: {
      return {
        ...state,
        selectedAmount: action.payload
      };
    }

    case ORDER_HISTORY: {
      return {
        ...state,
        orderHistory: action.payload
      };
    }

    case SET_GIFT_DETAILS: {
      const a = state.giftDetails
      return {
        ...state,
        giftDetails: { ...a, ...action.payload }
      };
    }

    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetails: action.userDetails
      }
    }

    case ERROR: {
      return {
        ...state,
        [action.loading]: false,
        error: {
          title: action.title,
          message: action.message,
        }
      }
    }


    default: return state;
  }
}