import React, { Component } from 'react'
import { connect } from 'react-redux';
import styles from '../styles/loyalty.css';
import { getLoyaltyConfig } from '../reducers/loyalty/loyalty';

class ConversionCard extends Component {

    componentDidMount() {
        this.props.getLoyaltyConfig();
        window.scrollTo(0, 0);
    }

    render() {
        const { loyalty, conversionRateBox } = this.props;
        const { defaultCurrencyConversionRate, pointsLabel, currency } = loyalty;
        const bgColor = conversionRateBox && conversionRateBox.backgroundColor;
        const color = conversionRateBox && conversionRateBox.textColor;
        const disable = conversionRateBox && conversionRateBox.disable;

        if (disable == "1") {
            return null;
        }

        return (

            <div className={`${styles['currentConversion']} conversion-card`} style={{ background: bgColor || '#131313' }}>
                <p className={`${styles['mb-0']} ${styles['currentp']}`} style={{ color: color || '#FFFFFF' }}>current Conversion</p>
                <div className={styles['currentPoint']}>
                    <div>
                        <h2 style={{ color: color || '#FFFFFF' }}>{defaultCurrencyConversionRate}</h2>
                        <p style={{ color: color || '#FFFFFF' }}>{pointsLabel || "Points"}</p>
                    </div>
                    <div className={styles['equal']} style={{ color: color || '#FFFFFF' }}>=</div>
                    <div>
                        <h2 style={{ color: color || '#FFFFFF' }}><sup>{currency}</sup>1</h2>
                        <p style={{ color: color || '#FFFFFF' }}>Value</p>
                    </div>
                </div>
            </div>

        )
    }
}

const mapState = (state, ownprops) => {
    return {
        conversionRateBox: state.theme && state.theme.conversionRateBox,
        loyalty: state.loyalty
    };
}

const mapDispatch = {
    getLoyaltyConfig
}

export default connect(mapState, mapDispatch)(ConversionCard);