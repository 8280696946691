import React, { Component } from 'react';
import BenefitsList from './BenefitsList';
import { initializeBenefits } from "../reducers/session/session";
import { connect } from "react-redux";
import Benefit from './Benefit';

class ActiveBenefits extends Component {

    constructor(props) {
        super(props)
        this.props.initializeBenefits(this.props.merchantAccessToken, this.props.authToken, this.props.loyaltyId);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { navigation, customCss } = this.props;
        const { path } = navigation;

        return (
            <div>
                <style>{customCss}</style>
                {
                    path == "/freebie-details" ?
                        <Benefit /> :
                        <BenefitsList type="active" />}
            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    return {
        navigation: state.navigation,
        customCss: state.theme && state.theme.customCss,
    };
}

export default connect(mapState, { initializeBenefits })(ActiveBenefits);