import React, { Component } from 'react'
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'


export default class GiftCardHistoryDesktopItem extends Component {

    render() {
        const { item, index, themeColors, copiedUrlIndex, copiedPinIndex } = this.props

        const isEven = index % 2 === 0;
        const backgroundColor = isEven ? "#fff" : "#EFF4F7";
        const textColor = isEven ? "#000" : "#000"
        const expired = item.freebieItem?.isLive
        const urlType = item.freebieItem?.couponInfo && item.freebieItem?.couponInfo?.type
        const url = item.freebieItem?.couponInfo && item.freebieItem?.couponInfo?.voucherCode
        const pin = item.freebieItem?.couponInfo && item.freebieItem?.couponInfo?.pin
        const pinLconColor = copiedPinIndex === index
        const urlLconColor = copiedUrlIndex === index

        return (
            <div>
                <div style={{ width: "100%", display: "flex", backgroundColor, justifyContent: "space-between", alignItems: "center", padding: 20, opacity: !expired ? .6 : null }}>
                    <div style={{ color: "#fff", width: "20%", display: "flex", alignItems: "center" }}>
                        <img src={item.image} alt="Img" style={{ width: 50, height: 50, borderRadius: 50, objectFit: "cover" }} />
                        <span className={`${styles['head-feild-text']} `} style={{
                            color: textColor, display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                        }} >{item.name}</span>
                    </div>
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start", backgroundColor: "pick" }}>
                        <span className={`${styles['feild-text']}`} style={{ color: textColor }} >{item.freebieItem?.redeemedAt || "---"}</span>
                    </div >
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start" }}>
                        <span className={`${styles['feild-text']}`} style={{ color: textColor }}>{item.freebieItem?.expiryDate || "---"}</span>
                    </div >
                    <div style={{ width: "20%", display: "flex", justifyContent: "flex-start" }}>
                        <span className={`${styles['feild-text']}`} style={{ color: textColor }}>{parseFloat(item.freebieItem?.availableAmount)}</span>
                    </div >
                    {urlType == 'url' ?
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "20%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                                <button
                                    onClick={() => window.open(url, '_blank')}
                                    style={{ backgroundColor: themeColors?.buttonBgColor, borderStyle: "none", borderRadius: 5, border: '1px solid rgba(0,0,0,0.1)', padding: 10, width: "100%" }}
                                >
                                    <span className={`${styles['active-btn']}`}>Claim Voucher</span>
                                </button>
                            </div>
                        </div> :
                        urlType == 'urlPin' ?
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "20%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                                    <button
                                        onClick={() => window.open(url, '_blank')}
                                        style={{ backgroundColor: themeColors?.buttonBgColor, borderStyle: "none", borderRadius: 5, border: '1px solid rgba(0,0,0,0.1)', padding: 10, width: "100%" }}
                                    >
                                        <span className={`${styles['active-btn']}`}>Claim Voucher</span>
                                    </button>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                                    <div style={{ padding: 10 }}>
                                        <span style={{}}>PIN: {pin}</span>
                                    </div>
                                    <div style={{ padding: 10, cursor: 'pointer' }}
                                        onClick={() => this.copyToClipboard(pin, index, 'pins')}
                                    >
                                        <FontAwesomeIcon style={{ paddingLeft: 10, color: pinLconColor ? 'green' : themeColors?.buttonBgColor, }} icon={faCopy} />
                                    </div>
                                </div>
                            </div>

                            : urlType == 'codePin' ?

                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "20%" }}>
                                    <div style={{ borderStyle: "dashed", borderWidth: 1, borderRadius: 5, backgroundColor: 'rgba(0,0,0,0.1)', display: "flex", justifyContent: "space-between", flexDirection: "row", borderColor: themeColors?.buttonBgColor }}>
                                        <div style={{ padding: 10 }}>
                                            <span style={{}}>{url}</span>
                                        </div>
                                        <div style={{
                                            padding: 10, backgroundColor: urlLconColor ? 'green' : themeColors?.buttonBgColor, borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5, cursor: 'pointer'
                                        }}
                                            onClick={() => this.copyToClipboard(url, index, 'urls')}
                                        >
                                            <FontAwesomeIcon style={{
                                                paddingLeft: 10, color: urlLconColor ? themeColors?.buttonBgColor : "#ddd", borderTopRightRadius: 5,
                                                borderBottomRightRadius: 5
                                            }} icon={faCopy} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                                        <div style={{ padding: 10 }}>
                                            <span style={{}}>PIN: {pin}</span>
                                        </div>
                                        <div style={{ padding: 10, cursor: 'pointer' }}
                                            onClick={() => this.copyToClipboard(pin, index, 'pins')}
                                        >
                                            <FontAwesomeIcon style={{ paddingLeft: 10, color: pinLconColor ? 'green' : themeColors?.buttonBgColor, }} icon={faCopy} />
                                        </div>
                                    </div>
                                </div>
                                : urlType == 'code' ?

                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "20%" }}>
                                        <div style={{ borderStyle: "dashed", borderWidth: 1, borderRadius: 5, backgroundColor: 'rgba(0,0,0,0.1)', display: "flex", justifyContent: "space-between", flexDirection: "row", borderColor: themeColors?.buttonBgColor }}>
                                            <div style={{ padding: 10 }}>
                                                <span style={{}}>{url}</span>
                                            </div>
                                            <div style={{
                                                padding: 10, backgroundColor: urlLconColor ? 'green' : themeColors?.buttonBgColor, borderTopRightRadius: 5,
                                                borderBottomRightRadius: 5, cursor: 'pointer'
                                            }}
                                                onClick={() => this.copyToClipboard(url, index, 'urls')}
                                            >
                                                <FontAwesomeIcon style={{ paddingLeft: 10, color: urlLconColor ? themeColors?.buttonBgColor : "#ddd" }} icon={faCopy} />
                                            </div>
                                        </div>
                                    </div> : null
                    }
                </div>
            </div>
        )
    }
}
