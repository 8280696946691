import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getUserLoyaltyInfo } from '../reducers/referral/referral';
import RewardBalanceCardPage from '../components/RewardBalanceCardPage';


class RewardBalanceCard extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getUserLoyaltyInfo();
    }

    render() {

        const { loyaltyInfo, config } = this.props;

        if (!loyaltyInfo) {
            return null;
        }

        return <RewardBalanceCardPage loyaltyInfo={loyaltyInfo} config={config} />
    }
}

const mapState = (state, ownprops) => {

    return {
        loyaltyInfo: state.referral.loyaltyInfo
    };
}

const mapDispatch = {
    getUserLoyaltyInfo
}

export default connect(mapState, mapDispatch)(RewardBalanceCard);