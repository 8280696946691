import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import styles from '../styles/loyalty.css';
import { FontAwesomeIcon, faCircleChevronRight } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faStar } from '@fortawesome/free-solid-svg-icons'

class BenefitItem3 extends Component {

    render() {
        const { item, onclick, benefitStyles, type, icons, themeColors, userPoints } = this.props;
        const { listItemBackground, listItemTextColor, exclusiveItemBackground, exclusiveItemTextColor } = benefitStyles || {};
        const bgcolor = type == 'exclusive' ? exclusiveItemBackground || '#ddd' : listItemBackground || '#ddd';
        const color = type == 'exclusive' ? exclusiveItemTextColor || '#fff' : listItemTextColor || '#FFF';
        const { pointsIcon } = icons || {};
        const value = parseFloat(item.worth) - parseFloat(userPoints)

        return (
            <div style={{ flex: 1 }}>
                <div style={{
                    marginTop: 10,
                    marginBottom: 15,
                    backgroundColor: "#dfe1f6",
                    display: "flex", borderRadius: 6, flexDirection: "column"
                }}>
                    <img src={item.image} alt="Img" style={{ width: "95%", height: 100, objectFit: "cover", borderRadius: 6, margin: 10, alignSelf: "center" }} />
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ color: themeColors?.themeColor, marginTop: 10, fontSize: 15, width: "95%" }}>{item.name.length > 30 ? `${item.name.slice(0, 30)}...` : item.name}</span>
                            <span style={{ color: '#000', fontSize: 15, marginTop: 4, width: "95%" }}>{item.description || ''}</span>
                            <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: 12, marginRight: 12, marginTop: 10, width: "95%" }}>
                                <div style={{ flex: .5, display: "flex", flexDirection: "column" }}>
                                    <span style={{ color: "#9ea5ce", marginBottom: 5 }}>Order Value</span>
                                    <div style={{ width: "100%", height: 36, borderRadius: 5, display: "flex", alignItems: "center" }}>
                                        <div style={{ width: "95%", height: 36, backgroundColor: "#fff", borderRadius: 5, display: "flex", alignItems: "center" }}>
                                            <span style={{ paddingLeft: 5 }}>{parseFloat(item.worth)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ flex: .5, display: "flex", flexDirection: "column" }}>
                                    <div style={{ width: "100%", borderRadius: 5, display: "flex", alignItems: "end", flexDirection: "column" }}>
                                        <span style={{ color: "#9ea5ce", width: "95%", marginBottom: 5 }}>Quantity</span>
                                        <div style={{ width: "95%", height: 36, backgroundColor: "#fff", borderRadius: 5, display: "flex", alignItems: "center" }}>
                                            <span style={{ paddingLeft: 5 }}>1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles['btn-div2']}`} style={{ marginBottom: 20 }}>
                        <button style={{ color: color || '#000', backgroundColor: themeColors?.buttonBgColor, borderRadius: 5, height: 40 }} className={`${styles['fr-link-black-btn2']} `} onClick={() => onclick()}>
                            {parseInt(userPoints) >= parseInt(item.worth) ?
                                <span className={`${styles['link-btn-label']}`} style={{ fontWeight: "bold" }}>Buy Now for {parseFloat(item.worth)} Points</span>
                                : <span className={`${styles['link-btn-label']}`} style={{ fontWeight: "bold" }}>Earn {value} more points to redeem</span>}
                            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowCircleRight} />
                        </button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapState = (state, ownprops) => {
    const { benefitStyles, icons, themeColors } = state.theme;

    return {
        benefitStyles,
        icons,
        themeColors
    };
}

const mapDispatch = {
}

export default connect(mapState, mapDispatch)(BenefitItem3);

