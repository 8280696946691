import apiClient from '../../api';


const GET_TRANSACTIONS_LIST = "loyalty/GET_TRANSACTIONS_LIST";
const MERCHANT_INFO = "loyalty/MERCHANT_INFO";
const EXPORT_LINK = 'loyalty/EXPORT_LINK';
const initialState = {
    pointsInfo: null,
    transactions: [],
    merchantinfo: null,
    exportlink: null,
};

export const getTransactionHistory = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    try {
        const response = await apiClient.get(apiClient.Urls.transactionHistory, {
            authToken,
            merchantAccessToken,
        });
        console.log(response)
        if (response.success) {

            await dispatch({
                type: GET_TRANSACTIONS_LIST,
                transactions: response.data
            });
        } else {

        }
    } catch (e) {
        console.log(e)
    }
};

export const filterTransactionHistory = (Startdate, Enddate, id) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;

    try {
        if (id == null || id == '') {
            const response = await apiClient.post(apiClient.Urls.transactionHistory, {
                authToken,
                merchantAccessToken,
                startDate: Startdate,
                endDate: Enddate
            });

            console.log(response, 'filterTransactionHistory')
            if (response.success) {

                await dispatch({
                    type: GET_TRANSACTIONS_LIST,
                    transactions: response.data
                });
            }
        } else if (Startdate == null || Startdate == '' && Enddate == null || Enddate == '') {
            const response = await apiClient.post(apiClient.Urls.transactionHistory, {
                authToken,
                merchantAccessToken,
                merchantIds: [id],
            });
            console.log(response, 'filterTransactionHistory')
            if (response.success) {

                await dispatch({
                    type: GET_TRANSACTIONS_LIST,
                    transactions: response.data
                });
            }
        } else {
            const response = await apiClient.post(apiClient.Urls.transactionHistory, {
                authToken,
                merchantAccessToken,
                merchantIds: [id],
                startDate: Startdate,
                endDate: Enddate,
            });
            console.log(response, 'filterTransactionHistory')
            if (response.success) {

                await dispatch({
                    type: GET_TRANSACTIONS_LIST,
                    transactions: response.data
                });
            }
        }
    } catch (e) {
        console.log(e)
    }
};

export const getMerchantinfo = () => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;
    try {
        const response = await apiClient.get(apiClient.Urls.merchantinfo, {
            authToken,
            merchantAccessToken,

        });
        console.log('merchantinfo----', response)
        if (response.success) {
            await dispatch({
                type: MERCHANT_INFO,
                merchantinfo: response.data
            });
        } else {

        }
    } catch (e) {
        console.log(e)
    }
};

export const getexportlink = (Startdate, Enddate, id) => async (dispatch, getState) => {
    const state = getState();
    const { authToken, merchantAccessToken } = state.session;

    try {
        if (id == null || id == '') {
            const response = await apiClient.post(apiClient.Urls.export, {
                authToken,
                merchantAccessToken,
                startDate: Startdate,
                endDate: Enddate
            });

            console.log('exportlink----', response)
            if (response.success) {

                await dispatch({
                    type: EXPORT_LINK,
                    exportlink: response.fileUrl
                });
            }
        } else if (Startdate == null || Startdate == '' && Enddate == null || Enddate == '') {
            const response = await apiClient.post(apiClient.Urls.export, {
                authToken,
                merchantAccessToken,
                merchantIds: [id],
            });
            console.log('exportlink----', response)
            if (response.success) {

                await dispatch({
                    type: EXPORT_LINK,
                    exportlink: response.fileUrl
                });
            }
        } else {
            const response = await apiClient.post(apiClient.Urls.export, {
                authToken,
                merchantAccessToken,
                merchantIds: [id],
                startDate: Startdate,
                endDate: Enddate,
            });
            console.log('exportlink----', response)
            if (response.success) {

                await dispatch({
                    type: EXPORT_LINK,
                    exportlink: response.fileUrl
                });
            }
        }
    } catch (e) {
        console.log(e)
    }

    // try {
    //     const response = await apiClient.get(apiClient.Urls.export, {
    //         authToken,
    //         merchantAccessToken,
    //     });
    //     console.log('exportlink----', response)
    //     if (response.success) {
    //         await dispatch({
    //             type: EXPORT_LINK,
    //             exportlink: response.fileUrl
    //         });
    //     } else {

    //     }
    // } catch (e) {
    //     console.log(e)
    // }
};

const TransactionHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRANSACTIONS_LIST: {
            return {
                ...state,
                transactions: action.transactions
            }
        }
        case MERCHANT_INFO: {
            return {
                ...state,
                merchantinfo: action.merchantinfo
            }
        }
        case EXPORT_LINK: {
            return {
                ...state,
                exportlink: action.exportlink
            }
        }
        default: return state;
    }

}


export default TransactionHistoryReducer;
