import React, { Component } from 'react'
import ReferralOverview from '../containers/ReferralOverview';
import LeaderBoard from '../containers/LeaderBoard';
import Milestones from '../containers/Milestones';
import History from '../containers/History';
import styles from '../styles/referral.css';
import PointsHistory from '../containers/PointsHistory';

export default class ReferralPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedTab: 'overview'
        }

    }

    render() {

        const renderTabs = {
            "overview": ReferralOverview,
            "leaderboard": LeaderBoard,
            "milestones": Milestones,
            "history": PointsHistory,
            // "terms": <div>Empty</div>,
        };

        const ComponentToRender = renderTabs[this.state.selectedTab] || null;

        const { themeColors, tabStyle, termsUrl, mileStoneType } = this.props;
        const { themeColor, buttonBgColor, buttonTextColor } = themeColors || {};

        if (tabStyle == "1") {
            return (
                <div className={styles['fr-main-parent-div']}>

                    <div className={styles['fr-tabs-div']}>
                        <div style={{ display: 'flex', flexDirection: 'row' }} className={styles["tabs"]}>
                            <li><a className={this.state.selectedTab === "overview" ? `${styles['fr-active-tab']} active-tab` : 'tab-item-custom'} style={{ borderBottom: this.state.selectedTab === "overview" ? `2px solid ${themeColor}` : 'none' }} onClick={() => this.setState({ selectedTab: 'overview' })}>Overview</a>
                            </li>
                            {/* <li>
                            <a className={this.state.selectedTab === "leaderboard" ? `${styles['fr-active-tab']} active-tab` : 'tab-item-custom'} onClick={() => this.setState({ selectedTab: 'leaderboard' })}>Leaderboard</a>
                        </li> */}
                            <li><a style={{ borderBottom: this.state.selectedTab === "milestones" ? `2px solid ${themeColor}` : 'none' }} className={this.state.selectedTab === "milestones" ? `${styles['fr-active-tab']} active-tab` : 'tab-item-custom'} onClick={() => this.setState({ selectedTab: 'milestones' })}>Milestones</a>
                            </li>
                            <li><a style={{ borderBottom: this.state.selectedTab === "history" ? `2px solid ${themeColor}` : 'none' }} className={this.state.selectedTab === "history" ? `${styles['fr-active-tab']} active-tab` : 'tab-item-custom'} onClick={() => this.setState({ selectedTab: 'history' })}>History</a>
                            </li>
                        </div>

                        <a href={termsUrl} target='_blank' className={styles['fr-terms-btn']} style={{ background: buttonBgColor, color: buttonTextColor }}>
                            VIEW T&C
                        </a>
                    </div>

                    <ComponentToRender />

                </div>
            )
        }
        else {
            return (
                <div>
                    <div className={styles['shadow-block']}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className={styles["tabs"]}>
                            <li>
                                <a className={`${styles['fr-active-tab']} active-tab`} style={{ borderBottom: `2px solid ${themeColor}` }}>Overview</a>
                            </li>
                            <a href={termsUrl} target='_blank' className={styles['fr-terms-btn']} style={{ background: buttonBgColor, color: buttonTextColor }}>
                                VIEW T&C
                            </a>
                        </div>
                        <ReferralOverview />
                    </div>
                    <div className={styles['shadow-block']}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className={styles["tabs"]}>
                            <li>
                                <a className={`${styles['fr-active-tab']} active-tab`} style={{ borderBottom: `2px solid ${themeColor}` }}>Milestones</a>
                            </li>
                        </div>
                        <Milestones mileStoneType={mileStoneType || 2} />
                    </div>
                    <div className={styles['shadow-block']}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className={styles["tabs"]}>
                            <li>
                                <a className={`${styles['fr-active-tab']} active-tab`} style={{ borderBottom: `2px solid ${themeColor}` }}>History</a>
                            </li>
                        </div>
                        <PointsHistory />
                    </div>
                </div>
            )
        }
    }
}
