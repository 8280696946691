
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pushPath } from '../reducers/navigation/navigation'
import { openSignUpModal } from '../reducers/signup/signup'
import ProgramListPage from '../components/ProgramListPage'
import SignUp from './SignUp';
import { getPrograms } from "../reducers/loyaltyprogram/loyaltyprogram"
import { getEnrollmentCriteria } from '../reducers/signup/signup'


class ProgramList extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.getPrograms();
    }

    constructor(props) {
        super(props)
    }


    render() {

        const { loyaltyprogram, navigation, userDetails } = this.props;
        const { programList } = loyaltyprogram;
        const { enrolledPrograms } = userDetails;
        const { params } = navigation;


        return (
            <div style={{ padding: 25 }}>
                {programList.length > 0 ?
                    <ProgramListPage
                        openSignUpModal={this.props.openSignUpModal} enrolledPrograms={enrolledPrograms}
                        getEnrollmentCriteria={this.props.getEnrollmentCriteria}
                        programs={programList} pushPath={() => this.props.pushPath("/loyalty", null)} />
                    : null}
                <SignUp />

            </div>
        );
    }
}


const mapState = (state, ownprops) => {
    return {
        loyaltyprogram: state.loyaltyprogram,
        navigation: state.navigation,
        userDetails: state.loyalty.userDetails,
    };
}

const mapDispatch = {
    pushPath,
    openSignUpModal,
    getPrograms,
    getEnrollmentCriteria,
}

export default connect(mapState, mapDispatch)(ProgramList);
