import React, { useState } from 'react'
import styles from '../../../styles/gifting.css';
import { useDispatch, useSelector } from 'react-redux';
import { addAmountAction, setGiftDetails } from '../../../reducers/gifting';
import ContinueButton from '../../common/Button.Continue';
import SendOption from '../../common/SendOption'
import getImage from '../../../images';

function Step0Form(props) {

  const dispatch = useDispatch()

  const navigation = useSelector(state => state.navigation)
  const gifting = useSelector(state => state.gifting)
  const denominationConfig = useSelector(state => state.session.denominationConfig)

  const [isCustom, setisCustom] = useState(false)
  const [isAddClicked, setisAddClicked] = useState(false)

  const { config, currencyInfo, offerList } = denominationConfig?.data || {}
  const { themeId, theme, amount } = gifting.giftDetails

// useEffect(() => {
//  let isAvailable= config?.fixed_values?.includes(amount)

// }, [])

  const handleOnChangeText = (e) => {
    const { name, value } = e.currentTarget;
    dispatch(setGiftDetails({ [name]: value }))
  }

  const handleContinue = () => {
    let validation = props.validationFormOne()
    // if (gifting.giftDetails.type === 'gift') {
    //   if (validation === true) {
    //     window.scrollTo({ top: 0, behavior: 'auto' });
    //     props.handleseterrors({})
    //     props.handleForm(1)
    //   }
    // }
    // else {
    //   if (validation === true) {
    //     window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    //   }
    // }

    if (validation === true) {
      window.scrollTo({ top: 0, behavior: 'auto' });
      props.handleseterrors({})
      props.handleForm(1)
    }

  }

  const handleSelectAmount = (param) => {
    setisCustom(false)
    dispatch(setGiftDetails({ amount: param.fixed_values }))

    dispatch(addAmountAction(param.fixed_values))
    props.handleseterrors({})
  }

  const handleAddRemoveBtn = () => {
    props.validationFormOne()
    if (isAddClicked === false && !!gifting.giftDetails.amount?.length) {
      dispatch(addAmountAction())
      setisAddClicked(true)
      // props.handleseterrors({})
    }
    else {
      setisAddClicked(false)
    }
  }

  return (
    <div className={styles['gft-container']}>
      <div style={{
        display: 'flex', flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: 14, fontfamily: 'Open Sans',
        textAlign: 'center',
        fontweight: 'bold',
        marginTop: 30,
        marginBottom: 60
      }}>
        <img
          src={theme}
          style={{
            width: 347, height: 186, borderRadius: 10,
            boxShadow: '0px 20px 30px -15px rgba(0,0,0,0.86)'
          }}
          alt="Img" />
      </div>

      <div
        className={styles['fr-sub-head-text']}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', minHeight: 200, }}>
        <SendOption />

        {gifting.giftDetails.type !== 'self'
          ?
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <text className={styles['gft-label']}>Enter your message</text>
            <input className={styles['gft-input']}
              style={{ wordBreak: 'normal' }}
              name='message'
              value={gifting.giftDetails?.message}
              onChange={(e) => handleOnChangeText(e)}
              defaultValue=""
            />

            <text className={styles['gft-warning-label']}>
              {props.errors['message']}
            </text>
          </div>
          :
          null}


        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <text className={styles['gft-label']} style={{ marginBottom: 10 }}>Select Gift Card Amount</text>
          <div
            className={styles['btnsContainer']}          >

            {config?.denominations_type !== 'open' ?
              config?.fixed_values?.map((item, i) => (
                <div
                  key={i}
                  className={styles['amountBtn']}
                  style={{
                    backgroundColor: item.fixed_values === gifting.giftDetails?.amount && isCustom === false ? '#3B444D' : '#fff',
                    color: item.fixed_values === gifting.giftDetails?.amount && isCustom === false ? '#fff' : '#41434A',
                  }}
                  onClick={() => handleSelectAmount(item)}
                >
                  {currencyInfo.symbol}{item.fixed_values}
                </div>
              ))
              : null
            }
            {
              config?.denominations_type !== 'fixed' ?
                <div
                  className={styles['amountBtn']}
                  style={{
                    backgroundColor: isCustom === true ? '#3B444D' : '#fff',
                    color: isCustom === true ? '#fff' : '#41434A',
                  }}
                  onClick={() => setisCustom(!isCustom)}
                >
                  Custom
                </div>
                : null
            }

          </div>
          {
            isCustom ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', height: 57 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ marginRight: -30, zIndex: 1 }}>
                    {currencyInfo.symbol}
                  </div>
                  <input
                    className={styles[isAddClicked === true ? 'amountBtn' : 'custom-amt-input']}
                    style={{
                      width: 100,
                      fontSize: 16,
                      borderWidth: 0
                    }}
                    disabled={isAddClicked}
                    name='amount'
                    type='number'
                    defaultValue="₹500"
                    value={gifting.giftDetails?.amount}
                    onChange={(e) => handleOnChangeText(e)}
                  />
                </div>

                <div
                  className={styles['amountBtn']}
                  style={{
                    backgroundColor: isAddClicked === false ? '#3B444D' : '#fff',
                    color: isAddClicked === false ? '#fff' : '#41434A',
                  }}
                  onClick={() => handleAddRemoveBtn()}
                >{isAddClicked === false
                  ?
                  <text>Add</text>
                  :
                  <img alt="Img" style={{ height: 20 }}
                    src={getImage('pencil.png')}
                  />
                  }
                </div>
              </div>
              :
              null
          }

          <text className={styles['gft-warning-label']}>
            {props.errors['amount']}
          </text>


          <text className={styles['gft-info-label']} style={{ marginBottom: 30 }} >
            {(gifting.selectedAmount?.succcess === true || gifting.selectedAmount?.success === true)
              ?
              `${gifting.selectedAmount?.message} with discount ${currencyInfo.symbol}${gifting.selectedAmount?.data?.discountAmount}`
              :
              ''
            }
          </text>

          {/* <div style={{ marginLeft: 'auto', marginRight: 0 }}> */}
          <ContinueButton
            actionTitle={'Continue'}
            action={() => handleContinue()} />
          {/* </div> */}
        </div>

      </div>

    </div>
  )
}

Step0Form.propTypes = {

}

export default Step0Form

