import React from 'react';
import styles from '../styles/referral.css'

const HowItWorksPage2 = ({ program,referralProgram }) => {
    return (
        <div className={`${styles['fr-how-it-works-div']} how-it-works-one`}>
            {program && program.map((item, index) => {
                return (
                    <HowItWorksItem item={item} key={index} referralProgram={referralProgram}/>
                )
            })}
        </div>
    )
}

const HowItWorksItem = ({ item,referralProgram }) => {
  const { image, backgroundColor, content, title } = item;
  const matchingItem = referralProgram.filter(refItem => refItem.slug === item.slug);
    return (
      <div className={`${styles['fr-how-works-item']} how-it-works-item`} style={{ background: backgroundColor }}>
      {image && <div>
          <img src={image} className={`${styles['image-style']}`} style={{ height: 220 }} />
      </div>}
      <div className={`${styles['fr-how-it-works-title-div']} how-it-works-title`}>
        <span
            className={`${styles['title-style']}`}
            style={{ fontSize: 28, color: '#000', marginTop: 25, marginBottom: 25 }}
            dangerouslySetInnerHTML={{
                __html: title
                    .replace('You Get', '<strong>You Get</strong>')
                    .replace('Your Friend Gets', '<strong>Your Friend Gets</strong>')
            }}
        />
    </div>
      <div style={{display:'flex',flexDirection:'column'}}>
        {matchingItem.map((matchingItem, index) => (
          <div key={index} className={`${styles['content-div-style']}`} style={{ display: 'flex', flexDirection: "row", gap: 10,alignItems:'center',margin:10, }}>
            <img className={`${styles['icon-style']}`} src={matchingItem.icon} alt="icon" />
            <div className={`${styles['content-style']}`} style={{ color: "black",fontSize:18 }} dangerouslySetInnerHTML={{ __html: matchingItem.content }} />
          </div>
        ))}
          </div>
  </div>
    )
}

export default HowItWorksPage2;
