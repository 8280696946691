import React, { Component } from 'react';
import Heading from '../common/Heading';
import VoucherItem from './VoucherItem';
import styles from '../styles/loyalty.css';

class VouchersList extends Component {
    render() {
        const { pushPath, vouchers, currency } = this.props;
        if (vouchers && vouchers.length > 0) {
            return (
                <div>
                    <Heading title={'Buy Gift Cards Using Your Points'} />
                    <div className={`${styles['freebies-container']}`}>
                        {vouchers && vouchers.length > 0 ? vouchers.map((item, index) => {
                            return <VoucherItem item={item} currency={currency} key={index} onclick={() => { pushPath('/voucher-cart', { voucher: item }); }} />
                        }) : <h2>No vouchers available now.</h2>}
                    </div>
                </div>
            )
        }

        return (
            <div>
                <Heading title={'Buy Gift Cards Using Your Points'} />
                <p>Gift cards are loading...</p>
            </div>
        )
    }
}


export default VouchersList;
