
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BenefitsListPage from '../components/BenefitsListPage';
import { getActiveFreebies, getExpiredFreebies } from '../reducers/loyalty/loyalty'
import { pushPath } from '../reducers/navigation/navigation';

class BenefitsList extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.type === "active" ?
            this.props.getActiveFreebies() :
            this.props.getExpiredFreebies()
    }

    render() {
        const { type, active, expired, pushPath } = this.props;

        return (
            <div>
                <BenefitsListPage pushPath={pushPath} type={type} freebies={type === "active" ? active : expired} />
            </div>
        );
    }
}

const mapState = (state, ownprops) => {

    return {
        active: state.loyalty.activeFreebies,
        expired: state.loyalty.expiredFreebies,
    };
}

export default connect(mapState, { getActiveFreebies, getExpiredFreebies, pushPath })(BenefitsList);
