import React, { useEffect, useRef } from "react";

const PaymentSubmit = ({
    pgDetails
}) => {
    const formRef = useRef(null);
    useEffect(() => {
        formRef.current.submit();
    }, []);

    const { response } = pgDetails;

    return (
        pgDetails ?
            <form ref={formRef} method={response && response.method} action={response && response.url}>
                {response && response.payload && Object.keys(response && response.payload).map(name => (
                    <input
                        type="hidden"
                        name={name}
                        value={response && response.payload[name]}
                    ></input>
                ))}
            </form>
            : null
    );
};

export default PaymentSubmit;